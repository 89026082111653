<h4 class="center">Matzikama Vacancies</h4>
<div class="container">
<!-- Application forms header -->
<div class="row">
        <h4 class="center">Recruitment Application Forms</h4>
        <div class="row">
            <div class="col s12 m12 l6">
                <div class="card #01579b light-blue darken-4">
                    <div class="card-content white-text">
                        <span class="card-title">Senior Management Application Form</span>
                        <p class="truncate">Download the application form for Senior Management positions here.</p>
                    </div>
                    <div class="card-action">
                        <a href="/assets/vacancies/Senior Management Recruitment Form.pdf" download>Download</a>
                    </div>
                </div>
            </div>
            <div class="col s12 m12 l6">
                <div class="card #01579b light-blue darken-4">
                    <div class="card-content white-text">
                        <span class="card-title">Vacancy Application Form</span>
                        <p class="truncate">Download the application form for vacancies here.</p>
                    </div>
                    <div class="card-action">
                        <a href="/assets/vacancies/Application Form For Employment.pdf" download>Download</a>
                    </div>
                </div>
            </div>
            <div class="col s12 m12 l6">
              <div class="card #01579b light-blue darken-4">
                  <div class="card-content white-text">
                      <span class="card-title">Consent Form</span>
                      <p class="truncate">Download the consent form for vacancies here.</p>
                  </div>
                  <div class="card-action">
                      <a href="/assets/vacancies/2023-24/LXN_Consent_and_Indemnity_Form.pdf" download>Download</a>
                  </div>
              </div>
          </div>
        </div>
</div> <!--row end-->
<!-- Application forms header end-->
   <!-- V12 2024-->
   <div class="row">
    <div class="col s12 m12 l12" >
      <mz-collapsible [mode]="'expandable'" [popout]="true">
         <mz-collapsible-item [active]="true">
           <mz-collapsible-item-header>
             <i class="material-icons">keyboard_arrow_down</i>
             V16-2024 Various Positions
         </mz-collapsible-item-header>
             <mz-collapsible-item-body>
               <mz-collection>
                 <mz-collection-item>
                     Upload Date:
                     <a href="#" mz-secondary-content download> {{ V16_2024 | date: 'MMM, dd yyyy, HH:mm aaa'}}</a>
                 </mz-collection-item>
                 <mz-collection-item>
                   Advertisement
                   <a href="/assets/vacancies/2024-25/V16-2024.pdf" mz-secondary-content download>
                       <i class=" material-icons download rigth" download>cloud_download</i>
                   </a>
                 </mz-collection-item>
                 <mz-collection-item>
                  Application Form
                  <a href="/assets/vacancies/Application Form For Employment.pdf"mz-secondary-content download>
                      <i class=" material-icons download rigth" download>cloud_download</i>
                  </a>
                </mz-collection-item>
                <mz-collection-item>
                  Consent and Indemnity Form
                  <a href="/assets/vacancies/2023-24/LXN_Consent_and_Indemnity_Form.pdf"mz-secondary-content download>
                      <i class=" material-icons download rigth" download>cloud_download</i>
                  </a>
                </mz-collection-item>
             </mz-collection>
             </mz-collapsible-item-body>
         </mz-collapsible-item>
      </mz-collapsible>
    </div>
   </div>
   <!-- V12 2024-->
   <div class="row">
    <div class="col s12 m12 l12" >
      <mz-collapsible [mode]="'expandable'" [popout]="true">
         <mz-collapsible-item [active]="true">
           <mz-collapsible-item-header>
             <i class="material-icons">keyboard_arrow_down</i>
             V14-2024 Various Positions
         </mz-collapsible-item-header>
             <mz-collapsible-item-body>
               <mz-collection>
                 <mz-collection-item>
                     Upload Date:
                     <a href="#" mz-secondary-content download> {{ V14_2024 | date: 'MMM, dd yyyy, HH:mm aaa'}}</a>
                 </mz-collection-item>
                 <mz-collection-item>
                   Advertisement
                   <a href="/assets/vacancies/2024-25/V14 -2024 -Superintendent Civil Maintenace - Klawer Foreman VDorp Building Insp Asst Town Planner.pdf" mz-secondary-content download>
                       <i class=" material-icons download rigth" download>cloud_download</i>
                   </a>
                 </mz-collection-item>
                 <mz-collection-item>
                  Application Form
                  <a href="/assets/vacancies/Application Form For Employment.pdf"mz-secondary-content download>
                      <i class=" material-icons download rigth" download>cloud_download</i>
                  </a>
                </mz-collection-item>
                <mz-collection-item>
                  Consent and Indemnity Form
                  <a href="/assets/vacancies/2023-24/LXN_Consent_and_Indemnity_Form.pdf"mz-secondary-content download>
                      <i class=" material-icons download rigth" download>cloud_download</i>
                  </a>
                </mz-collection-item>
             </mz-collection>
             </mz-collapsible-item-body>
         </mz-collapsible-item>
      </mz-collapsible>
    </div>
   </div>
<!-- V14 start
 <div class="row">
  <div class="col s12 m12 l12">
    <mz-collapsible [mode]="'expandable'" [popout]="true">
      <mz-collapsible-item-header>
        <i class="material-icons">keyboard_arrow_down</i>
        V14-2024 Various Positions
      </mz-collapsible-item-header>
      <mz-collapsible-item-body>
        <mz-collection>
          <mz-collection-item>
            Upload Date:
            <a href="#" mz-secondary-content download> {{ V14_2024 | date: 'MMM, dd yyyy, HH:mm aaa'}}</a>
        </mz-collection-item>
        <mz-collection-item>
          Advertisement
          <a href="/assets/vacancies/2024-25/V14 -2024 -Superintendent Civil Maintenace - Klawer Foreman VDorp Building Insp Asst Town Planner.pdf" mz-secondary-content download>
              <i class=" material-icons download rigth" download>cloud_download</i>
          </a>
        </mz-collection-item>
        <mz-collection-item>
         Application Form
         <a href="/assets/vacancies/Application Form For Employment.pdf"mz-secondary-content download>
             <i class=" material-icons download rigth" download>cloud_download</i>
         </a>
       </mz-collection-item>
       <mz-collection-item>
        Consent and Indemnity Form
        <a href="/assets/vacancies/2023-24/LXN_Consent_and_Indemnity_Form.pdf"mz-secondary-content download>
            <i class=" material-icons download rigth" download>cloud_download</i>
        </a>
      </mz-collection-item>
        </mz-collection>
      </mz-collapsible-item-body>
    </mz-collapsible>
  </div>
</div> -->
 <!-- Director Corporate Services -->
<div class="row">
  <div class="col s12 m12 l12" >
    <mz-collapsible [mode]="'expandable'" [popout]="true">
       <mz-collapsible-item [active]="true">
         <mz-collapsible-item-header>
           <i class="material-icons">keyboard_arrow_down</i>
           DIRECTOR: CORPORATE SERVICES
       </mz-collapsible-item-header>
           <mz-collapsible-item-body>
             <mz-collection>
               <mz-collection-item>
                   Upload Date:
                   <a href="#" mz-secondary-content download> {{ V13_2024 | date: 'MMM, dd yyyy, HH:mm aaa'}}</a>
               </mz-collection-item>
               <mz-collection-item>
                 Advertisement
                 <a href="/assets/vacancies/2024-25/V13-2024 - Director Corporate Services.pdf" mz-secondary-content download>
                     <i class=" material-icons download rigth" download>cloud_download</i>
                 </a>
               </mz-collection-item>
               <mz-collection-item>
                Senior Manager Application Form
                <a href="/assets/vacancies/2023-24/Aansoek om betrekking - senior bestuur.pdf"mz-secondary-content download>
                    <i class=" material-icons download rigth" download>cloud_download</i>
                </a>
              </mz-collection-item>
              <mz-collection-item>
                Consent and Indemnity Form
                <a href="/assets/vacancies/2023-24/LXN_Consent_and_Indemnity_Form.pdf"mz-secondary-content download>
                    <i class=" material-icons download rigth" download>cloud_download</i>
                </a>
              </mz-collection-item>
           </mz-collection>
           </mz-collapsible-item-body>
       </mz-collapsible-item>
    </mz-collapsible>
  </div>
 </div>
<!-- Director Corporate Services End-->
  <!-- V12 2024-->
  <div class="row">
    <div class="col s12 m12 l12" >
      <mz-collapsible [mode]="'expandable'" [popout]="true">
         <mz-collapsible-item [active]="true">
           <mz-collapsible-item-header>
             <i class="material-icons">keyboard_arrow_down</i>
             V12-2024 Various Positions
         </mz-collapsible-item-header>
             <mz-collapsible-item-body>
               <mz-collection>
                 <mz-collection-item>
                     Upload Date:
                     <a href="#" mz-secondary-content download> {{ V12_2024 | date: 'MMM, dd yyyy, HH:mm aaa'}}</a>
                 </mz-collection-item>
                 <mz-collection-item>
                   Advertisement
                   <a href="/assets/vacancies/2024-25/V12 -2024 - Advert - various positions - Full ad.pdf" mz-secondary-content download>
                       <i class=" material-icons download rigth" download>cloud_download</i>
                   </a>
                 </mz-collection-item>
                 <mz-collection-item>
                  Application Form
                  <a href="/assets/vacancies/Application Form For Employment.pdf"mz-secondary-content download>
                      <i class=" material-icons download rigth" download>cloud_download</i>
                  </a>
                </mz-collection-item>
                <mz-collection-item>
                  Consent and Indemnity Form
                  <a href="/assets/vacancies/2023-24/LXN_Consent_and_Indemnity_Form.pdf"mz-secondary-content download>
                      <i class=" material-icons download rigth" download>cloud_download</i>
                  </a>
                </mz-collection-item>
             </mz-collection>
             </mz-collapsible-item-body>
         </mz-collapsible-item>
      </mz-collapsible>
    </div>
   </div>
 <!-- V10 2024-->
 <div class="row">
  <div class="col s12 m12 l12" >
    <mz-collapsible [mode]="'expandable'" [popout]="true">
       <mz-collapsible-item [active]="true">
         <mz-collapsible-item-header>
           <i class="material-icons">keyboard_arrow_down</i>
           V10 -2024 -Superintendent Civil Maintenace - Klawer en Vanrhynsdorp
       </mz-collapsible-item-header>
           <mz-collapsible-item-body>
             <mz-collection>
               <mz-collection-item>
                   Upload Date:
                   <a href="#" mz-secondary-content download> {{ V10_2024 | date: 'MMM, dd yyyy, HH:mm aaa'}}</a>
               </mz-collection-item>
               <mz-collection-item>
                 Advertisement
                 <a href="/assets/vacancies/2023-24/V10 -2024 -Superintendent Civil Maintenace - Klawer en Vanrhynsdorp.pdf" mz-secondary-content download>
                     <i class=" material-icons download rigth" download>cloud_download</i>
                 </a>
               </mz-collection-item>
               <mz-collection-item>
                Application Form
                <a href="/assets/vacancies/Application Form For Employment.pdf"mz-secondary-content download>
                    <i class=" material-icons download rigth" download>cloud_download</i>
                </a>
              </mz-collection-item>
              <mz-collection-item>
                Consent and Indemnity Form
                <a href="/assets/vacancies/2023-24/LXN_Consent_and_Indemnity_Form.pdf"mz-secondary-content download>
                    <i class=" material-icons download rigth" download>cloud_download</i>
                </a>
              </mz-collection-item>
           </mz-collection>
           </mz-collapsible-item-body>
       </mz-collapsible-item>
    </mz-collapsible>
  </div>
 </div>

 <!-- V10 2024 End -->
<!-- Director Corporate Services -->
<div class="row">
  <div class="col s12 m12 l12" >
    <mz-collapsible [mode]="'expandable'" [popout]="true">
       <mz-collapsible-item [active]="true">
         <mz-collapsible-item-header>
           <i class="material-icons">keyboard_arrow_down</i>
           DIRECTOR: CORPORATE SERVICES
       </mz-collapsible-item-header>
           <mz-collapsible-item-body>
             <mz-collection>
               <mz-collection-item>
                   Upload Date:
                   <a href="#" mz-secondary-content download> {{ V9_2024 | date: 'MMM, dd yyyy, HH:mm aaa'}}</a>
               </mz-collection-item>
               <mz-collection-item>
                 Advertisement
                 <a href="/assets/vacancies/2023-24/V9-2024 - Director Corporate Services.pdf" mz-secondary-content download>
                     <i class=" material-icons download rigth" download>cloud_download</i>
                 </a>
               </mz-collection-item>
               <mz-collection-item>
                Senior Manager Application Form
                <a href="/assets/vacancies/2023-24/Aansoek om betrekking - senior bestuur.pdf"mz-secondary-content download>
                    <i class=" material-icons download rigth" download>cloud_download</i>
                </a>
              </mz-collection-item>
              <mz-collection-item>
                Consent and Indemnity Form
                <a href="/assets/vacancies/2023-24/LXN_Consent_and_Indemnity_Form.pdf"mz-secondary-content download>
                    <i class=" material-icons download rigth" download>cloud_download</i>
                </a>
              </mz-collection-item>
           </mz-collection>
           </mz-collapsible-item-body>
       </mz-collapsible-item>
    </mz-collapsible>
  </div>
 </div>
<!-- Director Corporate Services End-->
<!-- Director Technical Services -->
<div class="row">
  <div class="col s12 m12 l12" >
    <mz-collapsible [mode]="'expandable'" [popout]="true">
       <mz-collapsible-item [active]="true">
         <mz-collapsible-item-header>
           <i class="material-icons">keyboard_arrow_down</i>
           DIRECTOR:TECHNICAL SERVICES
       </mz-collapsible-item-header>
           <mz-collapsible-item-body>
             <mz-collection>
               <mz-collection-item>
                   Upload Date:
                   <a href="#" mz-secondary-content download> {{ V7_2024 | date: 'MMM, dd yyyy, HH:mm aaa'}}</a>
               </mz-collection-item>
               <mz-collection-item>
                 Advertisement
                 <a href="/assets/vacancies/2023-24/V7-2024 - Director Technical Services.pdf" mz-secondary-content download>
                     <i class=" material-icons download rigth" download>cloud_download</i>
                 </a>
               </mz-collection-item>
               <mz-collection-item>
                Senior Manager Application Form
                <a href="/assets/vacancies/2023-24/Aansoek om betrekking - senior bestuur.pdf"mz-secondary-content download>
                    <i class=" material-icons download rigth" download>cloud_download</i>
                </a>
              </mz-collection-item>
           </mz-collection>
           </mz-collapsible-item-body>
       </mz-collapsible-item>
    </mz-collapsible>
  </div>
 </div>
<!-- Director Technical Services End-->
<!--  VARIUOS POSITIONS start -->
<div class="row">
  <div class="col s12 m12 l12" >
    <mz-collapsible [mode]="'expandable'" [popout]="true">
       <mz-collapsible-item [active]="true">
         <mz-collapsible-item-header>
           <i class="material-icons">keyboard_arrow_down</i>
           Various Positions
       </mz-collapsible-item-header>
           <mz-collapsible-item-body>
             <mz-collection>
               <mz-collection-item>
                   Upload Date:
                   <a href="#" mz-secondary-content download> {{ V3_2024 | date: 'MMM, dd yyyy, HH:mm aaa'}}</a>
               </mz-collection-item>
               <mz-collection-item>
                 Advertisement
                 <a href="/assets/vacancies/2023-24/V3 -2024 - Advert - various positions.pdf" mz-secondary-content download>
                     <i class=" material-icons download rigth" download>cloud_download</i>
                 </a>
               </mz-collection-item>
               <mz-collection-item>
                Application Form
                <a href="/assets/vacancies/Application Form For Employment.pdf"mz-secondary-content download>
                    <i class=" material-icons download rigth" download>cloud_download</i>
                </a>
              </mz-collection-item>
              <mz-collection-item>
                Consent and Indemnity Form
                <a href="/assets/vacancies/2023-24/LXN_Consent_and_Indemnity_Form.pdf"mz-secondary-content download>
                    <i class=" material-icons download rigth" download>cloud_download</i>
                </a>
              </mz-collection-item>
           </mz-collection>
           </mz-collapsible-item-body>
       </mz-collapsible-item>
    </mz-collapsible>
  </div>
 </div>
<!--  VARIUOS POSITIONS end -->
<!--  VARIUOS POSITIONS start -->
<div class="row">
  <div class="col s12 m12 l12" >
    <mz-collapsible [mode]="'expandable'" [popout]="true">
       <mz-collapsible-item [active]="true">
         <mz-collapsible-item-header>
           <i class="material-icons">keyboard_arrow_down</i>
           Various Positions
       </mz-collapsible-item-header>
           <mz-collapsible-item-body>
             <mz-collection>
               <mz-collection-item>
                   Upload Date:
                   <a href="#" mz-secondary-content download> {{ V6_2024 | date: 'MMM, dd yyyy, HH:mm aaa'}}</a>
               </mz-collection-item>
               <mz-collection-item>
                 Advertisement
                 <a href="/assets/vacancies/2023-24/V6-2024 - Artisan (Electrician) and Foreman positions - April 2024.pdf" mz-secondary-content download>
                     <i class=" material-icons download rigth" download>cloud_download</i>
                 </a>
               </mz-collection-item>
               <mz-collection-item>
                Application Form
                <a href="/assets/vacancies/Application Form For Employment.pdf"mz-secondary-content download>
                    <i class=" material-icons download rigth" download>cloud_download</i>
                </a>
              </mz-collection-item>
              <mz-collection-item>
                Consent and Indemnity Form
                <a href="/assets/vacancies/2023-24/LXN_Consent_and_Indemnity_Form.pdf"mz-secondary-content download>
                    <i class=" material-icons download rigth" download>cloud_download</i>
                </a>
              </mz-collection-item>
           </mz-collection>
           </mz-collapsible-item-body>
       </mz-collapsible-item>
    </mz-collapsible>
  </div>
 </div>
<!--  VARIUOS POSITIONS end -->
<!--  SENIOR COMMUNICATION AND PUBLIC RELATIONS OFFICER start -->
<div class="row">
  <div class="col s12 m12 l12" >
    <mz-collapsible [mode]="'expandable'" [popout]="true">
       <mz-collapsible-item [active]="true">
         <mz-collapsible-item-header>
           <i class="material-icons">keyboard_arrow_down</i>
           Senior Clerk - Cashier Posts ( Financial Services & Traffic Services) 
       </mz-collapsible-item-header>
           <mz-collapsible-item-body>
             <mz-collection>
               <mz-collection-item>
                   Upload Date:
                   <a href="#" mz-secondary-content download> {{ V5_2024_2 | date: 'MMM, dd yyyy, HH:mm aaa'}}</a>
               </mz-collection-item>
               <mz-collection-item>
                 Advertisement
                 <a href="/assets/vacancies/2023-24/V5-2024 - Senior Clerk - Cashier Posts ( Financial Services and Traffic Services) - Vredendal.pdf" mz-secondary-content download>
                     <i class=" material-icons download rigth" download>cloud_download</i>
                 </a>
               </mz-collection-item>
               <mz-collection-item>
                Application Form
                <a href="/assets/vacancies/Application Form For Employment.pdf"mz-secondary-content download>
                    <i class=" material-icons download rigth" download>cloud_download</i>
                </a>
              </mz-collection-item>
           </mz-collection>
           </mz-collapsible-item-body>
       </mz-collapsible-item>
    </mz-collapsible>
  </div>
 </div>
<!--  SENIOR COMMUNICATION AND PUBLIC RELATIONS OFFICER end -->
<!--  SENIOR COMMUNICATION AND PUBLIC RELATIONS OFFICER start -->
<div class="row">
  <div class="col s12 m12 l12" >
    <mz-collapsible [mode]="'expandable'" [popout]="true">
       <mz-collapsible-item [active]="true">
         <mz-collapsible-item-header>
           <i class="material-icons">keyboard_arrow_down</i>
           SENIOR COMMUNICATION AND PUBLIC RELATIONS OFFICER
       </mz-collapsible-item-header>
           <mz-collapsible-item-body>
             <mz-collection>
               <mz-collection-item>
                   Upload Date:
                   <a href="#" mz-secondary-content download> {{ V5_2024 | date: 'MMM, dd yyyy, HH:mm aaa'}}</a>
               </mz-collection-item>
               <mz-collection-item>
                 Advertisement
                 <a href="/assets/vacancies/2023-24/V5-2024 - Senior Communication and Public Relations Officer.pdf" mz-secondary-content download>
                     <i class=" material-icons download rigth" download>cloud_download</i>
                 </a>
               </mz-collection-item>
               <mz-collection-item>
                Application Form
                <a href="/assets/vacancies/Application Form For Employment.pdf"mz-secondary-content download>
                    <i class=" material-icons download rigth" download>cloud_download</i>
                </a>
              </mz-collection-item>
           </mz-collection>
           </mz-collapsible-item-body>
       </mz-collapsible-item>
    </mz-collapsible>
  </div>
 </div>
<!--  SENIOR COMMUNICATION AND PUBLIC RELATIONS OFFICER end -->
<!-- Deputy Mayor Peace Training Officers start -->
<div class="row">
  <div class="col s12 m12 l12" >
    <mz-collapsible [mode]="'expandable'" [popout]="true">
       <mz-collapsible-item [active]="true">
         <mz-collapsible-item-header>
           <i class="material-icons">keyboard_arrow_down</i>
           PEACE OFFICER TRAINING 2024
       </mz-collapsible-item-header>
           <mz-collapsible-item-body>
             <mz-collection>
               <mz-collection-item>
                   Upload Date:
                   <a href="#" mz-secondary-content download> {{ peace2024 | date: 'MMM, dd yyyy, HH:mm aaa'}}</a>
               </mz-collection-item>
               <mz-collection-item>
                 Advertisement
                 <a href="/assets/vacancies/2023-24/PEACE OFFICER TRAINING 2024.pdf" mz-secondary-content download>
                     <i class=" material-icons download rigth" download>cloud_download</i>
                 </a>
               </mz-collection-item>
               <mz-collection-item>
                Application Form
                <a href="/assets/vacancies/Application Form For Employment.pdf"mz-secondary-content download>
                    <i class=" material-icons download rigth" download>cloud_download</i>
                </a>
              </mz-collection-item>
           </mz-collection>
           </mz-collapsible-item-body>
       </mz-collapsible-item>
    </mz-collapsible>
  </div>
 </div>
<!-- Deputy Peace Training Officers end -->
<!-- Deputy Mayor PA start -->
<div class="row">
  <div class="col s12 m12 l12" >
    <mz-collapsible [mode]="'expandable'" [popout]="true">
       <mz-collapsible-item [active]="true">
         <mz-collapsible-item-header>
           <i class="material-icons">keyboard_arrow_down</i>
           PERSONAL ASSISTANT: DEPUTY MAYOR – VREDENDAL
       </mz-collapsible-item-header>
           <mz-collapsible-item-body>
             <mz-collection>
               <mz-collection-item>
                   Upload Date:
                   <a href="#" mz-secondary-content download> {{ V2_2024 | date: 'MMM, dd yyyy, HH:mm aaa'}}</a>
               </mz-collection-item>
               <mz-collection-item>
                 Advertisement
                 <a href="/assets/vacancies/2023-24/V2-2024 -External and Internal -PA Deputy Mayor.pdf" mz-secondary-content download>
                     <i class=" material-icons download rigth" download>cloud_download</i>
                 </a>
               </mz-collection-item>
               <mz-collection-item>
                Application Form
                <a href="/assets/vacancies/Application Form For Employment.pdf"mz-secondary-content download>
                    <i class=" material-icons download rigth" download>cloud_download</i>
                </a>
              </mz-collection-item>
           </mz-collection>
           </mz-collapsible-item-body>
       </mz-collapsible-item>
    </mz-collapsible>
  </div>
 </div>
<!-- Deputy Mayor PA end -->
<!-- Director Technical Services -->
<div class="row">
  <div class="col s12 m12 l12" >
    <mz-collapsible [mode]="'expandable'" [popout]="true">
       <mz-collapsible-item [active]="true">
         <mz-collapsible-item-header>
           <i class="material-icons">keyboard_arrow_down</i>
           DIRECTOR:TECHNICAL SERVICES
       </mz-collapsible-item-header>
           <mz-collapsible-item-body>
             <mz-collection>
               <mz-collection-item>
                   Upload Date:
                   <a href="#" mz-secondary-content download> {{ V1_2024 | date: 'MMM, dd yyyy, HH:mm aaa'}}</a>
               </mz-collection-item>
               <mz-collection-item>
                 Advertisement
                 <a href="/assets/vacancies/2023-24/V1-2024  Advert - Director Technical Services.pdf" mz-secondary-content download>
                     <i class=" material-icons download rigth" download>cloud_download</i>
                 </a>
               </mz-collection-item>
               <mz-collection-item>
                Application Form
                <a href="/assets/vacancies/2023-24/Aansoek om betrekking - senior bestuur.pdf"mz-secondary-content download>
                    <i class=" material-icons download rigth" download>cloud_download</i>
                </a>
              </mz-collection-item>
           </mz-collection>
           </mz-collapsible-item-body>
       </mz-collapsible-item>
    </mz-collapsible>
  </div>
 </div>
<!-- Director Technical Services End-->

<!-- Internal & External Start  -->
<div class="row">
  <div class="col s12 m12 l12" >
    <mz-collapsible [mode]="'expandable'" [popout]="true">
       <mz-collapsible-item [active]="true">
         <mz-collapsible-item-header>
           <i class="material-icons">keyboard_arrow_down</i>
           EXTERNAL AND INTERNAL ADVERTISEMENTS -VARIOUS POSITIONS
       </mz-collapsible-item-header>
           <mz-collapsible-item-body>
             <mz-collection>
               <mz-collection-item>
                   Upload Date:
                   <a href="#" mz-secondary-content download> {{ V11_2023 | date: 'MMM, dd yyyy, HH:mm aaa'}}</a>
               </mz-collection-item>
               <mz-collection-item>
                 Advertisement
                 <a href="/assets/vacancies/2023-24/V11-2023 - External and Internal - various positions.pdf" mz-secondary-content download>
                     <i class=" material-icons download rigth" download>cloud_download</i>
                 </a>
               </mz-collection-item>
               <mz-collection-item>
                 Application Form
                 <a href="/assets/vacancies/Application Form For Employment.pdf"mz-secondary-content download>
                     <i class=" material-icons download rigth" download>cloud_download</i>
                 </a>
               </mz-collection-item>
           </mz-collection>
           </mz-collapsible-item-body>
       </mz-collapsible-item>
    </mz-collapsible>
  </div>
 </div>
<!-- Internal & External End  -->
<!-- Director Technical Services Start  -->
<div class="row">
    <div class="col s12 m12 l12" >
      <mz-collapsible [mode]="'expandable'" [popout]="true">
         <mz-collapsible-item [active]="true">
           <mz-collapsible-item-header>
             <i class="material-icons">keyboard_arrow_down</i>
             DIRECTOR:TECHNICAL SERVICES & DIRECTOR:CORPORATE SERVICES
         </mz-collapsible-item-header>
             <mz-collapsible-item-body>
               <mz-collection>
                 <mz-collection-item>
                     Upload Date:
                     <a href="#" mz-secondary-content download> {{ V10_2023 | date: 'MMM, dd yyyy, HH:mm aaa'}}</a>
                 </mz-collection-item>
                 <mz-collection-item>
                   Advertisement
                   <a href="/assets/vacancies/2023-24/V10 -2023 - Director Corporate Services_Director Technical Services.pdf" mz-secondary-content download>
                       <i class=" material-icons download rigth" download>cloud_download</i>
                   </a>
                 </mz-collection-item>
                 <mz-collection-item>
                   Application Form
                   <a href="/assets/vacancies/2023-24/Aansoek om betrekking - senior bestuur.pdf"mz-secondary-content download>
                       <i class=" material-icons download rigth" download>cloud_download</i>
                   </a>
                 </mz-collection-item>
             </mz-collection>
             </mz-collapsible-item-body>
         </mz-collapsible-item>
      </mz-collapsible>
    </div>
   </div>
<!-- Director Technical Services End  -->


<div class="row">
    <div class="col s12 m12 l12" >
      <mz-collapsible [mode]="'expandable'" [popout]="true">
         <mz-collapsible-item [active]="true">
           <mz-collapsible-item-header>
             <i class="material-icons">keyboard_arrow_down</i>
            ADMINISTRATIVE ASSISTANT
              </mz-collapsible-item-header>
             <mz-collapsible-item-body>
            <mz-collection>
                 <mz-collection-item>
                     Upload Date:
                     <a href="#" mz-secondary-content download> {{ AdminAssist | date: 'MMM, dd yyyy, HH:mm aaa'}}</a>
                 </mz-collection-item>
                 <mz-collection-item>
                   Advertisement
                   <a href="/assets/vacancies/2023-24/Getekende advertensie - Administrative Assistant.pdf" mz-secondary-content download>
                       <i class=" material-icons download rigth" download>cloud_download</i>
                   </a>
                 </mz-collection-item>
                 <mz-collection-item>
                    Application Form
                    <a href="/assets/vacancies/Application Form For Employment.pdf"mz-secondary-content download>
                        <i class=" material-icons download rigth" download>cloud_download</i>
                    </a>
                  </mz-collection-item>
             </mz-collection>
             </mz-collapsible-item-body>
         </mz-collapsible-item>
      </mz-collapsible>
    </div>
</div> 
<!--  End of MM Ad-->
<div class="row">
    <div class="col s12 m12 l12" >
      <mz-collapsible [mode]="'expandable'" [popout]="true">
         <mz-collapsible-item [active]="true">
           <mz-collapsible-item-header>
             <i class="material-icons">keyboard_arrow_down</i>
            EXTERNAL AND INTERNAL ADVERTISEMENTS -VARIOUS POSITIONS
              </mz-collapsible-item-header>
             <mz-collapsible-item-body>
            <mz-collection>
                 <mz-collection-item>
                     Upload Date:
                     <a href="#" mz-secondary-content download> {{ V8_2023 | date: 'MMM, dd yyyy, HH:mm aaa'}}</a>
                 </mz-collection-item>
                 <mz-collection-item>
                   Advertisement
                   <a href="/assets/vacancies/2023-24/V8-2023 -External and Internal - Various positions.pdf" mz-secondary-content download>
                       <i class=" material-icons download rigth" download>cloud_download</i>
                   </a>
                 </mz-collection-item>
                 <mz-collection-item>
                    Application Form
                    <a href="/assets/vacancies/Application Form For Employment.pdf"mz-secondary-content download>
                        <i class=" material-icons download rigth" download>cloud_download</i>
                    </a>
                  </mz-collection-item>
             </mz-collection>
             </mz-collapsible-item-body>
         </mz-collapsible-item>
      </mz-collapsible>
    </div>
</div> 
<!--  End of MM Ad-->
<!-- Application forms header end-->
<div class="row">
    <div class="col s12 m12 l12" >
      <mz-collapsible [mode]="'expandable'" [popout]="true">
         <mz-collapsible-item [active]="true">
           <mz-collapsible-item-header>
             <i class="material-icons">keyboard_arrow_down</i>
             CLANWILLIAM DAM CONSTRUCTION PROJECT VACANCIES
              </mz-collapsible-item-header>
             <mz-collapsible-item-body>
            <mz-collection>
               <mz-collection-item>
                <h6>Dear All</h6>
                <p>               

                    All applications for Matzikama Municipality and surroundings towns of Cederberg Municipality, 
                    should be couriered, since the Post Office in Clanwillaim is no longer operational,  *OR* 
                    should be hand delivered at the CLO's Office in Clanwillaim (as per the address on the adverts - 
                    *Attention Mr. Brian Syster: Community Liaison Officer)*.All applications (posted) must be accompanied 
                    by  a fully and correctly completed New Z83 form, with Proof of Address, e.g.,  
                    Municipality Account or Proof of Bank Details.This only applies to persons that are unable to 
                    hand-deliver their applications.
                    No affidavits will be accepted. <br>
                    <strong>
                        Community Liaison Officer
                    </strong>
                </p>
               </mz-collection-item>
                 <mz-collection-item>
                     Upload Date:
                     <a href="#" mz-secondary-content download> {{ dws202309 | date: 'MMM, dd yyyy, HH:mm aaa'}}</a>
                 </mz-collection-item>
                 <mz-collection-item>
                   Advertisement
                   <a href="/assets/vacancies/2023-24/07092023 - Edited PB Advertisements - CWD1.pdf" mz-secondary-content download>
                       <i class=" material-icons download rigth" download>cloud_download</i>
                   </a>
                 </mz-collection-item>
             </mz-collection>
             </mz-collapsible-item-body>
         </mz-collapsible-item>
      </mz-collapsible>
    </div>
</div> 
<!--  End of MM Ad-->
<div class="row">
    <div class="col s12 m12 l12" >
      <mz-collapsible [mode]="'expandable'" [popout]="true">
         <mz-collapsible-item [active]="true">
           <mz-collapsible-item-header>
             <i class="material-icons">keyboard_arrow_down</i>
             MUNICIPAL MANAGER
              </mz-collapsible-item-header>
             <mz-collapsible-item-body>
               <mz-collection>
                 <mz-collection-item>
                     Upload Date:
                     <a href="#" mz-secondary-content download> {{ V7_2023 | date: 'MMM, dd yyyy, HH:mm aaa'}}</a>
                 </mz-collection-item>
                 <mz-collection-item>
                   Advertisement
                   <a href="/assets/vacancies/2022-23/V7-2023 Municipal Manager - July 2023.pdf" mz-secondary-content download>
                       <i class=" material-icons download rigth" download>cloud_download</i>
                   </a>
                 </mz-collection-item>
                 <mz-collection-item>
                   Application Form
                   <a href="/assets/vacancies/Application Form For Employment.pdf"mz-secondary-content download>
                       <i class=" material-icons download rigth" download>cloud_download</i>
                   </a>
                 </mz-collection-item>
             </mz-collection>
             </mz-collapsible-item-body>
         </mz-collapsible-item>
      </mz-collapsible>
    </div>
</div> 
<div class="row">
    <div class="col s12 m12 l12" >
      <mz-collapsible [mode]="'expandable'" [popout]="true">
         <mz-collapsible-item [active]="true">
           <mz-collapsible-item-header>
             <i class="material-icons">keyboard_arrow_down</i>
             DIRECTOR: CORPORATE SERVICES, COMMUNITY SERVICES, TECHNICAL SERVICES
              </mz-collapsible-item-header>
             <mz-collapsible-item-body>
               <mz-collection>
                 <mz-collection-item>
                     Upload Date:
                     <a href="#" mz-secondary-content download> {{ V6_2023 | date: 'MMM, dd yyyy, HH:mm aaa'}}</a>
                 </mz-collection-item>
                 <mz-collection-item>
                   Advertisement
                   <a href="/assets/vacancies/2022-23/V6-2023 - Advert - Municipal Manager  Dir Corp Tech  Comm (Perm) (005).pdf" mz-secondary-content download>
                       <i class=" material-icons download rigth" download>cloud_download</i>
                   </a>
                 </mz-collection-item>
                 <mz-collection-item>
                   Application Form
                   <a href="/assets/vacancies/Application Form For Employment.pdf"mz-secondary-content download>
                       <i class=" material-icons download rigth" download>cloud_download</i>
                   </a>
                 </mz-collection-item>
             </mz-collection>
             </mz-collapsible-item-body>
         </mz-collapsible-item>
      </mz-collapsible>
    </div>
</div> 
<div class="row">
    <div class="col s12 m12 l12" >
      <mz-collapsible [mode]="'expandable'" [popout]="true">
         <mz-collapsible-item [active]="true">
           <mz-collapsible-item-header>
             <i class="material-icons">keyboard_arrow_down</i>
             MUNICIPAL MANAGER
              </mz-collapsible-item-header>
             <mz-collapsible-item-body>
               <mz-collection>
                 <mz-collection-item>
                     Upload Date:
                     <a href="#" mz-secondary-content download> {{ V4_2023 | date: 'MMM, dd yyyy, HH:mm aaa'}}</a>
                 </mz-collection-item>
                 <mz-collection-item>
                   Advertisement
                   <a href="/assets/vacancies/2022-23/V4-2023 Municipal Manager - April 2023.pdf" mz-secondary-content download>
                       <i class=" material-icons download rigth" download>cloud_download</i>
                   </a>
                 </mz-collection-item>
                 <mz-collection-item>
                   Application Form
                   <a href="/assets/vacancies/Application form for employment of Senior Managers.pdf"mz-secondary-content download>
                       <i class=" material-icons download rigth" download>cloud_download</i>
                   </a>
                 </mz-collection-item>
             </mz-collection>
             </mz-collapsible-item-body>
         </mz-collapsible-item>
      </mz-collapsible>
    </div>
</div> 
<!-- End of V2_2023 -->
<div class="row">
    <div class="col s12 m12 l12" >
      <mz-collapsible [mode]="'expandable'" [popout]="true">
         <mz-collapsible-item [active]="true">
           <mz-collapsible-item-header>
             <i class="material-icons">keyboard_arrow_down</i>
             SENIOR ARTISAN (ELECTRICIAN)
              </mz-collapsible-item-header>
             <mz-collapsible-item-body>
               <mz-collection>
                 <mz-collection-item>
                     Upload Date:
                     <a href="#" mz-secondary-content download> {{ V2_2023 | date: 'MMM, dd yyyy, HH:mm aaa'}}</a>
                 </mz-collection-item>
                 <mz-collection-item>
                   Advertisement
                   <a href="/assets/vacancies/2022-23/V2-2023 - Snr Electrician - Internal and  Eksternal.pdf" mz-secondary-content download>
                       <i class=" material-icons download rigth" download>cloud_download</i>
                   </a>
                 </mz-collection-item>
                 <mz-collection-item>
                   Application Form
                   <a href="/assets/vacancies/Application Form For Employment.pdf"mz-secondary-content download>
                       <i class=" material-icons download rigth" download>cloud_download</i>
                   </a>
                 </mz-collection-item>
             </mz-collection>
             </mz-collapsible-item-body>
         </mz-collapsible-item>
      </mz-collapsible>
    </div>
</div>
<div class="row">
    <div class="col s12 m12 l12" >
      <mz-collapsible [mode]="'expandable'" [popout]="true">
         <mz-collapsible-item [active]="true">
           <mz-collapsible-item-header>
             <i class="material-icons">keyboard_arrow_down</i>
             ARTISAN AND TRAFFIC OFFICER (X2)
              </mz-collapsible-item-header>
             <mz-collapsible-item-body>
               <mz-collection>
                 <mz-collection-item>
                     Upload Date:
                     <a href="#" mz-secondary-content download> {{ V1_2023 | date: 'MMM, dd yyyy, HH:mm aaa'}}</a>
                 </mz-collection-item>
                 <mz-collection-item>
                   Advertisement
                   <a href="/assets/vacancies/2022-23/V1-2023 - Artisan en Verkeersbeampte.pdf" mz-secondary-content download>
                       <i class=" material-icons download rigth" download>cloud_download</i>
                   </a>
                 </mz-collection-item>
                 <mz-collection-item>
                   Application Form
                   <a href="/assets/vacancies/Application Form For Employment.pdf"mz-secondary-content download>
                       <i class=" material-icons download rigth" download>cloud_download</i>
                   </a>
                 </mz-collection-item>
             </mz-collection>
             </mz-collapsible-item-body>
         </mz-collapsible-item>
      </mz-collapsible>
    </div>
   </div>
<div class="row">
  <div class="col s12 m12 l12" >
    <mz-collapsible [mode]="'expandable'" [popout]="true">
       <mz-collapsible-item [active]="true">
         <mz-collapsible-item-header>
           <i class="material-icons">keyboard_arrow_down</i>
           MUNICIPAL FINANCIAL INTERNSHIP: TWO-YEAR CONTRACT POSITION VREDENDAL (4 VACANCIES)
            </mz-collapsible-item-header>
           <mz-collapsible-item-body>
             <mz-collection>
               <mz-collection-item>
                   Upload Date:
                   <a href="#" mz-secondary-content download> {{ V13_2022 | date: 'MMM, dd yyyy, HH:mm aaa'}}</a>
               </mz-collection-item>
               <mz-collection-item>
                 Advertisement:English
                 <a href="/assets/vacancies/2022-23/V13-2022 - Mun Fin Intern - 4  x 2jr Kontrak Poste - Eng.pdf" mz-secondary-content download>
                     <i class=" material-icons download rigth" download>cloud_download</i>
                 </a>
               </mz-collection-item>
               <mz-collection-item>
                Advertisement:Afrikaans
                <a href="/assets/vacancies/2022-23/V13-2022 - Mun Fin Intern - 4  x 2jr Kontrak Poste - Afr.pdf" mz-secondary-content download>
                    <i class=" material-icons download rigth" download>cloud_download</i>
                </a>
              </mz-collection-item>
               <mz-collection-item>
                 Application Form
                 <a href="/assets/vacancies/Application Form For Employment.pdf"mz-secondary-content download>
                     <i class=" material-icons download rigth" download>cloud_download</i>
                 </a>
               </mz-collection-item>
           </mz-collection>
           </mz-collapsible-item-body>
       </mz-collapsible-item>
    </mz-collapsible>
  </div>
 </div>
<div class="row">
  <div class="col s12 m12 l12" >
    <mz-collapsible [mode]="'expandable'" [popout]="true">
       <mz-collapsible-item [active]="true">
         <mz-collapsible-item-header>
           <i class="material-icons">keyboard_arrow_down</i>
           DIRECTOR: TECHNICAL SERVICES
       </mz-collapsible-item-header>
           <mz-collapsible-item-body>
             <mz-collection>
               <mz-collection-item>
                   Upload Date:
                   <a href="#" mz-secondary-content download> {{ V12_2022 | date: 'MMM, dd yyyy, HH:mm aaa'}}</a>
               </mz-collection-item>
               <mz-collection-item>
                 Advertisement
                 <a href="/assets/vacancies/2022-23/V12-2022 - Directors Tech Services.pdf" mz-secondary-content download>
                     <i class=" material-icons download rigth" download>cloud_download</i>
                 </a>
               </mz-collection-item>
               <mz-collection-item>
                 Application Form
                 <a href="/assets/vacancies/Senior Management Recruitment Form.pdf"mz-secondary-content download>
                     <i class=" material-icons download rigth" download>cloud_download</i>
                 </a>
               </mz-collection-item>
           </mz-collection>
           </mz-collapsible-item-body>
       </mz-collapsible-item>
    </mz-collapsible>
  </div>
 </div>
<div class="row">
  <div class="col s12 m12 l12" >
    <mz-collapsible [mode]="'expandable'" [popout]="true">
       <mz-collapsible-item [active]="true">
         <mz-collapsible-item-header>
           <i class="material-icons">keyboard_arrow_down</i>
          MANAGER:  ELECTRO-TECHNICAL SERVICES
            </mz-collapsible-item-header>
           <mz-collapsible-item-body>
             <mz-collection>
               <mz-collection-item>
                   Upload Date:
                   <a href="#" mz-secondary-content download> {{ V11_2022 | date: 'MMM, dd yyyy, HH:mm aaa'}}</a>
               </mz-collection-item>
               <mz-collection-item>
                 Advertisement
                 <a href="/assets/vacancies/2022-23/V11-2022 -Manager Elektro-Technical Services.pdf" mz-secondary-content download>
                     <i class=" material-icons download rigth" download>cloud_download</i>
                 </a>
               </mz-collection-item>
               <mz-collection-item>
                 Application Form
                 <a href="/assets/vacancies/Senior Management Recruitment Form.pdf"mz-secondary-content download>
                     <i class=" material-icons download rigth" download>cloud_download</i>
                 </a>
               </mz-collection-item>
           </mz-collection>
           </mz-collapsible-item-body>
       </mz-collapsible-item>
    </mz-collapsible>
  </div>
 </div>
 <!-- Municipal Finance Intern end-->
<div class="row">
  <div class="col s12 m12 l12" >
    <mz-collapsible [mode]="'expandable'" [popout]="true">
       <mz-collapsible-item [active]="true">
         <mz-collapsible-item-header>
           <i class="material-icons">keyboard_arrow_down</i>
           MUNISIPALE FINANSIËLE INTERNSKAP TWEE-JAAR KONTRAKPOS
            </mz-collapsible-item-header>
           <mz-collapsible-item-body>
             <mz-collection>
               <mz-collection-item>
                   Upload Date:
                   <a href="#" mz-secondary-content download> {{ V10_2022 | date: 'MMM, dd yyyy, HH:mm aaa'}}</a>
               </mz-collection-item>
               <mz-collection-item>
                 Advertisement
                 <a href="/assets/vacancies/2022-23/V10-2022 - Mun Fin Intern - 1 KIontrak Pos.pdf" mz-secondary-content download>
                     <i class=" material-icons download rigth" download>cloud_download</i>
                 </a>
               </mz-collection-item>
               <mz-collection-item>
                 Application Form
                 <a href="/assets/vacancies/Application Form For Employment.pdf"mz-secondary-content download>
                     <i class=" material-icons download rigth" download>cloud_download</i>
                 </a>
               </mz-collection-item>
           </mz-collection>
           </mz-collapsible-item-body>
       </mz-collapsible-item>
    </mz-collapsible>
  </div>
 </div>
<div class="row">
 <div class="col s12 m12 l12" >
   <mz-collapsible [mode]="'expandable'" [popout]="true">
      <mz-collapsible-item [active]="true">
        <mz-collapsible-item-header>
          <i class="material-icons">keyboard_arrow_down</i>
          DIRECTOR: TECHNICAL SERVICES
      </mz-collapsible-item-header>
          <mz-collapsible-item-body>
            <mz-collection>
              <mz-collection-item>
                  Upload Date:
                  <a href="#" mz-secondary-content download> {{ V8_2022 | date: 'MMM, dd yyyy, HH:mm aaa'}}</a>
              </mz-collection-item>
              <mz-collection-item>
                Advertisement
                <a href="/assets/vacancies/2021-22/V8-2022 - Directors Tech Services - full ad - Eng.pdf" mz-secondary-content download>
                    <i class=" material-icons download rigth" download>cloud_download</i>
                </a>
              </mz-collection-item>
              <mz-collection-item>
                Application Form
                <a href="/assets/vacancies/Senior Management Recruitment Form.pdf"mz-secondary-content download>
                    <i class=" material-icons download rigth" download>cloud_download</i>
                </a>
              </mz-collection-item>
          </mz-collection>
          </mz-collapsible-item-body>
      </mz-collapsible-item>
   </mz-collapsible>
 </div>
</div>
<div class="row">
  <div class="col s12 m12 l12">
      <mz-collapsible [mode]="'expandable'" [popout]="true">
          <mz-collapsible-item [active]="true">
              <mz-collapsible-item-header>
                  <i class="material-icons">keyboard_arrow_down</i>
                  MANAGER: LEGAL & ADMINISTRATION SERVICES AND OCCUPATIONAL HEALTH AND SAFETY OFFICER
              </mz-collapsible-item-header>
              <mz-collapsible-item-body>
                  <mz-collection>
                      <mz-collection-item>
                          Upload Date:
                          <a href="#" mz-secondary-content download> {{ V7_2022 | date: 'MMM, dd yyyy, HH:mm aaa'}}</a>
                      </mz-collection-item>
                      <mz-collection-item>
                        Advertisement
                        <a href="/assets/vacancies/2021-22/V7-2022 - Man Leg and Admin and OHS Officer.pdf" mz-secondary-content download>
                            <i class=" material-icons download rigth" download>cloud_download</i>
                        </a>
                      </mz-collection-item>
                      <mz-collection-item>
                        Application Form
                        <a href="/assets/vacancies/Application Form For Employment.pdf"mz-secondary-content download>
                            <i class=" material-icons download rigth" download>cloud_download</i>
                        </a>
                      </mz-collection-item>
                  </mz-collection>
              </mz-collapsible-item-body>
          </mz-collapsible-item>
      </mz-collapsible>
  </div>
</div>
<div class="row">
    <div class="col s12 m12 l12">
        <mz-collapsible [mode]="'expandable'" [popout]="true">
            <mz-collapsible-item [active]="true">
                <mz-collapsible-item-header>
                    <i class="material-icons">keyboard_arrow_down</i>
                    Various Positions as Advertised in Ons Kontrei on 17 March 2022.
                </mz-collapsible-item-header>
                <mz-collapsible-item-body>
                    <mz-collection>
                        <mz-collection-item>
                            Upload Date:
                            <a href="#" mz-secondary-content download> {{ V5_2022 | date: 'MMM, dd yyyy, HH:mm aaa'}}</a>
                        </mz-collection-item>
                        <mz-collection-item>
                          Advertisement
                          <a href="/assets/vacancies/2021-22/V5-2022 -Various positions.pdf" mz-secondary-content download>
                              <i class=" material-icons download rigth" download>cloud_download</i>
                          </a>
                        </mz-collection-item>
                        <mz-collection-item>
                          Application Form
                          <a href="/assets/vacancies/Application Form For Employment.pdf"mz-secondary-content download>
                              <i class=" material-icons download rigth" download>cloud_download</i>
                          </a>
                        </mz-collection-item>
                    </mz-collection>
                </mz-collapsible-item-body>
            </mz-collapsible-item>
        </mz-collapsible>
    </div>
</div>
<div class="row">
  <div class="col s12 m12 l12">
    <mz-collapsible [mode]="'expandable'" [popout]="true">
      <mz-collapsible-item [active]="true">
        <mz-collapsible-item-header>
          <i class="material-icons">keyboard_arrow_down</i>
          Directors Technical Services, Corporate Services and Manager Electrical Services
        </mz-collapsible-item-header>
        <mz-collapsible-item-body>
          <mz-collection>
            <mz-collection-item>
              <mz-collection-item >
                Upload Date:
                <a href="#" mz-secondary-content download> {{ V4_2022 | date: 'MMM, dd yyyy, HH:mm aaa'}}</a>
            </mz-collection-item>
            <mz-collection-item>
                Advertisement
                <a href="/assets/vacancies/2021-22/V4-2022 - Directors Tech Services and Corporate Service and Manager Electrical - Eng.pdf"mz-secondary-content download>
                    <i class=" material-icons download rigth" download>cloud_download</i>
                </a>
            </mz-collection-item>
            <mz-collection-item>
                Application Form
                <a href="/assets/vacancies/Senior Management Recruitment Form.pdf"mz-secondary-content download>
                    <i class=" material-icons download rigth" download>cloud_download</i>
                </a>
            </mz-collection-item>
            </mz-collection-item>
          </mz-collection>
        </mz-collapsible-item-body>
      </mz-collapsible-item>
    </mz-collapsible>
  </div>
</div>
<div class="row">
  <div class="col s12 m12 l12">
    <mz-collapsible [mode]="'expandable'" [popout]="true">
      <mz-collapsible-item [active]="true">
        <mz-collapsible-item-header>
          <i class="material-icons">keyboard_arrow_down</i>
          MUNICIPAL MANAGER, EXTENSION TILL FRIDAY, 28 JANUARY 2022 AT 15H30
        </mz-collapsible-item-header>
        <mz-collapsible-item-body>
          <mz-collection>
            <mz-collection-item>
              <mz-collection-item >
                Upload Date:
                <a href="#" mz-secondary-content download> {{ V1_2022 | date: 'MMM, dd yyyy, HH:mm aaa'}}</a>
            </mz-collection-item>
            <mz-collection-item>
                Advertisement
                <a href="/assets/vacancies/2021-22/V1-2022- Advert - MM position - Lang - Jan 2022.pdf"mz-secondary-content download>
                    <i class=" material-icons download rigth" download>cloud_download</i>
                </a>
            </mz-collection-item>
            <mz-collection-item>
                Application Form
                <a href="/assets/vacancies/Senior Management Recruitment Form.pdf"mz-secondary-content download>
                    <i class=" material-icons download rigth" download>cloud_download</i>
                </a>
            </mz-collection-item>
            </mz-collection-item>
          </mz-collection>
        </mz-collapsible-item-body>
      </mz-collapsible-item>
    </mz-collapsible>
  </div>
</div>
<div class="row">
  <div class="col s12 m12 l12">
    <mz-collapsible [mode]="'expandable'" [popout]="true">
      <mz-collapsible-item [active] = "true">
        <mz-collapsible-item-header>
          <i class="material-icons">keyboard_arrow_down</i>
          ASSISTANT TOWN PLANNER – MATZIKAMA AREA & ASSISTANT LIBRARIAN - LUTZVILLE
        </mz-collapsible-item-header>
        <mz-collapsible-item-body>
          <mz-collection>
            <mz-collection-item>
              Upload Date:
              <a href="#" mz-secondary-content download> {{ V13_2021 | date: 'MMM, dd yyyy, HH:mm aaa'}}</a>
            </mz-collection-item>
            <mz-collection-item>
              Advertisement
              <a href="/assets/vacancies/2021-22/V13- External - Asst TPlanner and Asst Librarian Lutzville.pdf"mz-secondary-content download>
                  <i class=" material-icons download rigth" download>cloud_download</i>
              </a>
            </mz-collection-item>
            <mz-collection-item >
              Application Form
                  <a href="/assets/vacancies/Application Form For Employment.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
          </mz-collection-item>
          </mz-collection>
        </mz-collapsible-item-body>
      </mz-collapsible-item>
    </mz-collapsible>
  </div>
</div>
<div class="row">
  <div class="col s12 m12 l12">
    <mz-collapsible
    [mode]="'expandable'"
    [popout]="true">
    <mz-collapsible-item [active]="true">
        <mz-collapsible-item-header>
                    <i class="material-icons">keyboard_arrow_down</i>
                    Municipal Manager November 2021
        </mz-collapsible-item-header>
            <mz-collapsible-item-body>
                <mz-collection>
                    <mz-collection-item >
                        Upload Date:
                        <a href="#" mz-secondary-content download> {{ V14_2021 | date: 'MMM, dd yyyy, HH:mm aaa'}}</a>
                    </mz-collection-item>
                    <mz-collection-item>
                        Advertisement
                        <a href="/assets/vacancies/2021-22/V14-2021- Advert - Municipal Manager.pdf"mz-secondary-content download>
                            <i class=" material-icons download rigth" download>cloud_download</i>
                        </a>
                    </mz-collection-item>
                    <mz-collection-item>
                        Application Form
                        <a href="/assets/vacancies/Senior Management Recruitment Form.pdf"mz-secondary-content download>
                            <i class=" material-icons download rigth" download>cloud_download</i>
                        </a>
                    </mz-collection-item>
                </mz-collection>
            </mz-collapsible-item-body>
        </mz-collapsible-item>
    </mz-collapsible>
  </div>
</div>
  <div class="row">
    <div class="col s12 m12 l12">
      <mz-collapsible
      [mode]="'expandable'"
      [popout]="true">
      <mz-collapsible-item [active]="true">
          <mz-collapsible-item-header>
                      <i class="material-icons">keyboard_arrow_down</i>
                      SENIOR CLERK/CASHIER/LIBRARY ASSISTANT/COMMUNITY LIAISON OFFICER – KOEKENAAP – (RE-ADVERTISEMENT)
          </mz-collapsible-item-header>
              <mz-collapsible-item-body>
                  <mz-collection>
                    <mz-collection-item >
                        Upload Date:
                        <a href="#" mz-secondary-content download> {{ V12_2021 | date: 'MMM, dd yyyy, HH:mm aaa'}}</a>
                    </mz-collection-item>
                    <mz-collection-item>
                        Advertisement English
                        <a href="/assets/vacancies/2021-22/V12- 2021- Snr Clerk Bib - Koekenaap only - External - English.pdf"mz-secondary-content download>
                            <i class=" material-icons download rigth" download>cloud_download</i>
                        </a>
                    </mz-collection-item>
                    <mz-collection-item>
                        Advertisement Afrikaans
                        <a href="/assets/vacancies/2021-22/V12-2021 - Snr Klerk  en Bib slegs Koekenaap - Ekstern - Afrikaans.pdf"mz-secondary-content download>
                            <i class=" material-icons download rigth" download>cloud_download</i>
                        </a>
                    </mz-collection-item>
                    <mz-collection-item>
                        Application Form
                        <a href="/assets/vacancies/AANSOEK OM BETREKKINGSVORM.PDF"mz-secondary-content download>
                            <i class=" material-icons download rigth" download>cloud_download</i>
                        </a>
                    </mz-collection-item>
                  </mz-collection>
              </mz-collapsible-item-body>
          </mz-collapsible-item>
      </mz-collapsible>
    </div>
</div>
  <div class="row">
      <div class="col s12 m12 l12">
        <mz-collapsible
        [mode]="'expandable'"
        [popout]="true">
        <mz-collapsible-item [active]="true">
            <mz-collapsible-item-header>
                        <i class="material-icons">keyboard_arrow_down</i>
                        Municipal Manager
            </mz-collapsible-item-header>
                <mz-collapsible-item-body>
                    <mz-collection>
                        <mz-collection-item >
                            Upload Date:
                            <a href="#" mz-secondary-content download> {{ municipalManagerAugust2021 | date: 'MMM, dd yyyy, HH:mm aaa'}}</a>
                        </mz-collection-item>
                        <mz-collection-item>
                            Advertisement
                            <a href="/assets/vacancies/2021-22/V10-2021- Advert - MM position - August 2021.pdf"mz-secondary-content download>
                                <i class=" material-icons download rigth" download>cloud_download</i>
                            </a>
                        </mz-collection-item>
                        <mz-collection-item>
                            Application Form
                            <a href="/assets/vacancies/Senior Management Recruitment Form.pdf"mz-secondary-content download>
                                <i class=" material-icons download rigth" download>cloud_download</i>
                            </a>
                        </mz-collection-item>
                    </mz-collection>
                </mz-collapsible-item-body>
            </mz-collapsible-item>
        </mz-collapsible>
      </div>
  </div>
  <div class="row">
      <div class="col s12 m12 l12">
        <mz-collapsible
        [mode]="'expandable'"
        [popout]="true">
        <mz-collapsible-item [active]="true">
            <mz-collapsible-item-header>
                        <i class="material-icons">keyboard_arrow_down</i>
                        Municipal Manager Re-advertisement
            </mz-collapsible-item-header>
                <mz-collapsible-item-body>
                    <mz-collection>
                        <mz-collection-item >
                            Upload Date:
                            <a href="#" mz-secondary-content download> {{ municipalManagerApril2021 | date: 'MMM, dd yyyy, HH:mm aaa'}}</a>
                        </mz-collection-item>
                        <mz-collection-item>
                            Advertisement
                            <a href="/assets/vacancies/2020-21/V8-2021-MM.pdf"mz-secondary-content download>
                                <i class=" material-icons download rigth" download>cloud_download</i>
                            </a>
                        </mz-collection-item>
                    </mz-collection>
                </mz-collapsible-item-body>
            </mz-collapsible-item>
        </mz-collapsible>
      </div>
  </div>
  <div class="row">
    <div class="col s12 m12 l12">
        <mz-collapsible
        [mode]="'expandable'"
        [popout]="true">
        <mz-collapsible-item [active]="true">
            <mz-collapsible-item-header>
                        <i class="material-icons">keyboard_arrow_down</i>
                        Municipal Manager
            </mz-collapsible-item-header>
                <mz-collapsible-item-body>
                    <mz-collection>
                        <mz-collection-item >
                            Upload Date:
                            <a href="#" mz-secondary-content download> {{ municipalManagerMarch2021 | date: 'MMM, dd yyyy, HH:mm aaa'}}</a>
                        </mz-collection-item>
                        <mz-collection-item>
                            Advertisement
                            <a href="/assets/vacancies/2020-21/Municipal Manager.pdf"mz-secondary-content download>
                                <i class=" material-icons download rigth" download>cloud_download</i>
                            </a>
                        </mz-collection-item>
                        <mz-collection-item >
                            Application Form
                                <a href="/assets/vacancies/Senior Management Recruitment Form.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>
                    </mz-collection>
                </mz-collapsible-item-body>
            </mz-collapsible-item>
        </mz-collapsible>
    </div>
</div>
<div class="row">
    <div class="col s12 m12 l12">
        <mz-collapsible
        [mode]="'expandable'"
        [popout]="true">
        <mz-collapsible-item [active]="true">
            <mz-collapsible-item-header>
                        <i class="material-icons">keyboard_arrow_down</i>
                        Manager: Electro-Technical Services
            </mz-collapsible-item-header>
                <mz-collapsible-item-body>
                    <mz-collection>
                        <mz-collection-item >
                            Upload Date:
                            <a href="#" mz-secondary-content download> {{ ManagerElecFeb2021 | date: 'MMM, dd yyyy, HH:mm aaa'}}</a>
                        </mz-collection-item>
                        <mz-collection-item>
                            Advertisement
                            <a href="/assets/vacancies/2020-21/V1-2021 - Manager Electro -Technical Services.pdf"mz-secondary-content download>
                                <i class=" material-icons download rigth" download>cloud_download</i>
                            </a>
                        </mz-collection-item>
                        <mz-collection-item >
                            Application Form
                                <a href="/assets/vacancies/2020-21/AANSOEK OM BETREKKINGSVORM (1).PDF" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>
                    </mz-collection>
                </mz-collapsible-item-body>
            </mz-collapsible-item>
        </mz-collapsible>
    </div>
</div>
<div class="row">
    <div class="col s12 m12 l12">
        <mz-collapsible
        [mode]="'expandable'"
        [popout]="true">
        <mz-collapsible-item [active]="true">
            <mz-collapsible-item-header>
                        <i class="material-icons">keyboard_arrow_down</i>
                                Manager Infrastructure Services
            </mz-collapsible-item-header>
                <mz-collapsible-item-body>
                    <mz-collection>
                        <mz-collection-item >
                            Upload Date:
                            <a href="#" mz-secondary-content download> {{ ManagerInfraAug2020 | date: 'MMM, dd yyyy, HH:mm:ss aaa'}}</a>
                        </mz-collection-item>
                        <mz-collection-item>
                            Advertisement Afrikaans
                            <a href="/assets/vacancies/2020-21/V9-2020 -  Afr - Bestuurder Infrastruktuur.pdf"mz-secondary-content download>
                                <i class=" material-icons download rigth" download>cloud_download</i>
                            </a>
                        </mz-collection-item>
                        <mz-collection-item >
                            Advertisement English
                                <a href="/assets/vacancies/2020-21/V9-2020 -Eng Manager Infrastructure.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>
                        <mz-collection-item >
                            Application Form
                                <a href="/assets/vacancies/2020-21/AANSOEK OM BETREKKINGSVORM (1).pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>
                    </mz-collection>
                </mz-collapsible-item-body>
            </mz-collapsible-item>
    </mz-collapsible>
    </div>
</div>
<div class="row">
    <div class="col s12 m12 l12">
        <mz-collapsible
        [mode]="'expandable'"
        [popout]="true">
        <mz-collapsible-item [active]="true">
            <mz-collapsible-item-header>
                     <i class="material-icons">keyboard_arrow_down</i>
                     <strong>Extended Closing Date : Municipal Manager & Director Infrastructure Vacancy</strong>
            </mz-collapsible-item-header>
            <mz-collapsible-item-body>
                <mz-collection>
                    <mz-collection-item >
                        Upload Date:
                        <a href="#" mz-secondary-content download> {{ extendMunManAndDir | date: 'MMM, dd yyyy, HH:mm:ss aaa'}}</a>
                    </mz-collection-item>
                    <mz-collection-item >
                       <p>
                        NEEM ASSEBLIEF KENNIS DAT DIE SLUITINGSDATUM VIR DIE VAKANTE POSTE VAN MUNISIPALE BESTURRDER
                        EN DIREKTEUR : INFRASTRUKTUURDIENSTE, SOOS GEADVERTEER IN DIE BURGER VAN 07 MAART 2020, HIERMEE
                        TOT <strong>20 APRIL 2020</strong> VERLENG WORD.
                       </p>
                       <p>
                        PLEASE NOTE THAT THE CLOSING DATE FOR THE VACANCIES OF MUNICIPAL MANAGAER AND DIRECTOR : INFRASTRUCTURE SERVICES,
                        AS ADVERTISED IN DIE BURGER OF 07 MARCH 2020, IS NOW EXTENDED UNTILL <strong>20 APRIL 2020.</strong>
                        </p>
                        <p>
                            <a href="/assets/vacancies/139333 - Burger.pdf" download> Download Notice : Burger</a> <br>
                            <a href="/assets/vacancies/139333 - Rapport.pdf" download> Download Notice: Rapport</a><br>
                            <a href="/assets/vacancies/139333 - Sunday Times.pdf" download> Download Notice: Sunday Times</a><br>
                        </p>
                    </mz-collection-item>
                    <mz-collection-item >
                        <p>
                            DIE MUNISIPALE BESTUURDER ADVERTENSIE WORD AS VOLG GEWYSIG:
                            <strong>
                                {{ 'Dat die woorde “(Drie (3) jaar prestasiegebaseerde vaste termyn kontrak)” op die volledige advertensie
                                vervang word met die woorde: “(’n prestasiegebaseerde vaste kontrak vir ’n termyn van tot 1 jaar na die
                                volgende plaaslike-owerheids-verkiesing)” ' | uppercase}}
                            </strong> <br>
                           </p>
                            <p>
                            <strong>{{ 'Please take note that the advertisement for the vacant Municipal Manager post (V2/2020),
                                that is currently advertised with an extended closing date of 20 April 2020, be changed as follows:
                                The words “Three (3) year performance based fixed term contract” on the full advertisement be replaced
                                with the words “Performance based fixed term contract for a term of up to one (1) year after the following
                                local government elections”' | uppercase}}
                            </strong><br>
                             <!--Uppercase Pipe-->
                             <strong>
                                Text amended on : {{ mmAndDirAdAmendment | date: 'MMM, dd yyyy, HH:mm'}}
                             </strong>
                           </p>
                           <p>
                            <a href="/assets/vacancies/V2-2020 - Wysiging van advertensie van die Munisipale Bestuurderspos.pdf" download>Download : Munisipale Bestuurder Advertensie Wysiging Kennisgewing (Afrikaans)</a><br>
                           </p>
                           <p>
                            <a href="/assets/vacancies/V2-2020 - Wysiging van advertensie van die Munisipale Bestuurderspos (Eng).pdf" download>Download : Municipal Manager Advertisement Amendment (English)</a>
                           </p>
                     </mz-collection-item>
                     <mz-collection-item >


                     </mz-collection-item>
                </mz-collection>
            </mz-collapsible-item-body>
        </mz-collapsible-item>

    </mz-collapsible>
    </div>
</div>
<div class="row">
    <div class="col s12 m12 l12">
        <mz-collapsible
            [mode]="'expandable'"
            [popout]="true">
            <mz-collapsible-item>
                <mz-collapsible-item-header>
                    <i class="material-icons">keyboard_arrow_down</i>
                         <strong>V5-2020- Ekstern - Stadsbeplanner, SENIOR AMBAGSMAN (ELEKTRISIËN), Eerste Klerk Kassier Verkeer Lutzville</strong>
                </mz-collapsible-item-header>
                <mz-collapsible-item-body>
                    <mz-collection>
                        <mz-collection-item>
                            Upload Date:
                            <a href="#" mz-secondary-content download> {{ v52020 | date: 'MMM, dd yyyy, HH:mm:ss aaa'}}</a>
                        </mz-collection-item>
                        <mz-collection-item >
                            Aansoek om betrekking
                                <a href="/assets/vacancies/AANSOEK OM BETREKKINGSVORM.PDF" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>
                        <mz-collection-item >
                            V5-2020- Ekstern - Stadsbeplanner, SENIOR AMBAGSMAN (ELEKTRISIËN), Eerste Klerk Kassier Verkeer Lutzville
                                <a href="/assets/vacancies/V5-2020- Ekstern - Stadsbepl Sen Kler Kassie Verkeer Lutzville.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>
                        <mz-collection-item>
                            Kennisgewing: Verlenging van Sluitingsdatum na 30 April 2020
                            <a href="#" mz-secondary-content download> {{ v52020_1 | date: 'MMM, dd yyyy, HH:mm:ss aaa'}}</a>
                        </mz-collection-item>
                        <mz-collection-item>
                            Download Notice: Verlenging van Sluitingsdatum na 30 April 2020
                            <a href="/assets/vacancies/V5-2020 - verleng.pdf" mz-secondary-content download ><i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>

                    </mz-collection>
                </mz-collapsible-item-body>
            </mz-collapsible-item>
        </mz-collapsible>
    </div>
</div>
<div class="row">
    <div class="col s12 m12 l12">
        <mz-collapsible
        [mode]="'expandable'"
        [popout]="true">
    <mz-collapsible-item [active]="true">
        <mz-collapsible-item-header>
                 <!-- <i class="material-icons">keyboard_arrow_down</i> -->
                 <table class="striped">
                    <thead>
                      <tr>
                          <th>Municipal Manager & Director Infrastructure Vacancy</th>
                          <th class="right">Upload Date : 09 March 2020 12:16</th>
                      </tr>
                    </thead>
                </table>
                    <!-- <span class="right" id="vacancyDate">07 February 2020 22:01</span>                    -->
        </mz-collapsible-item-header>
        <mz-collapsible-item-body>
            <mz-collection>
                <mz-collection-item >
                    Aansoek om betrekking - Senior Bestuur

                        <a href="/assets/vacancies/Aansoek om betrekking - senior bestuur.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                </mz-collection-item>
               <mz-collection-item >
                    V2-2020 - MM and Director  Infrastructure - Eng
                        <a href="/assets/vacancies/V2-2020 - MM and Direcector  Infrastructure - Eng.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                </mz-collection-item>
                <mz-collection-item >
                    V2-2020 - MM en Direkteur Infrastruktuur - AFr
                        <a href="/assets/vacancies/V2-2020 - MM en Direkteur Infrastruktuur - AFr.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                </mz-collection-item>
                <mz-collection-item>
                    Belangrike Kennisgewing: Wysiging van Advertensie
                    <a href="#" mz-secondary-content download> {{ mmAndDirAdAmendment | date: 'MMM, dd yyyy, HH:mm:ss aaa'}}</a>
                </mz-collection-item>
                <mz-collection-item>
                    Wysiging van Advertensie
                    <a href="/assets/vacancies/V2-2020 - Wysiging van advertensie van die Munisipale Bestuurderspos.pdf" mz-secondary-content download ><i class=" material-icons download rigth" download>cloud_download</i></a>
                </mz-collection-item>
            </mz-collection>
        </mz-collapsible-item-body>
    </mz-collapsible-item>
    </mz-collapsible>
    </div>
</div>
<div class="row">
    <div class="col s12 m12 l12">
        <mz-collapsible [mode]="'expandable'" [popout]="true">
            <mz-collapsible-item [active]="true">
                <mz-collapsible-item-header>
                         <!-- <i class="material-icons">keyboard_arrow_down</i> -->
                         <table class="striped">
                            <thead>
                              <tr>
                                  <th> Advert for Municipal Manager Vacancy</th>
                                  <th class="right">Upload Date : 07 February 2020 22:01</th>
                              </tr>
                            </thead>
                        </table>
                            <!-- <span class="right" id="vacancyDate">07 February 2020 22:01</span>                    -->
                </mz-collapsible-item-header>
                <mz-collapsible-item-body>
                    <mz-collection>
                        <mz-collection-item >
                            Aansoek om betrekking - Senior Bestuur

                                <a href="/assets/vacancies/Aansoek om betrekking - senior bestuur.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>
                       <mz-collection-item >
                            V1-2020 - MM - Afr volledige advertensie
                                <a href="/assets/vacancies/V1-2020 - MM - Afr volledige advertensie.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>
                        <mz-collection-item >
                            V1-2020 - MM volledige advertensie - Engels
                                <a href="/assets/vacancies/V1-2020 - MM volledige advertensie - Engels.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>
                    </mz-collection>
                </mz-collapsible-item-body>
            </mz-collapsible-item>
        </mz-collapsible>
    </div>
</div>
</div>
