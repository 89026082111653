import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ward-committee',
  templateUrl: './ward-committee.component.html',
  styleUrls: ['./ward-committee.component.css']
})
export class WardCommitteeComponent implements OnInit {

  wcBylaw2021AFR:Date = new Date('2021-11-03 10:29');
  wcBylaw2021ENG:Date = new Date('2021-11-03 10:39');
  wcBylaw2023AFR:Date = new Date('2023-02-10 07:48');
  wcBylaw2023ENG:Date = new Date('2023-02-10 07:46');


  constructor() { }

  ngOnInit() {
  }

}
