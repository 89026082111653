<h4 class="center">Performance Management</h4>
<div class="container">
    <div class="row">
        <div class="s12 m12 l12">
            <mz-collapsible [mode]="'expandable'" [popout]="true">
                <mz-collapsible-item [active]="true">
                    <mz-collapsible-item-header>
                        <i class="material-icons">keyboard_arrow_down</i>
                        Matzikama Municipality 2023-24 Performance Agreements
                    </mz-collapsible-item-header>
                    <mz-collapsible-item-body>
                        <mz-collection>
                            <mz-collection-item >
                                Director Development Planning & Community Services
                                    <a href="/assets/StrategicDocs/performance/2023-24/PA Planning_Dev.pdf" mz-secondary-content download>
                                        {{ Agreements202324| date: 'MMM, dd yyyy'}}
                                        <i class=" material-icons download rigth" download>cloud_download</i></a>
                            </mz-collection-item>
                            <mz-collection-item >
                                Director Financial Services
                                      <a href="/assets/StrategicDocs/performance/2023-24/PA CFO.pdf" mz-secondary-content download>
                                          {{Agreements202324 | date: 'MMM, dd yyyy'}}
                                          <i class=" material-icons download rigth" download>cloud_download</i></a>
                            </mz-collection-item>
                        </mz-collection>
                    </mz-collapsible-item-body>
                </mz-collapsible-item>
            </mz-collapsible>
        </div>
      </div>
  <div class="row">
    <div class="s12 m12 l12">
        <mz-collapsible [mode]="'expandable'" [popout]="true">
            <mz-collapsible-item [active]="true">
                <mz-collapsible-item-header>
                    <i class="material-icons">keyboard_arrow_down</i>
                    Matzikama Municipality 2022-23 Performance Agreements
                </mz-collapsible-item-header>
                <mz-collapsible-item-body>
                    <mz-collection>
                        <mz-collection-item >
                            Director Development Planning & Community Services
                                <a href="/assets/StrategicDocs/performance/202223/PA Dev Planning and Comm Service Signed Split.pdf" mz-secondary-content download>
                                    {{ Agreements202223| date: 'MMM, dd yyyy'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>
                        <mz-collection-item >
                          Director Financial Services
                                  <a href="/assets/StrategicDocs/performance/202223/PA Fin Signed Split.pdf" mz-secondary-content download>
                                      {{Agreements202223 | date: 'MMM, dd yyyy'}}
                                      <i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>
                        <mz-collection-item >
                          Municipal Manager
                                  <a href="/assets/StrategicDocs/performance/202223/PA MM Full Signed Split.pdf" mz-secondary-content download>
                                      {{ Agreements202223 | date: 'MMM, dd yyyy'}}
                                      <i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>
                    </mz-collection>
                </mz-collapsible-item-body>
            </mz-collapsible-item>
        </mz-collapsible>
    </div>
  </div>
    <div class="row">
        <div class="s12 m12 l12">
            <mz-collapsible [mode]="'expandable'" [popout]="true">
                <mz-collapsible-item [active]="true">
                    <mz-collapsible-item-header>
                        <i class="material-icons">keyboard_arrow_down</i>
                        Matzikama Municipality 2021-22 Performance Agreements
                    </mz-collapsible-item-header>
                    <mz-collapsible-item-body>
                        <mz-collection>
                            <mz-collection-item >
                                Director Community Development Services
                                     <a href="/assets/StrategicDocs/performance/2021-22/PA Dir CDS 2021-22 Final Signed.pdf" mz-secondary-content download>
                                         {{ Agreements202122| date: 'MMM, dd yyyy'}}
                                         <i class=" material-icons download rigth" download>cloud_download</i></a>
                             </mz-collection-item>
                             <mz-collection-item >
                                 Director Infrastructure Services
                                      <a href="/assets/StrategicDocs/performance/2021-22/PA Dir Infra 2021-22 Final Signed.pdf" mz-secondary-content download>
                                          {{Agreements202122 | date: 'MMM, dd yyyy'}}
                                          <i class=" material-icons download rigth" download>cloud_download</i></a>
                             </mz-collection-item>
                             <mz-collection-item >
                                 Director Financial Services
                                      <a href="/assets/StrategicDocs/performance/2021-22/PA Dir Fin 2021-22 Final Signed.pdf" mz-secondary-content download>
                                          {{ Agreements202122 | date: 'MMM, dd yyyy'}}
                                          <i class=" material-icons download rigth" download>cloud_download</i></a>
                             </mz-collection-item>
                        </mz-collection>
                    </mz-collapsible-item-body>
                </mz-collapsible-item>
            </mz-collapsible>
        </div>
    </div>
<div class="row">
    <div class="col s12 m12 l12">

        <mz-collapsible
            [mode]="'expandable'"
            [popout]="true">
            <mz-collapsible-item [active]="true">
                <mz-collapsible-item-header>
                         <i class="material-icons">keyboard_arrow_down</i>
                            Matzikama Municipality 2020-21 Performance Agreements
                </mz-collapsible-item-header>
                <mz-collapsible-item-body>
                    <mz-collection>
                        <mz-collection-item >
                           Director Community Development Services
                                <a href="/assets/StrategicDocs/performance/202021/Dir Community Development Services-compressed.pdf" mz-secondary-content download>
                                    {{ Agreements202021| date: 'MMM, dd yyyy'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>
                        <mz-collection-item >
                            Director Infrastructure Services
                                 <a href="/assets/StrategicDocs/performance/202021/PA Dir Tech 2020-21_2-compressed.pdf" mz-secondary-content download>
                                     {{AgreementsCFO | date: 'MMM, dd yyyy'}}
                                     <i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>
                        <mz-collection-item >
                            Municipal Manager
                                 <a href="/assets/StrategicDocs/performance/202021/Municipal Manager-compressed.pdf" mz-secondary-content download>
                                     {{ Agreements202021| date: 'MMM, dd yyyy'}}
                                     <i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>
                        <mz-collection-item >
                            Director Financial Services
                                 <a href="/assets/StrategicDocs/performance/202021/PA CFO 2020-21_2-compressed.pdf" mz-secondary-content download>
                                     {{ AgreementsCFO | date: 'MMM, dd yyyy'}}
                                     <i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>
                    </mz-collection>
                </mz-collapsible-item-body>
            </mz-collapsible-item>
            <!-- End of 2020-21 Performance Collapsible & Collection -->

            <mz-collapsible-item [active]="true">
                <mz-collapsible-item-header>
                         <i class="material-icons">keyboard_arrow_down</i>
                            Matzikama Municipality 2019-20 Performance Agreements
                </mz-collapsible-item-header>
                <mz-collapsible-item-body>
                    <mz-collection>
                        <mz-collection-item >
                           Director Community Development Services
                                <a href="/assets/StrategicDocs/performance/PA CDS 2019-20 Signed_compressed.pdf" mz-secondary-content download>
                                    {{websiteLiveDate | date: 'MMM, dd yyyy'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>
                        <mz-collection-item >
                            Director Corporate Services
                                 <a href="/assets/StrategicDocs/performance/PA Dir Corp Services 2019-20 Signed_compressed.pdf" mz-secondary-content download>
                                     {{websiteLiveDate | date: 'MMM, dd yyyy'}}
                                     <i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>
                        <mz-collection-item >
                            Director Infrastructure Services
                                 <a href="/assets/StrategicDocs/performance/PA Dir Infrastructure Services 2019-20 Signed_compressed.pdf" mz-secondary-content download>
                                     {{websiteLiveDate | date: 'MMM, dd yyyy'}}
                                     <i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>
                        <mz-collection-item >
                            Municipal Manager
                                 <a href="/assets/StrategicDocs/performance/PA Municipal Manager 2019-20 Signed_compressed.pdf" mz-secondary-content download>
                                     {{websiteLiveDate | date: 'MMM, dd yyyy'}}
                                     <i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>
                    </mz-collection>
                </mz-collapsible-item-body>
            </mz-collapsible-item>
            <!-- End of Performance Collapsible & Collection -->
        </mz-collapsible>
    </div>
</div>
</div>

