import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  websiteLiveDate :Date = new Date('2020-01-16')
  title = 'matzikama';
}
