import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-notices',
  templateUrl: './notices.component.html',
  styleUrls: ['./notices.component.css']
})
export class NoticesComponent implements OnInit {

  k13UploadDate : Date = new Date('2020-02-27 15:48:09');
  k14UploadDate : Date = new Date('2020-02-27 15:56:43');
  k51UploadDate : Date = new Date('2020-10-15 11:09');
  ror2021UploadDate : Date = new Date('2021-02-02 20:59');
  zoningBylaw2023 = new Date("2023-03-17 12:49");



  constructor() { }

  ngOnInit() {
  }

}
