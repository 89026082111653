<app-townplanning-nav></app-townplanning-nav>
<h5 class="center">Scheme Regulations</h5>
<div class="container">
         <!-- First Card -->
<!--     <div class="row">
        <div class="col s12 m12 l3">
            <div class="card small">
                <div class="card-image">
                    <img class="materialboxed" src="assets/townplanning/spatialPlans/Bitterfontein.JPG">
                        <span class=" black-text card-title center">Bitterfontein</span>
                </div>
                <div class="card-content">
                        <p>Click to enlarge Bitterfontein Zoning Scheme image.</p>
                </div>
                <div class="card-action">
                    <a href="assets/townplanning/spatialPlans/Bitterfontein.JPG" download>Download</a>
                </div>
            </div>
        </div> -->
           <!-- Second Card -->
<!--         <div class="col s12 m12 l3">
            <div class="card small">
                <div class="card-image">
                    <img class="materialboxed" src="assets/townplanning/spatialPlans/Doringbaai.JPG">
                        <span class=" black-text card-title center">Doringbaai</span>
                </div>
                <div class="card-content">
                        <p>Click to enlarge Doringbaai Zoning Scheme image.</p>
                </div>
                <div class="card-action">
                  <a href="assets/townplanning/spatialPlans/Doringbaai.JPG" download>Download</a>
                </div>
            </div>
        </div> -->
              <!-- Third Card -->
<!--         <div class="col s12 m12 l3">
            <div class="card small">
                <div class="card-image">
                    <img class="materialboxed" src="assets/townplanning/spatialPlans/Ebenhaeser.JPG">
                        <span class=" black-text card-title center">Ebenhaeser</span>
                </div>
                <div class="card-content">
                        <p>Click to enlarge Ebenhaeser Zoning Scheme image.</p>
                </div>
                <div class="card-action">
                    <a href="assets/townplanning/spatialPlans/Ebenhaeser.JPG" download>Download</a>
                </div>
            </div>
        </div> -->
             <!-- Fourth Card -->
<!--         <div class="col s12 m12 l3">
            <div class="card small">
                <div class="card-image">
                    <img class="materialboxed" src="assets/townplanning/spatialPlans/Klawer.JPG">
                        <span class=" black-text card-title center">Klawer</span>
                </div>
                <div class="card-content">
                        <p>Click to enlarge Klawer Zoning Scheme image.</p>
                </div>
                <div class="card-action">
                    <a href="assets/townplanning/spatialPlans/Klawer.JPG" download>Download</a>
                </div>
            </div>
        </div>
    </div> -->
            <!-- Fifth Card -->
<!--     <div class="row">
        <div class="col s12 m12 l3">
            <div class="card small">
                <div class="card-image">
                    <img class="materialboxed" src="assets/townplanning/spatialPlans/Kliprand.JPG">
                        <span class=" black-text card-title center">Kliprand</span>
                </div>
                <div class="card-content">
                        <p>Click to enlarge Kliprand Zoning Scheme image.</p>
                </div>
                <div class="card-action">
                    <a href="assets/townplanning/spatialPlans/Kliprand.JPG" download>Download</a>
                </div>
            </div>
        </div>  -->
            <!-- Sixth Card -->
<!--         <div class="col s12 m12 l3">
            <div class="card small">
                <div class="card-image">
                    <img class="materialboxed" src="assets/townplanning/spatialPlans/Koekenaap.JPG">
                        <span class=" black-text card-title center">Koekenaap</span>
                </div>
                <div class="card-content">
                        <p>Click to enlarge Koekenaap Zoning Scheme image.</p>
                </div>
                <div class="card-action">
                    <a href="assets/townplanning/spatialPlans/Koekenaap.JPG" download>Download</a>
                </div>
            </div>
        </div> -->
        <!-- Seventh Card -->
<!--         <div class="col s12 m12 l3">
            <div class="card small">
                <div class="card-image">
                    <img class="materialboxed" src="assets/townplanning/spatialPlans/Lutzville.JPG">
                        <span class=" black-text card-title center">Lutzville</span>
                </div>
                <div class="card-content">
                        <p>Click to enlarge Lutzville Zoning Scheme image.</p>
                </div>
                <div class="card-action">
                    <a href="assets/townplanning/spatialPlans/Lutzville.JPG" download>Download</a>
                </div>
            </div>
        </div>   -->
        <!-- Seventh Card -->
    <!--     <div class="col s12 m12 l3">
            <div class="card small">
                <div class="card-image">
                    <img class="materialboxed" src="assets/townplanning/spatialPlans/Lutzville-Wes.JPG">
                        <span class=" black-text card-title center">Lutzville-Wes</span>
                </div>
                <div class="card-content">
                        <p>Click to enlarge Lutzville-Wes Zoning Scheme image.</p>
                </div>
                <div class="card-action">
                    <a href="assets/townplanning/spatialPlans/Lutzville-Wes.JPG" download>Download</a>
                </div>
            </div>
        </div>      
    </div> -->
        <!-- Eight Card -->
<!--     <div class="row">
        <div class="col s12 m12 l3">
            <div class="card small">
                <div class="card-image">
                    <img class="materialboxed" src="assets/townplanning/spatialPlans/Nuwerus.JPG">
                        <span class=" black-text card-title center">Nuwerus</span>
                </div>
                <div class="card-content">
                        <p>Click to enlarge Nuwerus Zoning Scheme image.</p>
                </div>
                <div class="card-action">
                    <a href="assets/townplanning/spatialPlans/Nuwerus.JPG" download>Download</a>
                </div>
            </div>
        </div>  -->
        <!-- Ninth Card -->
<!--         <div class="col s12 m12 l3">
            <div class="card small">
                <div class="card-image">
                    <img class="materialboxed" src="assets/townplanning/spatialPlans/Papendorp.JPG">
                        <span class=" black-text card-title center">Papendorp</span>
                </div>
                <div class="card-content">
                        <p>Click to enlarge Papendorp Zoning Scheme image.</p>
                </div>
                <div class="card-action">
                    <a href="assets/townplanning/spatialPlans/Papendorp.JPG" download>Download</a>
                </div>
            </div>
        </div> -->
    <!-- Tenth Card -->
<!--         <div class="col s12 m12 l3">
            <div class="card small">
                <div class="card-image">
                    <img class="materialboxed" src="assets/townplanning/spatialPlans/Rietpoort.JPG">
                        <span class=" black-text card-title center">Rietpoort</span>
                </div>
                <div class="card-content">
                        <p>Click to enlarge Rietpoort Zoning Scheme image.</p>
                </div>
                <div class="card-action">
                    <a href="assets/townplanning/spatialPlans/Rietpoort.JPG" download>Download</a>
                </div>
            </div>
        </div>  --> 
    <!-- Eleventh Card -->
<!--         <div class="col s12 m12 l3">
            <div class="card small">
                <div class="card-image">
                    <img class="materialboxed" src="assets/townplanning/spatialPlans/Strandfontein.JPG">
                        <span class=" black-text card-title center">Strandfontein</span>
                </div>
                <div class="card-content">
                        <p>Click to enlarge Strandfontein Zoning Scheme image.</p>
                </div>
                <div class="card-action">
                    <a href="assets/townplanning/spatialPlans/Strandfontein.JPG" download>Download</a>
                </div>
            </div>
        </div>      
    </div> -->
        <!-- Twelth Card -->
<!--     <div class="row">
        <div class="col s12 m12 l3">
            <div class="card small">
                <div class="card-image">
                    <img class="materialboxed" src="assets/townplanning/spatialPlans/Vanrhynsdorp.JPG">
                        <span class=" black-text card-title center">Vanrhynsdorp</span>
                </div>
                <div class="card-content">
                        <p>Click to enlarge Vanrhynsdorp Zoning Scheme image.</p>
                </div>
                <div class="card-action">
                    <a href="assets/townplanning/spatialPlans/Vanrhynsdorp.JPG" download>Download</a>
                </div>
            </div>
        </div>  -->
        <!-- Thirteenth Card -->
      <!--   <div class="col s12 m12 l3">
            <div class="card small">
                <div class="card-image">
                    <img class="materialboxed" src="assets/townplanning/spatialPlans/Vredendal-Noord.JPG">
                        <span class=" black-text card-title center">Vredendal-Noord</span>
                </div>
                <div class="card-content">
                        <p>Click to enlarge Vredendal-Noord Zoning Scheme image.</p>
                </div>
                <div class="card-action">
                    <a href="assets/townplanning/spatialPlans/Vredendal-Noord.JPG" download>Download</a>
                </div>
            </div>
        </div> -->
        <!-- Fourteenth Card -->
  <!--       <div class="col s12 m12 l3">
            <div class="card small">
                <div class="card-image">
                    <img class="materialboxed" src="assets/townplanning/spatialPlans/Vredendal-Suid.JPG">
                        <span class=" black-text card-title center">Vredendal-Suid</span>
                </div>
                <div class="card-content">
                        <p>Click to enlarge Vredendal-Suid Zoning Scheme image.</p>
                </div>
                <div class="card-action">
                    <a href="assets/townplanning/spatialPlans/Vredendal-Suid.JPG" download>Download</a>
                </div>
            </div>
        </div>       
    </div> -->


    <!-- TownPlanning Scheme Regulations start here inside container -->
    <div class="row">
        <div class="cols12 m12 l12">
            <mz-collapsible [mode]="'expandable'" [popout]="true">
                <mz-collapsible-item [active]="true">
                    <mz-collapsible-item-header>
                        <i class="material-icons">keyboard_arrow_down</i>
                        Scheme Regulations
                    </mz-collapsible-item-header>
                    <mz-collapsible-item-body>
                        <mz-collection>
                            <mz-collection-item>
                                CONCEPT AMENDED DRAFT ZONING SCHEME BY-LAW MATZIKAMA MUNICIPAL
                                <a href="/assets/townplanning/spatialPlans/Matzikama Integrated Zoning Scheme V 19 March 2023.pdf" mz-secondary-content download>
                                   {{zoningBylaw   |date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download right" download>cloud_download</i>
                                </a> 
                            </mz-collection-item>
                            <mz-collection-item>
                                Concept Zoning maps 2022 :  Integrated Zoning Scheme 
                                   <a href="https://drdlr1.maps.arcgis.com/apps/mapviewer/index.html?webmap=764b5de69091457b9b4bd62b18638675" mz-secondary-content target="_blank">View</a>
                             
                            </mz-collection-item>
                            <mz-collection-item>
                                Vredendal skemaregulasies 1993
                                <a href="/assets/townplanning/spatialPlans/Vredendal skemaregulasies 1993.pdf" mz-secondary-content download>
                                   {{Vredendal1993   |date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download right" download>cloud_download</i>
                                </a> 
                            </mz-collection-item>
                            <mz-collection-item>
                                ARTIKEL 8 REGULASIES
                                <a href="/assets/townplanning/spatialPlans/Art 8 Regulasies Afr.pdf" mz-secondary-content download>
                                   {{Artikel8   |date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download right" download>cloud_download</i>
                                </a> 
                            </mz-collection-item>
                        <!--     <mz-collection-item>
                                Pls 198-292 Vredendal
                                <a href="/assets/townplanning/applications/Pls 198-292 Vredendal.pdf" mz-secondary-content download>
                                   {{Pls198_292UploadDate  |date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download right" download>cloud_download</i>
                                </a> 
                            </mz-collection-item>
                            <mz-collection-item>
                                Erf 349 Vredendal
                                <a href="/assets/townplanning/applications/Erf 349 Vredendal.pdf" mz-secondary-content download>
                                   {{erf349UploadDate |date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download right" download>cloud_download</i>
                                </a> 
                            </mz-collection-item> -->
                        </mz-collection>
                    </mz-collapsible-item-body>
                </mz-collapsible-item>
            </mz-collapsible>
        </div>
    </div>
</div> 

