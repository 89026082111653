import { Component, OnInit } from '@angular/core';
import { PublicMeetingsModule } from 'src/app/Modules/public-participation/public-participation.module';
import { PublicParticipationService } from 'src/app/Modules/public-participation/public-participation.service';
declare let $ : any;

let apiLoaded = false;





@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  idpNotice:Date=new Date("2020-10-14");
  publicMeeting:PublicMeetingsModule[];

  constructor( private readonly _publicMeetingsService:PublicParticipationService) {
      this.publicMeeting = this._publicMeetingsService.getAllPublicMeetings();
  }

  ngOnInit() {
    $(document).ready(function(){
      $('.materialboxed').materialbox();

      if(!apiLoaded){
        const tag = document.createElement('script');
        tag.src = "https://www.youtube.com/iframe_api";
        document.body.appendChild(tag);
      }


    });
  }

}
