<div class="container">
  <div class="row">
    <div class="col s12 m12 l12">
      <mz-collapsible
      [mode]="'expandable'"
      [popout]="true">
        <mz-collapsible-item [active]="true">
          <mz-collapsible-item-header>
                  <i class="material-icons">keyboard_arrow_down</i>
                  MATZI-NUUS 
          </mz-collapsible-item-header>
          <mz-collapsible-item-body>
              <mz-collection>
                <mz-collection-item >
                  ISSUE 45 DECEMBER 2022
                  <a href="/assets/publications/matzi nuus - uitgawe 45_desember2022.pdf" mz-secondary-content download>
                      {{matzinuus45 | date : 'MMM, dd yyyy, HH:mm:aaa'}}
                      <i class=" material-icons download rigth" download>cloud_download</i>
                  </a>
                </mz-collection-item>
              </mz-collection>
          </mz-collapsible-item-body>
        </mz-collapsible-item>
      </mz-collapsible>
    </div>
  </div>
</div>

