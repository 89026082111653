import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-decisions',
  templateUrl: './decisions.component.html',
  styleUrls: ['./decisions.component.css']
})
export class DecisionsComponent implements OnInit {



  constructor() { }

  ngOnInit() {
  }

}
