

  <nav class="#01579b light-blue darken-4" role="navigation">
    <div class="nav-wrapper container">
      <a id="logo-container" href="#" class="brand-logo">
        <img src="/assets/wc011-logo.png" width="40" height="30" id="wc011-logo" alt="Matzikama Municipality Logo" > <span class="hide-on-med-and-down">Matzikama Municipality</span> 
      </a>
      <ul class="right hide-on-med-and-down">
        <li><a [routerLink]="['/vacancies']" routerLinkActive="router-link-active">Recruitment</a></li>
        <li><a [routerLink]="['/procurement']" routerLinkActive="router-link-active">Procurement</a></li>
        <li><a [routerLink]="['/leadership']" routerLinkActive="router-link-active">Leadership</a></li>
        <li><a [routerLink]="['/documents']" routerLinkActive="router-link-active" >Documents</a></li>
        <li><a [routerLink]="['/gallery']" routerLinkActive="router-link-active" >Gallery</a></li>
        <li><a [routerLink]="['/contact']" routerLinkActive="router-link-active" >Contact Us</a></li>
      </ul>
      <ul id="slide-out" class="sidenav">
          <li><a [routerLink]="['/home']" class="sidenav-close" routerLinkActive="router-link-active"><i class="material-icons">home</i>Home</a></li>
          <li><div class="divider"></div></li>
          <!-- <li><a [routerLink]="['/covid19']" class="sidenav-close" routerLinkActive="router-link-active"><i class="material-icons">bug_report</i>Covid-19</a></li> -->
          <li><div class="divider"></div></li>
          <ul class="collapsible">
            <li>
              <a class="collapsible-header"><i class="material-icons">keyboard_arrow_down</i>Leadership</a>
              <div class="collapsible-body">
                <li> <a [routerLink]="['/mayor']" routerLinkActive="router-link-active" class="sidenav-close">Office of the Mayor</a></li>
                <li> <a [routerLink]="['/deputy']" routerLinkActive="router-link-active" class="sidenav-close">Deputy Mayor</a></li>
                <li> <a [routerLink]="['/speaker']" routerLinkActive="router-link-active" class="sidenav-close">Office of the Speaker</a></li>
                <li> <a [routerLink]="['/mayco']" routerLinkActive="router-link-active" class="sidenav-close">Mayoral Committee</a></li>
                <li> <a [routerLink]="['/councillors']" routerLinkActive="router-link-active" class="sidenav-close">Councillors</a></li>
                <li> <a [routerLink]="['/mm']" routerLinkActive="router-link-active" class="sidenav-close">Municipal Manager</a></li>
                <li> <a [routerLink]="['/cfo']" routerLinkActive="router-link-active" class="sidenav-close">Financial Services</a></li>
                <li> <a [routerLink]="['/corporate']" routerLinkActive="router-link-active" class="sidenav-close">Corporate Services</a></li>
                <li> <a [routerLink]="['/community']" routerLinkActive="router-link-active" class="sidenav-close">Community Services</a></li>
                <li> <a [routerLink]="['/infrastructure']" routerLinkActive="router-link-active" class="sidenav-close">Infrastructure Services</a></li>
              </div>
            </li>
          </ul>
          <li><div class="divider"></div></li>
          <li><a [routerLink]="['/documents']" class="sidenav-close" routerLinkActive="router-link-active"><i class="material-icons">folder</i>Documents</a></li>
          <li><div class="divider"></div></li>
          <li><a [routerLink]="['/vacancies']" class="sidenav-close" routerLinkActive="router-link-active"><i class="material-icons"> supervised_user_circle</i>Recruitment</a></li>
          <li><div class="divider"></div></li>
          <li><a [routerLink]="['/procurement']" class="sidenav-close" routerLinkActive="router-link-active"><i class="material-icons">connect_without_contact</i>Procurement</a></li>
          <li><div class="divider"></div></li>         
          <li><a [routerLink]="['/gallery']" class="sidenav-close" routerLinkActive="router-link-active"><i class="material-icons">photo_library</i>Gallery</a></li>
          <li><div class="divider"></div></li>
          <li><a [routerLink]="['/contact']" class="sidenav-close" routerLinkActive="router-link-active"><i class="material-icons">email</i>Contact Us</a></li>
          <li><div class="divider"></div></li>
      </ul>
      <a href="#" data-target="slide-out" class="sidenav-trigger"><i class="material-icons">menu</i></a>
    </div>
  </nav>
