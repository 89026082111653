<h4 class="center">Government Tenders for Municipal Area</h4>
<div class="container">
  <div class="row">
    <div class="col s12 m12 l12">
      <mz-collapsible
            [mode]="'expandable'"
            [popout]="true">

      <mz-collapsible-item [active]="true">
        <mz-collapsible-item-header>
                 <i class="material-icons">keyboard_arrow_down</i>
                 DEPARTMENT OF AGRICULTURE, LAND REFORM AND RURAL DEVELOPMENT WESTERN CAPE
        </mz-collapsible-item-header>
        <mz-collapsible-item-body>
            <mz-collection>
              <mz-collection-item class="truncate">
                ADVERT: APPOINTMENT OF A CONTRACTOR FOR DRILLING & EQUIPPING OF ONE BOREHOLE IN VREDENDAL NORTH
                <a href="/assets/Tenders/govt-tenders/SSC WC Q04 CIDB.pdf" mz-secondary-content download>
                    {{sscwcq03 | date : 'MMM, dd yyyy, HH:mm:aaa'}}
                    <i class=" material-icons download rigth" download>cloud_download</i>
                </a>
              </mz-collection-item>
              <mz-collection-item class="truncate">
                TENDER DOCUMENT: APPOINTMENT OF A CONTRACTOR FOR DRILLING & EQUIPPING OF ONE BOREHOLE IN VREDENDAL NORTH
                <a href="/assets/Tenders/govt-tenders/TENDER DOCUMENT  SSC WC Q03 (2023-2024) DALRRD- BOREHOLE VREDENDAL NORTH.pdf" mz-secondary-content download>
                    {{sscwcq03 | date : 'MMM, dd yyyy, HH:mm:aaa'}}
                    <i class=" material-icons download rigth" download>cloud_download</i>
                </a>
              </mz-collection-item>
              <mz-collection-item class="truncate">
                ADVERT: APPOINTMENT OF A CONTRACTOR FOR DRILLING & EQUIPPING OF ONE BOREHOLE IN VANRHYSDORP
                <a href="/assets/Tenders/govt-tenders/SSC WC Q03 CIDB.pdf" mz-secondary-content download>
                    {{sscwcq04 | date : 'MMM, dd yyyy, HH:mm:aaa'}}
                    <i class=" material-icons download rigth" download>cloud_download</i>
                </a>
              </mz-collection-item>
              <mz-collection-item class="truncate">
                TENDER DOCUMENT: APPOINTMENT OF A CONTRACTOR FOR DRILLING & EQUIPPING OF ONE BOREHOLE IN VANRHYSDORP
                <a href="/assets/Tenders/govt-tenders/TENDER DOCUMENT SSC WC Q04(2023-2024) DALRRD- VANRHYNSDORP.pdf" mz-secondary-content download>
                    {{sscwcq04 | date : 'MMM, dd yyyy, HH:mm:aaa'}}
                    <i class=" material-icons download rigth" download>cloud_download</i>
                </a>
              </mz-collection-item>
            </mz-collection>
        </mz-collapsible-item-body>
      </mz-collapsible-item>
    </mz-collapsible>
    </div>
  </div>
</div>






