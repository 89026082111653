import { Component, OnInit } from '@angular/core';
import { TenderService } from 'src/app/Modules/tenders/tender-service';
import { TendersModule } from 'src/app/Modules/tenders/tenders.module';

@Component({
  selector: 'app-tenders-cancelled',
  templateUrl: './tenders-cancelled.component.html',
  styleUrls: ['./tenders-cancelled.component.css']
})
export class TendersCancelledComponent implements OnInit {
  cancelledTenders:TendersModule[];
  constructor(private tenderService:TenderService) { 
    this.cancelledTenders = this.tenderService.getAllTenders();
  }

  ngOnInit() {
  }

}
