
   <div class="container hide-on-med-and-down">
    <div class="row">
        <div class="col s3 mayor">
           <app-lead-side-bar></app-lead-side-bar>
        </div>
        <div class="col s9">
           <h4 class="center">Office of the Deputy Mayor</h4>
           <hr>
           <div class="col s12">
                <div class="col s5">
                     <img src="/assets/Council/Matz Mun_028 w.jpg"  alt="Matzikama Deputy Mayor" class="responsive-img">
                     <span class="flow-text center">Deputy Mayor Mrs. A. Job</span>
                </div>
                <div class="col s7">
                    <span class="black-text">
                        <p class="leadtext right" align="justify">
                                Deputy Mayors are provided for in section 48 of the Structures Act.
                                In the collective executive system, deputy mayors are appointed by council only if the MEC for local government
                                in the province approves that a deputy mayor be appointed. The deputy mayor is a member of the executive committee of
                                the municipal council and is elected when the executive committee is elected. The deputy mayor exercises the powers and performs
                                the duties of the mayor if the mayor is absent or not available or if the office of the mayor is vacant. The mayor may delegate
                                duties to the deputy mayor.
                        </p>
                        <p>
                                <b>Line of Authority</b>
                                <li>The Deputy Mayor reports to the Council.</li>
                                <b>Interaction with the municipal manager</b> <br>
                                <li>The Deputy Mayor interacts directly with the municipal manager.</li>
                                <b>Interaction with staff members</b>
                                <li>The Deputy Mayor interacts with staff members in consultation with the municipal manager.</li>
                                <b>Dispute resolution</b>
                                <li>In the event of a dispute between the Deputy Mayor and the Speaker or the Municipal Manager, such dispute shall be referred to Council.</li>
                        </p>
                    </span>
                </div>
            </div>
        </div>
    </div>

</div>

<div class="container hide-on-large-only">
    <div class="row">
        <h4 class="center">Deputy Mayor</h4>
    </div>
    <div class="row">
      <div class="col s12 m12 l4">
        <img src="/assets/Council/Matz Mun_028 w.jpg"  alt="Matzikama Deputy Mayor" class="responsive-img">
        <span class="flow-text center">Deputy Mayor Mrs. A. Job</span>
      </div>
      <div class="col s12 m12 l4">
        <span class="black-text">
            <p class="leadtext right" align="justify">
                    Deputy Mayors are provided for in section 48 of the Structures Act.
                    In the collective executive system, deputy mayors are appointed by council only if the MEC for local government
                    in the province approves that a deputy mayor be appointed. The deputy mayor is a member of the executive committee of
                    the municipal council and is elected when the executive committee is elected. The deputy mayor exercises the powers and performs
                    the duties of the mayor if the mayor is absent or not available or if the office of the mayor is vacant. The mayor may delegate
                    duties to the deputy mayor.
            </p>
            <p>
                    <b>Line of Authority</b>
                    <li>The Deputy Mayor reports to the Council.</li>
                    <b>Interaction with the municipal manager</b> <br>
                    <li>The Deputy Mayor interacts directly with the municipal manager.</li>
                    <b>Interaction with staff members</b>
                    <li>The Deputy Mayor interacts with staff members in consultation with the municipal manager.</li>
                    <b>Dispute resolution</b>
                    <li>In the event of a dispute between the Deputy Mayor and the Speaker or the Municipal Manager, such dispute shall be referred to Council.</li>
            </p>
        </span>
      </div>
    </div>
</div>

<app-footer></app-footer>
