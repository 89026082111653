import { Component, OnInit} from '@angular/core';
import { Ng2SearchPipeModule} from 'ng2-search-filter';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.css']
})
export class DocumentsComponent implements OnInit {

  
  searchedTerm;
  constructor() { }

  ngOnInit() {
  }

}
