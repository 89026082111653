
 <div class="container">
        <h4 class="center">Municipal Tenders</h4>
    <div class="row">
        <div class="col s12 m12 l4">
            <div class="card #01579b light-blue darken-4">
                <div class="card-content white-text">
                      <span class="card-title center-align">Open Tenders</span>
                      <p class="center-align">View all tenders that is available here.</p>
                </div>
                <div class="card-action center-align">
                    <a [routerLink]="['/tenders-open']" routerLinkActive="router-link-active" >View</a>    
                </div>
            </div>
        </div>
        <div class="col s4">
            <div class="card #01579b light-blue darken-4">
                <div class="card-content white-text">
                      <span class="card-title center-align">Closed Tenders</span>
                      <p class="center-align">Closed & Not Awarded Tenders.</p>
                </div>
                <div class="card-action center-align">
                    <a [routerLink]="['/tenders-in-progress']" routerLinkActive="router-link-active" >View</a>    
                </div>
            </div>
        </div> 
        <div class="col s12 m12 l4">
            <div class="card #01579b light-blue darken-4">
                <div class="card-content white-text">
                      <span class="card-title center-align">Closed & Awarded Tenders</span>
                      <p class="center-align">View closed and awarded tenders here.</p>
                </div>
                <div class="card-action center-align">
                    <a [routerLink]="['/tenders-awarded']" routerLinkActive="router-link-active" >View</a>    
                </div>
            </div>
        </div> 
    </div>
    <div class="row">
        <div class="col s12 m12 l4">
            <div class="card #01579b light-blue darken-4">
                <div class="card-content white-text">
                      <span class="card-title center-align">Cancelled Tenders</span>
                      <p class="center-align">View cancelled tenders here.</p>
                </div>
                <div class="card-action center-align">
                    <a [routerLink]="['/tenders-cancelled']" routerLinkActive="router-link-active" >View</a>    
                </div>
            </div>
        </div> 
        <div class="col s12 m12 l4">
            <div class="card #01579b light-blue darken-4">
                <div class="card-content white-text">
                      <span class="card-title center-align">Request For Proposals</span>
                      <p class="center-align">View request for proposals here.</p>
                </div>
                <div class="card-action center-align">
                    <a [routerLink]="['/request-for-proposal']" routerLinkActive="router-link-active" >View</a>    
                </div>
            </div>
        </div> 
        <div class="col s12 m12 l4">
            <div class="card #01579b light-blue darken-4">
                <div class="card-content white-text">
                      <span class="card-title center-align">Government Tenders</span>
                      <p class="center-align">View other tenders for area.</p>
                </div>
                <div class="card-action center-align">
                    <a [routerLink]="['/govt-tenders']" routerLinkActive="router-link-active" >View</a>    
                </div>
            </div>
        </div> 
    </div>
    <div class="row">
        <div class="col s12 m12 l4">
            <div class="card #01579b light-blue darken-4">
                <div class="card-content white-text">
                      <span class="card-title center-align">Archived Tenders</span>
                      <p class="center-align">View archived tenders here.</p>
                </div>
                <div class="card-action center-align">
                    <a [routerLink]="['/archived-tenders']" routerLinkActive="router-link-active" >View</a>    
                </div>
            </div>
        </div> 
    </div>
</div> 

