<h4 class="center">IDP SECTOR PLANS</h4>
<div class="container">
  <div class="row">
    <div class="col s12 m12 l12">
      <mz-collapsible [mode]="'expandable'" [popout]="true">
        <mz-collapsible-item [active]="true">
          <mz-collapsible-item-header>
            <i class="material-icons">keyboard_arrow_down</i>
            Final Approved IDP Sector Plans 2022-23
          </mz-collapsible-item-header>
          <mz-collapsible-item-body>
            <mz-collection>
              <mz-collection-item>
                <mz-collection-item >
                  Matzikama Municipality 2022-2023 Approved IDP & Sector Plans
                  <a href="/assets/StrategicDocs/idp/IDP May 2022 (Amendment).zip" mz-secondary-content download>
                      {{finalSectorPlansMay22UploadDate  | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                      <i class=" material-icons download rigth" download>cloud_download</i>
                  </a>
              </mz-collection-item>
              </mz-collection-item>
            </mz-collection>
          </mz-collapsible-item-body>
        </mz-collapsible-item>
      </mz-collapsible>
    </div>
  </div>
    <div class="row">
        <div class="col s12 m12 l12">
            <mz-collapsible [mode]="'expandable'" [popout]="true">
                <mz-collapsible-item [active]="true">
                    <mz-collapsible-item-header>
                        <i class="material-icons">keyboard_arrow_down</i>
                           Final Approved IDP Sector Plans 2021-22
                       </mz-collapsible-item-header>
                       <mz-collapsible-item-body>
                        <mz-collection>
                            <mz-collection-item>
                                Documents Uploaded :
                                <a href="#"mz-secondary-content>
                                    <span right>{{finalSectorPlansMarch21UploadDate  |date : 'MMM, dd yyyy, HH:mm:ss aaa'}}</span>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                Spatial Development Framework
                                <a href="/assets/sectorPlans/2021-22/Final/Spatial Development Framework.zip"mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                2020 WCDM signed Safety Plan
                                <a href="/assets/sectorPlans/2021-22/Draft/2020 WCDM signed Safety Plan.pdf"mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                AIR QUALITY MANAGEMENT PLAN MATZIKAMA MUNICIPALITY
                                <a href="/assets/sectorPlans/2021-22/Draft/Second Generation Integrated AQMP 2019-2024.pdf"mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                MATZIKAMA MUNICIPALITY COASTAL MANAGEMENT PLAN
                                <a href="/assets/sectorPlans/2021-22/Draft/Matzikama Municipality Coastal Management Programme.pdf"mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                Electrical Services Development Plan
                                <a href="/assets/sectorPlans/2021-22/Draft/Electric Plan.pdf"mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                Water Services Development Plan
                                <a href="/assets/sectorPlans/2021-22/Draft/IDP Water Sector Input Report - Matzikama.zip"mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                INFRASTRUCTURE GROWTH PLAN
                                <a href="/assets/sectorPlans/2021-22/Draft/Infrastructure growth plan Matz Mun.pdf"mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                INTEGRATED WASTE MANAGEMENT PLAN (3rd Generation)
                                <a href="/assets/sectorPlans/2021-22/Draft/Matzikama 3rd Gen IWMP - FINAL.pdf"mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                Sustainable Human Settlement Plan (HSP)
                                <a href="/assets/sectorPlans/2021-22/Draft/Sustainable Human Settlement Plan (HSP).zip"mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                West-Coast-District Integrated Transport Plan
                                <a href="/assets/sectorPlans/2021-22/Draft/West-Coast-District Integrated Transport Plan.pdf"mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                Matzikama 2018 LED Strategy 2018-2022
                                <a href="/assets/sectorPlans/2021-22/Draft/Matzikama 2018 LED Strategy 2018-2022.pdf"mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                MATZIKAMA MUNICIPALITY WORKPLACE SKILLS PLAN
                                <a href="/assets/sectorPlans/2021-22/Draft/SSPApplication_Report.pdf"mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                MATZIKAMA MUNICIPALITY DISASTER MANAGEMENT PLAN
                                <a href="/assets/sectorPlans/2021-22/Draft/MATZIKAMA MUNICIPAL FINAL  DM PLAN- MAY 2019 (Reviewed Feb 2020).pdf"mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                        </mz-collection>
                    </mz-collapsible-item-body>
                </mz-collapsible-item>
            </mz-collapsible>
        </div>
    </div>
    <div class="row">
        <div class="col s12 m12 l12">
            <mz-collapsible [mode]="'expandable'" [popout]="true">
                <mz-collapsible-item [active]="true">
                    <mz-collapsible-item-header>
                        <i class="material-icons">keyboard_arrow_down</i>
                           IDP Sector Plans Draft 2021-22
                       </mz-collapsible-item-header>
                       <mz-collapsible-item-body>
                        <mz-collection>
                            <mz-collection-item>
                                Documents Uploaded :
                                <a href="#"mz-secondary-content>
                                    <span right>{{draftSectorPlansMarch21UploadDate  |date : 'MMM, dd yyyy, HH:mm:ss aaa'}}</span>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                Spatial Development Framework
                                <a href="/assets/sectorPlans/2021-22/Draft/Spatial Development Framework.zip"mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                2020 WCDM signed Safety Plan
                                <a href="/assets/sectorPlans/2021-22/Draft/2020 WCDM signed Safety Plan.pdf"mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                AIR QUALITY MANAGEMENT PLAN MATZIKAMA MUNICIPALITY
                                <a href="/assets/sectorPlans/2021-22/Draft/Second Generation Integrated AQMP 2019-2024.pdf"mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                MATZIKAMA MUNICIPALITY COASTAL MANAGEMENT PLAN
                                <a href="/assets/sectorPlans/2021-22/Draft/Matzikama Municipality Coastal Management Programme.pdf"mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                Electrical Services Development Plan
                                <a href="/assets/sectorPlans/2021-22/Draft/Electric Plan.pdf"mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                Water Services Development Plan
                                <a href="/assets/sectorPlans/2021-22/Draft/IDP Water Sector Input Report - Matzikama.zip"mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                INFRASTRUCTURE GROWTH PLAN
                                <a href="/assets/sectorPlans/2021-22/Draft/Infrastructure growth plan Matz Mun.pdf"mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                INTEGRATED WASTE MANAGEMENT PLAN (3rd Generation)
                                <a href="/assets/sectorPlans/2021-22/Draft/Matzikama 3rd Gen IWMP - FINAL.pdf"mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                Sustainable Human Settlement Plan (HSP)
                                <a href="/assets/sectorPlans/2021-22/Draft/Sustainable Human Settlement Plan (HSP).zip"mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                West-Coast-District Integrated Transport Plan
                                <a href="/assets/sectorPlans/2021-22/Draft/West-Coast-District Integrated Transport Plan.pdf"mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                Matzikama 2018 LED Strategy 2018-2022
                                <a href="/assets/sectorPlans/2021-22/Draft/Matzikama 2018 LED Strategy 2018-2022.pdf"mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                MATZIKAMA MUNICIPALITY WORKPLACE SKILLS PLAN
                                <a href="/assets/sectorPlans/2021-22/Draft/SSPApplication_Report.pdf"mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                MATZIKAMA MUNICIPALITY DISASTER MANAGEMENT PLAN
                                <a href="/assets/sectorPlans/2021-22/Draft/MATZIKAMA MUNICIPAL FINAL  DM PLAN- MAY 2019 (Reviewed Feb 2020).pdf"mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                        </mz-collection>
                    </mz-collapsible-item-body>
                </mz-collapsible-item>
            </mz-collapsible>
        </div>
    </div>
    <div class="row">
        <div class="col s12 m12 l12">
            <mz-collapsible [mode]="'expandable'" [popout]="true">
                <mz-collapsible-item [active]="true">
                    <mz-collapsible-item-header>
                        <i class="material-icons">keyboard_arrow_down</i>
                           IDP Sector Plans Final 2020-21
                       </mz-collapsible-item-header>
                       <mz-collapsible-item-body>
                        <mz-collection>
                            <mz-collection-item>
                                Documents Uploaded :
                                <a href="#"mz-secondary-content>
                                    <span right>{{finalSectorPlansUploadDate  |date : 'MMM, dd yyyy, HH:mm:ss aaa'}}</span>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                Spatial Development Framework
                                <a href="/assets/sectorPlans/2020-21/final/2019 SDF.zip"mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                2020 WCDM signed Safety Plan
                                <a href="/assets/sectorPlans/2020-21/final/2020 WCDM signed Safety Plan.pdf"mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                AIR QUALITY MANAGEMENT PLAN MATZIKAMA MUNICIPALITY
                                <a href="/assets/sectorPlans/2020-21/final/AIR QUALITY MANAGEMENT PLAN MATZIKAMA MUN.PDF"mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                MATZIKAMA MUNICIPALITY COASTAL MANAGEMENT PLAN
                                <a href="/assets/sectorPlans/2020-21/final/COASTAL MANAGEMENT PLAN_Matzikama_10April2013.pdf"mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                Electrical Services Development Plan
                                <a href="/assets/sectorPlans/2020-21/final/Electric Plan.pdf"mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                Water Services Development Plan
                                <a href="/assets/sectorPlans/2020-21/final/Water Services Development Plan.zip"mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                INFRASTRUCTURE GROWTH PLAN
                                <a href="/assets/sectorPlans/2020-21/final/Infrastructure growth plan Matz Mun.pdf"mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                INTEGRATED WASTE MANAGEMENT PLAN (3rd Generation)
                                <a href="/assets/sectorPlans/2020-21/final/Matzikama 3rd Gen IWMP - FINAL.pdf"mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                Sustainable Human Settlement Plan (HSP)
                                <a href="/assets/sectorPlans/2020-21/final/Sustainable Human Settlement Plan (HSP).zip"mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                West-Coast-District Integrated Transport Plan
                                <a href="/assets/sectorPlans/2020-21/final/West-Coast-District Integrated Transport Plan.pdf"mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                Matzikama 2018 LED Strategy 2018-2022
                                <a href="/assets/sectorPlans/2020-21/final/Matzikama 2018 LED Strategy 2018-2022.pdf"mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                MATZIKAMA MUNICIPALITY WORKPLACE SKILLS PLAN
                                <a href="/assets/sectorPlans/2020-21/final/WSP 2019-2020.pdf"mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                MATZIKAMA MUNICIPALITY DISASTER MANAGEMENT PLAN
                                <a href="/assets/sectorPlans/2020-21/final/MATZIKAMA MUNICIPAL FINAL  DM PLAN- MAY 2019 (Reviewed Feb 2020).pdf"mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                        </mz-collection>
                    </mz-collapsible-item-body>
                </mz-collapsible-item>
            </mz-collapsible>
        </div>
    </div>
    <div class="row">
        <div class="col s12 m12 l12">
            <mz-collapsible  [mode]="'expandable'" [popout]="true">
                <mz-collapsible-item [active] = "true">
                   <mz-collapsible-item-header>
                    <i class="material-icons">keyboard_arrow_down</i>
                       IDP Draft Sector Plans 2020-21
                   </mz-collapsible-item-header>
                    <mz-collapsible-item-body>
                        <mz-collection>
                            <mz-collection-item>
                                Documents Uploaded :
                                <a href="#"mz-secondary-content>
                                    <span right>{{sectorPlansUploadDate |date : 'MMM, dd yyyy, HH:mm:ss aaa'}}</span>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                Spatial Development Framework 2019
                                <a href="/assets/sectorPlans/2020-21/2019 SDF.zip"mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                2020 WCDM signed Safety Plan
                                <a href="/assets/sectorPlans/2020-21/2020 WCDM signed Safety Plan.pdf"mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                AIR QUALITY MANAGEMENT PLAN MATZIKAMA MUNICIPALITY
                                <a href="/assets/sectorPlans/2020-21/AIR QUALITY MANAGEMENT PLAN MATZIKAMA MUN (3).pdf"mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                MATZIKAMA MUNICIPALITY COASTAL MANAGEMENT PLAN
                                <a href="/assets/sectorPlans/2020-21/COASTAL MANAGEMENT PLAN_Matzikama_10April2013.pdf"mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                Electrical Services Development Plan
                                <a href="/assets/sectorPlans/2020-21/Electric Plan.pdf"mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                Matzikama Municipality 2020-21 Draft SDBIP
                                <a href="/assets/sectorPlans/2020-21/Matzikama Municipality 2020-21 Final Draft SDBIP.pdf"mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                Water Services Development Plan
                                <a href="/assets/sectorPlans/2020-21/Water Services Development Plan.zip"mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                INFRASTRUCTURE GROWTH PLAN
                                <a href="/assets/sectorPlans/2020-21/Infrastructure growth plan Matz Mun.pdf"mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                INTEGRATED WASTE MANAGEMENT PLAN (3rd Generation)
                                <a href="/assets/sectorPlans/2020-21/Matzikama 3rd Gen IWMP - FINAL.pdf"mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                Sustainable Human Settlement Plan (HSP)
                                <a href="/assets/sectorPlans/2020-21/Sustainable Human Settlement Plan (HSP).zip"mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                West-Coast-District Integrated Transport Plan
                                <a href="/assets/sectorPlans/2020-21/West-Coast-District Integrated Transport Plan.pdf"mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                Matzikama 2018 LED Strategy 2018-2022
                                <a href="/assets/sectorPlans/2020-21/Matzikama 2018 LED Strategy 2018-2022.pdf"mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                MATZIKAMA MUNICIPAL FINAL  DM PLAN- MAY 2019 (Reviewed Feb 2020)
                                <a href="/assets/sectorPlans/2020-21/MATZIKAMA MUNICIPAL FINAL  DM PLAN- MAY 2019 (Reviewed Feb 2020).pdf"mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                IDP PROCESS PLAN
                                <a href="/assets/sectorPlans/2020-21/Process Plan Matzikama .pdf"mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                IDP TIME SCHEDULE
                                <a href="/assets/sectorPlans/2020-21/TIMESCHEDULE.pdf"mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                MATZIKAMA MUNICIPALITY WORKPLACE SKILLS PLAN 2019-2020
                                <a href="/assets/sectorPlans/2020-21/WSP 2019-2020.pdf"mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                MATZIKAMA MUNICIPALITY DISASTER MANAGEMENT FRAMEWORK 2019
                                <a href="/assets/sectorPlans/2020-21/Disaster Management Framework 2019.pdf"mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                        </mz-collection>
                    </mz-collapsible-item-body>
                </mz-collapsible-item>

            </mz-collapsible>
        </div>
    </div>
</div>
