<footer class="page-footer #01579b light-blue darken-4">
    <div class="container">
      <div class="row">
        <div class="col l4 s12">
          <h5 class="white-text">Matzikama Municipality</h5>
          <p class="grey-text text-lighten-4">

            <strong>Postal:</strong> 	PO Box 98, Vredendal, 8160 <br>
            <strong>Physical:</strong>	37 Church Street, Vredendal <br>
            <strong>Office Hours</strong> <br>
            Mon-Thurs: 8:00 - 13:00, 13:45 - 17:00 <br>
            Fri:    8:00 - 13:00, 13:30 - 15:30 <br>



          </p>
        </div>
        <div class="col l4 s12">
          <h5 class="white-text">Documents:</h5>
          <ul>
            <li><a class="grey-text text-lighten-3" [routerLink]="['/stratdocs']">IDP & Budget</a></li>
            <li><a class="grey-text text-lighten-3" [routerLink]="['/tenders']">Tenders</a></li>
            <li><a class="grey-text text-lighten-3" [routerLink]="['/vacancies']">Vacancies</a></li>
            <li><a class="grey-text text-lighten-3" [routerLink]="['/townplanning']">Spatial Development Framework</a></li>
          </ul>
        </div>
        <div class="col l4 s12">
          <!-- <h5 class="white-text">Links</h5> -->
          <ul>
            <li><a class="grey-text text-lighten-3" [routerLink]="['/covid19']">Covid 19</a></li>
            <li><a class="grey-text text-lighten-3" [routerLink]="['/procurement']">CSD</a></li>
            <li><a class="grey-text text-lighten-3" [routerLink]="['/performance']">Performance Management</a></li>
            <li><a class="grey-text text-lighten-3" [routerLink]="['/contact']">Contact Us</a></li>
          </ul>
        </div>

      </div>
    </div>
    <div class="footer-copyright">
      <div class="container">
      Designed and Developed by © 2024 Matzikama Municipality. All Rights Reserved
      <!-- <a class="grey-text text-lighten-4 right" href="#!">More Links</a> -->
      </div>
    </div>
  </footer>
<!--   <footer class="section #01579b light-blue darken-4 white-text center">

    <p class="flow-text">Designed and developed by Matzikama Municipality &copy; 2020. All Rights Reserved. </p>
  </footer> -->
