import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-appeals',
  templateUrl: './appeals.component.html',
  styleUrls: ['./appeals.component.css']
})
export class AppealsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
