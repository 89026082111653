import { Component, OnInit, AfterViewInit } from '@angular/core';


@Component({
  selector: 'app-tenders',
  templateUrl: './tenders.component.html',
  styleUrls: ['./tenders.component.css']
})
export class TendersComponent implements OnInit{



  constructor() {}
 

  ngOnInit() {}

}
