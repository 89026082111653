import { Component, OnInit, Pipe, PipeTransform } from '@angular/core';
import{SafePipe} from "./safe.pipe"
import { PublicMeetingsModule } from 'src/app/Modules/public-participation/public-participation.module';
import { PublicParticipationService } from 'src/app/Modules/public-participation/public-participation.service';




@Component({
  selector: 'app-publicparticipation',
  templateUrl: './publicparticipation.component.html',
  styleUrls: ['./publicparticipation.component.css']
})
export class PublicparticipationComponent implements OnInit {

  // openUpUrl:string = "https://matzikama-idp.openup.org.za/index.html"

  publicMeeting:PublicMeetingsModule[];

  constructor( private readonly _publicMeetingsService:PublicParticipationService) {
     
  }

  ngOnInit() {
    this.publicMeeting = this._publicMeetingsService.getAllPublicMeetings();
  }

}
