import { Component, OnInit } from '@angular/core';
import {TownplanningModule} from '../../../Modules/townplanning/townplanning.module';
// import * as M from '../../../../../../node_modules/materialize-css';
import * as M from '../../../../../node_modules/materialize-css';

@Component({
  selector: 'app-townplanning',
  templateUrl: './townplanning.component.html',
  styleUrls: ['./townplanning.component.css']
})
export class TownplanningComponent implements OnInit {

  websiteLiveDate :Date = new Date('2020-01-16');
  sdfUploadDate : Date = new Date ('2020-06-04 10:34:28');
  sdfMay2021UploadDate : Date = new Date ('2021-06-04 09:48');
  sdfMarch2023UploadDate : Date = new Date ('2023-04-06 11:05');

  townplanningNotices:TownplanningModule[];
  constructor() { 

 
    this.townplanningNotices=[

      new TownplanningModule("APPLICATION FOR DEPARTURE, CONSOLIDATION,  CONSENT USE & AMENDMENT OF RESTRICTIVE CONDITIONS",
      "Stawie Fouche FamilieTrust",
       "Downing & Engelbrecht Attorneys",
       "Erf 4338,Vredendal",
       "13 6461 ha",
       "Arbeidsgenot Plaas, Dorpstreet,Vredendal",
       "Agriculture zone I, with temporary departure for business use with regards to steelworks within existing agricultural barn ± 500m²",
       "/assets/townplanning/30 dae kennisgewingsAFRENG TEMPLATE Erwe 19-22, Klawer.pdf")
    ]
  }
  

  ngOnInit() {
    var el = document.querySelectorAll('.tabs');
    var instance = M.Tabs.init(el, {
    

    });
  
    
  }
 
}
