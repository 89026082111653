<h4 class="center">Media Statements</h4>
<div class="container">
  <div class="row">
    <div class="col s12 m8 offset-m2 l6 offset-l3">
        <div class="card-panel grey lighten-5 z-depth-1">
          <div class="row valign-wrapper">
            <div class="col s2">
              <img src="/assets/Council/Matz Mun_035 w.jpg" alt="" class="circle responsive-img"> <!-- notice the "circle" class -->
            </div>
            <div class="col s10">
                <audio controls>
                    <source src="/assets/Media Statements/Radio Namakwaland Onderhoud Gerald Seas.mp3" type="audio/mp3" width="100%">
                  Your browser does not support the audio element.
                </audio> <br>
              <span class="black-text">
                Radio Namakwaland interview with the municipal manager Gerald Seas on 28 September 2022.
              </span>
            </div>
          </div>
        </div>
      </div>
    <!-- <div class="col s12 m12 l6">
      <div class="card medium">
        <div class="card-image">
          <img src="/assets/Council/Matz Mun_035 w.jpg" class="responsive-img">
          <span class="card-title">Card Title</span>
        </div>
        <div class="card-content">
          <p>Radio Namakwaland interview with the municipal manager Gerald Seas</p>
        </div>
        <div class="card-action">
          <audio controls>
            <source src="/assets/Media Statements/Radio Namakwaland Onderhoud Gerald Seas.mp3" type="audio/mp3">
          Your browser does not support the audio element.
          </audio>
        </div>
      </div>
    </div> -->
  </div>
<div class="row">
    <div class="col s12 m12 l12">
        <mz-collapsible [mode]="'expandable'" [popout]="true">
            <mz-collapsible-item>
                <mz-collapsible-item-header >
                    <i class="material-icons">keyboard_arrow_down</i>
                    Media Statements 2020- 2021
                </mz-collapsible-item-header>
                <mz-collapsible-item-body>
                    <mz-collection>
                        <mz-collection-item >
                            REGSTELLING OP DIE MISLEIDENDE INLIGTING VAN RAADSLID KALLIE LOUW(AFR)
                                <a href="/assets/Media Statements/REGSTELLING OP DIE MISLEIDENDE INLIGTING VAN RAADSLID K LOUW VAN DIE DA (Afrikaans).pdf" mz-secondary-content download>
                                    {{officeOfSpeakerStatement2  | date: 'MMM, dd yyyy, HH:mm:ss aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>
                        <mz-collection-item >
                            CORRECTION ON THE MISLEADING INFORMATION OF COUNCILLOR KALLIE LOUW  (ENGLISH)
                                <a href="/assets/Media Statements/REGSTELLING OP DIE MISLEIDENDE INLIGTING VAN RAADSLID K LOUW VAN DIE DA (Engels).pdf" mz-secondary-content download>
                                    {{officeOfSpeakerStatement  | date: 'MMM, dd yyyy, HH:mm:ss aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>
                        <mz-collection-item >
                            CETA Skills Development Application Form
                                <a href="/assets/Media Statements/APPLICATION FORM - CEDA SKILLS DEV.pdf" mz-secondary-content download>
                                    {{CETAJune2021 | date: 'MMM, dd yyyy, HH:mm:ss aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>
                        <mz-collection-item >
                            CETA Skills Development Advert (English)
                                <a href="/assets/Media Statements/CETA SKILLS DEV ENG.pdf" mz-secondary-content download>
                                    {{CETAJune2021 | date: 'MMM, dd yyyy, HH:mm:ss aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>
                        <mz-collection-item >
                        CETA Skills Development Advert (Afrikaans)
                            <a href="/assets/Media Statements/CETA SKILLS DEV AFR.pdf" mz-secondary-content download>
                                {{CETAJune2021 | date: 'MMM, dd yyyy, HH:mm:ss aaa'}}
                                <i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>
                        <mz-collection-item >
                            Press Release 12 May 2021
                                <a href="/assets/Media Statements/Press Release_12 Mei 2021.pdf" mz-secondary-content download>
                                    {{pressRelease12May2021 | date: 'MMM, dd yyyy, HH:mm:ss aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>
                        <mz-collection-item >
                            Circular 2
                                <a href="/assets/Media Statements/OMSENDBRIEF 2.pdf" mz-secondary-content download>
                                    {{circular212May2021 | date: 'MMM, dd yyyy, HH:mm:ss aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>
                        <mz-collection-item >
                            Statement on Appointment of Acting Municipal Manager 30 March 2021
                                <a href="/assets/Media Statements/Press Release_30 Maart 2021.pdf" mz-secondary-content download>
                                    {{statement30March2021 | date: 'MMM, dd yyyy, HH:mm:ss aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>
                        <mz-collection-item >
                            Statement on DPCI (HAWKS) visit to Matzikama Municipal Offices on 15 March 2021
                                <a href="/assets/Media Statements/MEDIA STATEMENT - 15 Maart 2021.pdf" mz-secondary-content download>
                                    {{hawksMarch2021 | date: 'MMM, dd yyyy, HH:mm:ss aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>
                        <mz-collection-item >
                            Statement on Appointment of Acting Municipal Manager 01 March 2021
                                <a href="/assets/Media Statements/AANSTELLING VAN DIE WAARNEMENDE MUNISIPALE BESTUURDER - ENG.pdf" mz-secondary-content download>
                                    {{statement01March2021 | date: 'MMM, dd yyyy, HH:mm:ss aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>
                    </mz-collection>
                </mz-collapsible-item-body>
            </mz-collapsible-item>
        </mz-collapsible>
    </div>
</div>



