
   <div class="container hide-on-med-and-down">
    <div class="row">
        <div class="col s3 mayor">
           <app-lead-side-bar></app-lead-side-bar>
        </div>
        <div class="col s9">
           <h4 class="center">Infrastructure Services</h4>
           <hr>
           <div class="col s12">
                <div class="col s5">
                     <img src="/assets/Council/placeholder-md.png" alt="Director Infrastructure Services" class="responsive-img">
                     <!-- <span class="flow-text">Mr. M. Owies</span> -->
                </div>
                <div class="col s7">
                    <span class="black-text">
                        <p class="leadtext right" align="justify">
                                <b>Overview</b><br>
                               The Directorate is responsible for Electrical Services; Civil Services (Water and Sewer, Water & Wastewater Treatment and Roads,
                               Transport and Stormwater) and Mechanical Services & Fleet Management (Pump Stations, Telemetry, Desalination Plant
                               and Fleet Management).<br>
                            <b>The Director Infrastructure Services is responsible for:</b><br>
                                1. Manage the provisioning and maintenance of electro-technical services to ensure the rendering of sustainable and
                                    affordable electricity to the community<br>
                                2. Provide and maintain civil engineering services and coordinate administrative processes to the community of
                                    Vredendal, Vanrhynsdorp & Klawer<br>
                                3. Provide and maintain civil engineering services and coordinate administrative processes to the community of
                                    Lutzville, Lutzville-West, Koekenaap, Strandfontein, Doringbaai, Papendorp, Ebenhaeser, Nuwerus, Bitterfontein,
                                    Kliprand, Rietpoort, Stofkraal, Molsvlei, Putsekloof<br>
                                4. Coordinate the provisioning of project management services for the construction phase of capital / maintenance /
                                    external funded projects<br>
                                5. Provide residential, business, garden and bulk refuse removal services in accordance with a refuse removal
                                    program; manage and control landfill disposal sites<br>
                                6. Operate water treatment works and plant to ensure potable water in compliance with the license agreement as
                                    issued by the Department of Water Affairs<br>
                                7. Manage the maintenance and operation of wastewater treatment infrastructure to assure uninterrupted services<br>
                                8. Render a corporate fleet administration service to the municipality<br>
                                9. Render management and line function executive support services to the directorate<br>
                                 <b>Contact Information</b><br>
                                Tel: +27 (0) 27 201 3401<br>
                                <!-- E-mail:<a href="mailto:marko@matzikama.gov.za">marko@matzikama.gov.za</a><br> -->
                        </p>
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container hide-on-large-only">
    <div class="row">
        <h4 class="center">Infrastructure Services</h4>
    </div>
    <div class="row">
        <div class="col s12 m12 l4">
            <img src="/assets/Council/placeholder-md.png" alt="Director Infrastructure Services" class="responsive-img">
            <!-- <span class="flow-text">Mr. M. Owies</span> -->
            <span class="black-text">
                <p class="leadtext right" align="justify">
                        <b>Overview</b><br>
                       The Directorate is responsible for Electrical Services; Civil Services (Water and Sewer, Water & Wastewater Treatment and Roads,
                       Transport and Stormwater) and Mechanical Services & Fleet Management (Pump Stations, Telemetry, Desalination Plant
                       and Fleet Management).<br>
                    <b>The Director Infrastructure Services is responsible for:</b><br>
                        1. Manage the provisioning and maintenance of electro-technical services to ensure the rendering of sustainable and
                            affordable electricity to the community<br>
                        2. Provide and maintain civil engineering services and coordinate administrative processes to the community of
                            Vredendal, Vanrhynsdorp & Klawer<br>
                        3. Provide and maintain civil engineering services and coordinate administrative processes to the community of
                            Lutzville, Lutzville-West, Koekenaap, Strandfontein, Doringbaai, Papendorp, Ebenhaeser, Nuwerus, Bitterfontein,
                            Kliprand, Rietpoort, Stofkraal, Molsvlei, Putsekloof<br>
                        4. Coordinate the provisioning of project management services for the construction phase of capital / maintenance /
                            external funded projects<br>
                        5. Provide residential, business, garden and bulk refuse removal services in accordance with a refuse removal
                            program; manage and control landfill disposal sites<br>
                        6. Operate water treatment works and plant to ensure potable water in compliance with the license agreement as
                            issued by the Department of Water Affairs<br>
                        7. Manage the maintenance and operation of wastewater treatment infrastructure to assure uninterrupted services<br>
                        8. Render a corporate fleet administration service to the municipality<br>
                        9. Render management and line function executive support services to the directorate<br>
                         <b>Contact Information</b><br>
                        Tel: +27 (0) 27 201 3401<br>
                        E-mail:<a href="mailto:marko@matzikama.gov.za">marko@matzikama.gov.za</a><br>
                </p>
            </span>
        </div>
    </div>
</div>
<app-footer></app-footer>




