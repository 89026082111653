import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-govt-tenders',
  templateUrl: './govt-tenders.component.html',
  styleUrls: ['./govt-tenders.component.css']
})
export class GovtTendersComponent implements OnInit {
  sscwcq03:Date = new Date("2023-08-11 14:06");
  sscwcq04:Date = new Date("2023-08-11 14:11");

  constructor() { }

  ngOnInit() {
  }

}
