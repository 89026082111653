<!-- <div class="container">
    <div class="navIntro">
        <ul>
            <li>National Hotline: 0800 029 999</li>
            <li>Provincial Hotline: 021 928 4102</li>
            <li>WhatsApp 0600 123456</li>
        </ul>
    </div>
    <div class="nav"></div>
    <div class="coronaIntro"></div>
    <div class="coronaInfo"></div>
    <div class="coronaTwitterFeed"></div>

</div> -->
<div class="row">
    <nav role="navigation"> 
        <div class="col s12 m12 l12 covidNav">
            <div class="nav-wrapper container">
                <a [routerLink]="['/covid19']" class="brand-logo hide-on-med-and-down"><h5>Covid-19 Response</h5></a>
                <ul class="right">
                        <li><a [routerLink]="['/covid19']" class="sidenav-close" routerLinkActive="router-link-active">Home</a></li>
                        <li><a [routerLink]="['/covid-about']" class="sidenav-close" routerLinkActive="router-link-active">About</a></li>
                        <li><a href="https://coronavirus.westerncape.gov.za/frequently-asked-questions" routerLinkActive="router-link-active">FAQs</a></li>
                        <li><a [routerLink]="['/covid-contact']" class="sidenav-close" routerLinkActive="router-link-active">Contact</a></li>
                        <li><a href="https://coronavirus.westerncape.gov.za/" routerLinkActive="router-link-active">Provincial Website</a></li>
                        <li><a href="https://sacoronavirus.co.za/" routerLinkActive="router-link-active">National Website</a></li>
                </ul>
            </div>
        </div>
    </nav>
</div> 
<div class="row hotline">
    <div class="col s12 m12 l4 center">
        <span class="material-icons">phone</span>
        National Hotline: 0800 029 999 
    </div>
    <div class="col s12 m12 l4 center">
        <span class="material-icons">phone</span>
        Provincial Hotline: 021 928 4102
    </div>
    <div class="col s12 m12 l4 center">
        <img src="https://img.icons8.com/material/24/000000/whatsapp--v1.png"/>
        WhatsApp 0600 123456
    </div>
</div>

