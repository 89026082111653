<h4 class="center">Matzikama Financial Management Policies</h4>
<div class="container">
    <div class="row">
        <div class="col s12 m12 l12">
          <mz-collapsible [mode]="'expandable'" [popout]="true">
            <mz-collapsible-item [active] = "true">
              <mz-collapsible-item-header>
                <i class="material-icons">keyboard_arrow_down</i>
                  Final Approved Budget Policies 2024-25
              </mz-collapsible-item-header>
              <mz-collapsible-item-body>
                <mz-collection>
                  <mz-collection-item>
                    Documents Uploaded :
                    <a href="#"mz-secondary-content>
                        <span right>{{finalBudgetPolicies2025UploadDate |date : 'MMM, dd yyyy, HH:mm aaa'}}</span>
                    </a>
                  </mz-collection-item>
                  <mz-collection-item >
                    Matzikama Municipality 2024-2025 Approved Budget & Policies
                    <a href="/assets/StrategicDocs/Budget/2024-25/OneDrive_2024-05-21.zip" mz-secondary-content download>
                        <i class=" material-icons download rigth" download>cloud_download</i>
                    </a>
                </mz-collection-item>
                </mz-collection>
              </mz-collapsible-item-body>
            </mz-collapsible-item>
    
          </mz-collapsible>
        </div>
    </div>
    <div class="row">
        <div class="col s12 m12 l12">
          <mz-collapsible [mode]="'expandable'" [popout]="true">
            <mz-collapsible-item [active] = "true">
              <mz-collapsible-item-header>
                <i class="material-icons">keyboard_arrow_down</i>
                  Final Approved Budget Policies 2023-24
              </mz-collapsible-item-header>
              <mz-collapsible-item-body>
                <mz-collection>
                  <mz-collection-item>
                    Documents Uploaded :
                    <a href="#"mz-secondary-content>
                        <span right>{{finalBudgetPolicies2024UploadDate |date : 'MMM, dd yyyy, HH:mm aaa'}}</span>
                    </a>
                  </mz-collection-item>
                  <mz-collection-item >
                    Matzikama Municipality 2023-2024 Approved Budget & Policies
                    <a href="/assets/StrategicDocs/Budget/2023-24/OneDrive_2023-06-01.zip" mz-secondary-content download>
                        <i class=" material-icons download rigth" download>cloud_download</i>
                    </a>
                </mz-collection-item>
                </mz-collection>
              </mz-collapsible-item-body>
            </mz-collapsible-item>
    
          </mz-collapsible>
        </div>
    </div>
    <div class="row">
    <div class="col s12 m12 l12">
      <mz-collapsible [mode]="'expandable'" [popout]="true">
        <mz-collapsible-item [active] = "true">
          <mz-collapsible-item-header>
            <i class="material-icons">keyboard_arrow_down</i>
              Final Approved Budget Policies 2022-23
          </mz-collapsible-item-header>
          <mz-collapsible-item-body>
            <mz-collection>
              <mz-collection-item>
                Documents Uploaded :
                <a href="#"mz-secondary-content>
                    <span right>{{finalBudgetPolicies2023UploadDate |date : 'MMM, dd yyyy, HH:mm aaa'}}</span>
                </a>
              </mz-collection-item>
              <mz-collection-item >
                Matzikama Municipality 2022-2023 Approved Budget & Policies
                <a href="/assets/StrategicDocs/Budget/2022-23/OneDrive_2022-06-01.zip" mz-secondary-content download>
                    <i class=" material-icons download rigth" download>cloud_download</i>
                </a>
            </mz-collection-item>
            </mz-collection>
          </mz-collapsible-item-body>
        </mz-collapsible-item>

      </mz-collapsible>
    </div>
  </div>
    <div class="row">
        <div class="col s12 m12 l12">
            <mz-collapsible  [mode]="'expandable'" [popout]="true">
                <mz-collapsible-item [active] = "true">
                    <mz-collapsible-item-header>
                        <i class="material-icons">keyboard_arrow_down</i>
                            Final Approved Budget Policies 2021-22
                    </mz-collapsible-item-header>
                    <mz-collapsible-item-body>
                        <mz-collection>
                            <mz-collection-item>
                                Documents Uploaded :
                                <a href="#"mz-secondary-content>
                                    <span right>{{finalBudgetPolicies2022UploadDate |date : 'MMM, dd yyyy, HH:mm aaa'}}</span>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                Delegation table for SCM - 2021-2022 Matzikama
                                <a href="/assets/StrategicDocs/finpol/2021-22/Final Policies May 2021/Delegation table for SCM - 2021-2022 Matzikama.pdf" mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                C 1 Tariff Policy 2021-2022 ( Unchanged)
                                <a href="/assets/StrategicDocs/finpol/2021-22/Final Policies May 2021/C 1 Tariff Policy 2021-2022 ( Unchanged).pdf" mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                C 2 Property Taxes Policy 2021-2022 (Reviewed-Changed)
                                <a href="/assets/StrategicDocs/finpol/2021-22/Final Policies May 2021/C 2 Property Taxes Policy 2021-2022 (Reviewed-Changed).pdf" mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                C 3 Credit Control and Debt Management 2021-2022 (Reviewed-Changed)
                                <a href="/assets/StrategicDocs/finpol/2021-22/Final Policies May 2021/C 3 Credit Control and Debt Management 2021-2022 (Reviewed-Changed).pdf" mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                C 4 Indigent Policy 2021-2022 (Reviewed - Changed)
                                <a href="/assets/StrategicDocs/finpol/2021-22/Final Policies May 2021/C 4 Indigent Policy 2021-2022 (Reviewed - Changed).pdf" mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                C 5 Infrastructure Procurement and Delivery Management Policy 2021-2022 (Reviewed-Unchanged)
                                <a href="/assets/StrategicDocs/finpol/2021-22/Final Policies May 2021/C 5 Infrastructure Procurement and Delivery Management Policy 2021-2022 (Reviewed-Unchanged).pdf" mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                C 6 Cash Management and Investment policy 2021-2022 (Reviewed-Changed)
                                <a href="/assets/StrategicDocs/finpol/2021-22/Final Policies May 2021/C 6 Cash Management and Investment policy 2021-2022 (Reviewed-Changed).pdf" mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                C 7 Matzikama SCM Policy 2021-2022 (Reviewed-Changed)
                                <a href="/assets/StrategicDocs/finpol/2021-22/Final Policies May 2021/C 7 Matzikama SCM Policy 2021-2022 (Reviewed-Changed).pdf" mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                C 8 Preferential Procurement Policy  2021-2022 (Unchanged)
                                <a href="/assets/StrategicDocs/finpol/2021-22/Final Policies May 2021/C 8 Preferential Procurement Policy  2021-2022 (Unchanged).pdf" mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                C 9 Asset Management Policy 2021-2022 (Reviewed-Changed)
                                <a href="/assets/StrategicDocs/finpol/2021-22/Final Policies May 2021/C 9 Asset Management Policy 2021-2022 (Reviewed-Changed).pdf" mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                C 10 Funding and-Reserves Policy 2021-2022 ( Unchanged)
                                <a href="/assets/StrategicDocs/finpol/2021-22/Final Policies May 2021/C 10 Funding and-Reserves Policy 2021-2022 ( Unchanged).pdf" mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                C 11 Debt Policy 2021-2022 (Unchanged)
                                <a href="/assets/StrategicDocs/finpol/2021-22/Final Policies May 2021/C 11 Debt Policy 2021-2022 (Unchanged).pdf" mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                C 12 Travel and Accommodation Policy 2021-2022 (Unchanged)
                                <a href="/assets/StrategicDocs/finpol/2021-22/Final Policies May 2021/C 12 Travel and Accommodation Policy 2021-2022 (Unchanged).pdf" mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                C 13 Virement policy 2021-2022 (Reviewed-Changed)
                                <a href="/assets/StrategicDocs/finpol/2021-22/Final Policies May 2021/C 13 Virement policy 2021-2022 (Reviewed-Changed).pdf" mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                C 14 Budget implementation and monitoring 2021-2022 ( Unchanged)
                                <a href="/assets/StrategicDocs/finpol/2021-22/Final Policies May 2021/C 14 Budget implementation and monitoring 2021-2022 ( Unchanged).pdf" mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                C 15 Rental of caravan camping sites and chalets in Matzikama 2021-2022 (Reviewed-Changed)
                                <a href="/assets/StrategicDocs/finpol/2021-22/Final Policies May 2021/C 15 Rental of caravan camping sites and chalets in Matzikama 2021-2022 (Reviewed-Changed).pdf" mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                Service standards  2021-2022 Matzikama municipalty WC011
                                <a href="/assets/StrategicDocs/finpol/2021-22/Final Policies May 2021/Service standards  2021-2022 Matzikama municipalty WC011.pdf" mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                Cost Containment Policy
                                <a href="/assets/StrategicDocs/finpol/2021-22/Final Policies May 2021/Cost Containment Policy.pdf" mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                Bylaw Property Rates
                                <a href="/assets/StrategicDocs/finpol/2021-22/Final Policies May 2021/BY LAW Finale 2010-Property Rates.pdf" mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                Municipal Property Rates Act Circular 12
                                <a href="/assets/StrategicDocs/finpol/2021-22/Final Policies May 2021/Property rates Circular 12.pdf" mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                Municipal Property Rates Act Circular 13
                                <a href="/assets/StrategicDocs/finpol/2021-22/Final Policies May 2021/Property rates Circular13.pdf" mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                        </mz-collection>
                    </mz-collapsible-item-body>
                </mz-collapsible-item>
            </mz-collapsible>
        </div>
    </div>
    <div class="row">
        <div class="col s12 m12 l12">
            <mz-collapsible  [mode]="'expandable'" [popout]="true">
                <mz-collapsible-item [active] = "true">
                    <mz-collapsible-item-header>
                        <i class="material-icons">keyboard_arrow_down</i>
                            Draft Budget Policies 2021-22
                    </mz-collapsible-item-header>
                    <mz-collapsible-item-body>
                        <mz-collection>
                            <mz-collection-item>
                                Documents Uploaded :
                                <a href="#"mz-secondary-content>
                                    <span right>{{draftBudgetPolicies2022UploadDate |date : 'MMM, dd yyyy, HH:mm aaa'}}</span>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                MATZIKAMA MUNICIPALITY DRAFT 2021 2022 TARIFFS
                                <a href="/assets/StrategicDocs/finpol/2021-22/Draft Policies March 2021/MATZIKAMA MUNICIPALITY DRAFT 2021 2022 TARIFFS.pdf" mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                C 2 Property Taxes Policy 2021-2022 (Reviewed-Changed)
                                <a href="/assets/StrategicDocs/finpol/2021-22/Draft Policies March 2021/C 2 Property Taxes Policy 2021-2022 (Reviewed-Changed).pdf" mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                C 3 Credit Control and Debt Management 2021-2022 (Reviewed-Changed)
                                <a href="/assets/StrategicDocs/finpol/2021-22/Draft Policies March 2021/C 3 Credit Control and Debt Management 2021-2022 (Reviewed-Changed).pdf" mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                Annexure C 3 Credit Control and Debt Management Voorstel 20202021
                                <a href="/assets/StrategicDocs/finpol/2021-22/Draft Policies March 2021/Annexure C 3 Credit Control and Debt Management Voorstel 20202021.pdf" mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                C 5 Infrastructure Procurement and Delivery Management Policy 2021-202 (Reviewed-Unchanged)
                                <a href="/assets/StrategicDocs/finpol/2021-22/Draft Policies March 2021/C 5 Infrastructure Procurement and Delivery Management Policy 2021-202 (Reviewed-Unchanged).pdf" mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                C 6 Cash Management and Investment policy 2021-2022 (Reviewed-Changed)
                                <a href="/assets/StrategicDocs/finpol/2021-22/Draft Policies March 2021/C 6 Cash Management and Investment policy 2021-2022 (Reviewed-Changed).pdf" mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                C 7 Matzikama SCM Policy 2021-2022 (Reviewed-Changed)
                                <a href="/assets/StrategicDocs/finpol/2021-22/Draft Policies March 2021/C 7 Matzikama SCM Policy 2021-2022 (Reviewed-Changed).pdf" mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                C 8 Preferential Procurement Policy  2021-2022 (Unchanged)
                                <a href="/assets/StrategicDocs/finpol/2021-22/Draft Policies March 2021/C 8 Preferential Procurement Policy  2021-2022 (Unchanged).pdf" mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                C 9 Asset Management Policy 2021-2022 (Reviewed-Changed)
                                <a href="/assets/StrategicDocs/finpol/2021-22/Draft Policies March 2021/C 9 Asset Management Policy 2021-2022 (Reviewed-Changed).pdf" mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                C 10 Funding and-Reserves Policy 2021-2022 ( Unchanged)
                                <a href="/assets/StrategicDocs/finpol/2021-22/Draft Policies March 2021/C 10 Funding and-Reserves Policy 2021-2022 ( Unchanged).pdf" mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                C 11 Debt Policy 2021-2022 (Unchanged)
                                <a href="/assets/StrategicDocs/finpol/2021-22/Draft Policies March 2021/C 11 Debt Policy 2021-2022 (Unchanged).pdf" mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                C 12 Travel and Accommodation Policy 2021-2022 (Unchanged)
                                <a href="/assets/StrategicDocs/finpol/2021-22/Draft Policies March 2021/C 12 Travel and Accommodation Policy 2021-2022 (Unchanged).pdf" mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                C 13 Virement policy 2021-2022 (Reviewed-Changed)
                                <a href="/assets/StrategicDocs/finpol/2021-22/Draft Policies March 2021/C 13 Virement policy 2021-2022 (Reviewed-Changed).pdf" mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                C 14 Budget implementation and monitoring 2021-2022 ( Unchanged)
                                <a href="/assets/StrategicDocs/finpol/2021-22/Draft Policies March 2021/C 14 Budget implementation and monitoring 2021-2022 ( Unchanged).pdf" mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                C 15 Rental of caravan camping sites and chalets in Matzikama 2021-2022 (Reviewed-Changed)
                                <a href="/assets/StrategicDocs/finpol/2021-22/Draft Policies March 2021/C 15 Rental of caravan camping sites and chalets in Matzikama 2021-2022 (Reviewed-Changed).pdf" mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                Service standards  2021-2022 Matzikama municipalty WC011
                                <a href="/assets/StrategicDocs/finpol/2021-22/Draft Policies March 2021/Service standards  2021-2022 Matzikama municipalty WC011.pdf" mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                2021_22 MFMA Budget Circular No 107 - 04 Dec 2020 (final)
                                <a href="/assets/StrategicDocs/finpol/2021-22/Draft Policies March 2021/2021_22 MFMA Budget Circular No 107 - 04 Dec 2020 (final).pdf" mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                2021_22 MFMA Budget Circular No. 108 - 08 Mar 2021 (Final)
                                <a href="/assets/StrategicDocs/finpol/2021-22/Draft Policies March 2021/2021_22 MFMA Budget Circular No. 108 - 08 Mar 2021 (Final).pdf" mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                Bylaw Property Rates
                                <a href="/assets/StrategicDocs/finpol/2021-22/Draft Policies March 2021/Bylaw Property Rates.pdf" mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                Municipal Property Rates Act Circular 12
                                <a href="/assets/StrategicDocs/finpol/2021-22/Draft Policies March 2021/Municipal Property Rates Act Circular 12.pdf" mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                Municipal Property Rates Act Circular 13
                                <a href="/assets/StrategicDocs/finpol/2021-22/Draft Policies March 2021/Municipal Property Rates Act Circular 13.pdf" mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                        </mz-collection>
                    </mz-collapsible-item-body>
                </mz-collapsible-item>
            </mz-collapsible>
        </div>
    </div>
    <div class="row">
        <div class="col s12 m12 l12">
            <mz-collapsible  [mode]="'expandable'" [popout]="true">
                <!-- New Collapsible Item Start -->
                <mz-collapsible-item [active] = "true">
                    <mz-collapsible-item-header>
                     <i class="material-icons">keyboard_arrow_down</i>
                    Budget Policies 2020-21 Approved
                    </mz-collapsible-item-header>
                     <mz-collapsible-item-body>
                         <mz-collection>
                             <mz-collection-item>
                                 Documents Uploaded :
                                 <a href="#"mz-secondary-content>
                                     <span right>{{finalBudgetPoliciesUploadDate |date : 'MMM, dd yyyy, HH:mm:ss aaa'}}</span>
                                 </a>
                             </mz-collection-item>
                             <mz-collection-item>
                                Annexure C 1 Tariff Policy 2020-2021 ( Unchanged)
                                 <a href="/assets/StrategicDocs/finpol/2020-21/final/Annexure C 1 Tariff Policy 2020-2021 ( Unchanged).pdf" mz-secondary-content download>

                                     <i class=" material-icons download rigth" download>cloud_download</i>
                                 </a>
                             </mz-collection-item>
                             <mz-collection-item>
                                C 2 Property Taxes Policy 2020-2021 (Changed)
                                 <a href="/assets/StrategicDocs/finpol/2020-21/final/C 2 Property Taxes Policy 2020-2021 (Changed).pdf" mz-secondary-content download>

                                     <i class=" material-icons download rigth" download>cloud_download</i>
                                 </a>
                             </mz-collection-item>
                             <mz-collection-item>
                                C 3 Credit Control and Debt Management 20202021 (Changed)
                                 <a href="/assets/StrategicDocs/finpol/2020-21/final/C 3 Credit Control and Debt Management 20202021 (Changed).pdf" mz-secondary-content download>

                                     <i class=" material-icons download rigth" download>cloud_download</i>
                                 </a>
                             </mz-collection-item>
                             <mz-collection-item>
                                MATZIKAMA MUNISIPALITEIT FINAL 2020 - 2021 TARRIFS  LISTING
                                 <a href="/assets/StrategicDocs/finpol/2020-21/final/MATZIKAMA MUNISIPALITEIT FINAL 2020 - 2021 TARRIFS  LISTING.pdf" mz-secondary-content download>

                                     <i class=" material-icons download rigth" download>cloud_download</i>
                                 </a>
                             </mz-collection-item>
                             <mz-collection-item>
                                C 4 Indigent Policy 20202021 (Changed)
                                 <a href="/assets/StrategicDocs/finpol/2020-21/final/C 4 Indigent Policy 20202021 (Changed).pdf" mz-secondary-content download>

                                     <i class=" material-icons download rigth" download>cloud_download</i>
                                 </a>
                             </mz-collection-item>
                             <mz-collection-item>
                                C 5 Infrastructure Procurement and Delivery Management Policy2020-2021 (Changed)
                                 <a href="/assets/StrategicDocs/finpol/2020-21/final/C 5 Infrastructure Procurement and Delivery Management Policy2020-2021 (Changed).pdf" mz-secondary-content download>

                                     <i class=" material-icons download rigth" download>cloud_download</i>
                                 </a>
                             </mz-collection-item>
                             <mz-collection-item>
                                C 6 Cash Management and Investment policy020-2021 (Unchanged)
                                 <a href="/assets/StrategicDocs/finpol/2020-21/final/C 6 Cash Management and Investment policy020-2021 (Unchanged).pdf" mz-secondary-content download>

                                     <i class=" material-icons download rigth" download>cloud_download</i>
                                 </a>
                             </mz-collection-item>
                             <mz-collection-item>
                                C 7 Matzikama SCM Policy 2020-2021 (Changed)
                                 <a href="/assets/StrategicDocs/finpol/2020-21/final/C 7 Matzikama SCM Policy 2020-2021 (Changed).pdf" mz-secondary-content download>

                                     <i class=" material-icons download rigth" download>cloud_download</i>
                                 </a>
                             </mz-collection-item>
                             <mz-collection-item>
                                C 8 Preferential Procurement Policy  2020-2021 (Unchanged)
                                 <a href="/assets/StrategicDocs/finpol/2020-21/final/C 8 Preferential Procurement Policy  2020-2021 (Unchanged).pdf" mz-secondary-content download>

                                     <i class=" material-icons download rigth" download>cloud_download</i>
                                 </a>
                             </mz-collection-item>
                             <mz-collection-item>
                                C 9 Asset Management Policy 2020-2021 (Changed)
                                 <a href="/assets/StrategicDocs/finpol/2020-21/final/C 9 Asset Management Policy 2020-2021 (Changed).pdf" mz-secondary-content download>

                                     <i class=" material-icons download rigth" download>cloud_download</i>
                                 </a>
                             </mz-collection-item>
                             <mz-collection-item>
                                 Annexure C 10 Funding and-Reserves Policy 2020-2021 ( Unchanged)
                                 <a href="/assets/StrategicDocs/finpol/2020-21/final/C 10 Funding and-Reserves Policy 2020-2021 ( Unchanged).pdf" mz-secondary-content download>

                                     <i class=" material-icons download rigth" download>cloud_download</i>
                                 </a>
                             </mz-collection-item>
                             <mz-collection-item>
                                 Annexure C 11 Debt Policy 2020-2021 (Unchanged)
                                 <a href="/assets/StrategicDocs/finpol/2020-21/final/C 11 Debt Policy 2020-2021 (Unchanged).pdf" mz-secondary-content download>

                                     <i class=" material-icons download rigth" download>cloud_download</i>
                                 </a>
                             </mz-collection-item>
                             <mz-collection-item>
                                 Annexure C 12 Travel and Accommodation Policy 2020-2021 (Unchanged)
                                 <a href="/assets/StrategicDocs/finpol/2020-21/final/C 12 Travel and Accommodation Policy 2020-2021 (Unchanged).pdf" mz-secondary-content download>

                                     <i class=" material-icons download rigth" download>cloud_download</i>
                                 </a>
                             </mz-collection-item>
                             <mz-collection-item>
                                 Annexure C 13 Virement policy 2020-2021 (Unchanged)
                                 <a href="/assets/StrategicDocs/finpol/2020-21/final/C 13 Virement policy 2020-2021 (Unchanged).pdf" mz-secondary-content download>

                                     <i class=" material-icons download rigth" download>cloud_download</i>
                                 </a>
                             </mz-collection-item>
                             <mz-collection-item>
                                 Annexure C 14 Budget implementation and monitoring 2020-2021 ( Unchanged)
                                 <a href="/assets/StrategicDocs/finpol/2020-21/final/C 14 Budget implementation and monitoring 2020-2021 ( Unchanged).pdf" mz-secondary-content download>

                                     <i class=" material-icons download rigth" download>cloud_download</i>
                                 </a>
                             </mz-collection-item>
                             <mz-collection-item>
                                 Annexure C 15 Rental of caravan  camping sites and chalets in Matzikama 2020-2021 (Changed)
                                 <a href="/assets/StrategicDocs/finpol/2020-21/final/C 15 Rental of caravan  camping sites and chalets in Matzikama 2020-2021 (Changed).pdf" mz-secondary-content download>

                                     <i class=" material-icons download rigth" download>cloud_download</i>
                                 </a>
                             </mz-collection-item>
                             <mz-collection-item>
                                Service Standards  2020-2021 Matzikama municipalty WC011
                                 <a href="/assets/StrategicDocs/finpol/2020-21/final/Service Standards  2020-2021 Matzikama municipalty WC011.pdf" mz-secondary-content download>

                                     <i class=" material-icons download rigth" download>cloud_download</i>
                                 </a>
                             </mz-collection-item>
                         </mz-collection>
                     </mz-collapsible-item-body>
                 </mz-collapsible-item>
            </mz-collapsible>
        </div>
    </div>
    <div class="row">
        <div class="col s12 m12 l12">
            <mz-collapsible  [mode]="'expandable'" [popout]="true">
                <mz-collapsible-item [active] = "true">
                    <mz-collapsible-item-header>
                        <i class="material-icons">keyboard_arrow_down</i>
                            Draft Budget Policies 2020-21
                    </mz-collapsible-item-header>
                    <mz-collapsible-item-body>
                        <mz-collection>
                            <mz-collection-item>
                                Documents Uploaded :
                                <a href="#"mz-secondary-content>
                                    <span right>{{draftBudgetPoliciesUploadDate |date : 'MMM, dd yyyy, HH:mm:ss aaa'}}</span>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                Annexure C 1 Tariff Policy 2020-2021 ( Unchanged)
                                <a href="/assets/StrategicDocs/finpol/2020-21/draft/Annexure C 1 Tariff Policy 2020-2021 ( Unchanged).pdf" mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                Annexure C 2 Property Taxes policy 2020-2021 (Changed)
                                <a href="/assets/StrategicDocs/finpol/2020-21/draft/Annexure C 2 Property Taxes policy 2020-2021 (Changed).pdf" mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                Annexure C 3 Credit Control and Debt Management 20202021 (Changed)
                                <a href="/assets/StrategicDocs/finpol/2020-21/draft/Annexure C 3 Credit Control and Debt Management 20202021 (Changed).pdf" mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                Annexure C 3 Credit Control and Debt Management Voorstel 20202021
                                <a href="/assets/StrategicDocs/finpol/2020-21/draft/Annexure C 3 Credit Control and Debt Management Voorstel 20202021.pdf" mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                Annexure C 4 Indigent Policy 20202021 (Changed)
                                <a href="/assets/StrategicDocs/finpol/2020-21/draft/Annexure C 4 Indigent Policy 20202021 (Changed).pdf" mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                Annexure C 5 Infrastructure Procurement and Delivery Management Policy2020-2021 (Changed)
                                <a href="/assets/StrategicDocs/finpol/2020-21/draft/Annexure C 5 Infrastructure Procurement and Delivery Management Policy2020-2021 (Changed).pdf" mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                Annexure C 6 Cash Management and Investment policy020-2021 (Unchanged)
                                <a href="/assets/StrategicDocs/finpol/2020-21/draft/Annexure C 6 Cash Management and Investment policy020-2021 (Unchanged).pdf" mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                Annexure C 7 Matzikama SCM Policy 2020-2021 (Unchanged)
                                <a href="/assets/StrategicDocs/finpol/2020-21/draft/Annexure C 7 Matzikama SCM Policy 2020-2021 (Unchanged).pdf" mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                Annexure C 8 Preferential Procurement Policy  2020-2021 (Unchanged)
                                <a href="/assets/StrategicDocs/finpol/2020-21/draft/Annexure C 8 Preferential Procurement Policy  2020-2021 (Unchanged).pdf" mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                Annexure C 9 Asset Management Policy 2020-2021 (Changed)
                                <a href="/assets/StrategicDocs/finpol/2020-21/draft/Annexure C 9 Asset Management Policy 2020-2021 (Changed).pdf" mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                Annexure C 10 Funding and-Reserves Policy 2020-2021 ( Unchanged)
                                <a href="/assets/StrategicDocs/finpol/2020-21/draft/Annexure C 10 Funding and-Reserves Policy 2020-2021 ( Unchanged).pdf" mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                Annexure C 11 Debt Policy 2020-2021 (Unchanged)
                                <a href="/assets/StrategicDocs/finpol/2020-21/draft/Annexure C 11 Debt Policy 2020-2021 (Unchanged).pdf" mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                Annexure C 12 Travel and Accommodation Policy 2020-2021 (Unchanged)
                                <a href="/assets/StrategicDocs/finpol/2020-21/draft/Annexure C 12 Travel and Accommodation Policy 2020-2021 (Unchanged).pdf" mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                Annexure C 13 Virement policy 2020-2021 (Unchanged)
                                <a href="/assets/StrategicDocs/finpol/2020-21/draft/Annexure C 13 Virement policy 2020-2021 (Unchanged).pdf" mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                Annexure C 14 Budget implementation and monitoring 2020-2021 ( Unchanged)
                                <a href="/assets/StrategicDocs/finpol/2020-21/draft/Annexure C 14 Budget implementation and monitoring 2020-2021 ( Unchanged).pdf" mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                Annexure C 15 Rental of caravan  camping sites and chalets in Matzikama 2020-2021 (Changed)
                                <a href="/assets/StrategicDocs/finpol/2020-21/draft/Annexure C 15 Rental of caravan  camping sites and chalets in Matzikama 2020-2021 (Changed).pdf" mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                Annexure D - Service standards  2020-2021 Matzikama municipalty WC011
                                <a href="/assets/StrategicDocs/finpol/2020-21/draft/Annexure D - Service standards  2020-2021 Matzikama municipalty WC011.pdf" mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                Annexure E - MFMA Budget Circular No 99 - 09 March 2020 (final)
                                <a href="/assets/StrategicDocs/finpol/2020-21/draft/Annexure E - MFMA Budget Circular No 99 - 09 March 2020 (final).pdf" mz-secondary-content download>

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                        </mz-collection>
                    </mz-collapsible-item-body>
                </mz-collapsible-item>
            </mz-collapsible>
        </div>
    </div>
</div>

