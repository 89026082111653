<div class="container">
    <div class="row">
        <h4 class="center-align">Cancelled Tenders </h4>
        <div class="col-s6" *ngFor="let tender of cancelledTenders">
            <mz-collapsible
                [mode]="'expandable'"
               
                [popout]="true" *ngIf="tender.tenderStatus === 'Not Awarded'">
                    <mz-collapsible-item [active]="true">
                        <mz-collapsible-item-header>
                            <i class="material-icons" >keyboard_arrow_down</i>
                            {{tender.tenderTitle}}
                        </mz-collapsible-item-header>
                            <mz-collapsible-item-body>
                                <table class="responsive-table">
                                    <thead>
                                        <tr>
                                            <th data-field="id">Description</th>
                                            <th data-field="name">Details</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                                 <tr>
                                                    <td><b>Title:</b></td>
                                                    <td>{{tender.tenderTitle}}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Reference Number:</b></td>
                                                    <td>{{tender.tenderReferenceNumber}}</td>
                                                </tr>
                                                 <tr>
                                                    <td><b>Upload Date:</b></td>
                                                    <td>{{tender.tenderUploadedDate}}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Upload Time:</b></td>
                                                    <td>{{tender.tenderUploadedTime}}</td>
                                                </tr> 
                                                <!-- <tr>
                                                    <td><b>Closing Date:</b></td>
                                                    <td>{{tender.tenderClosingDate}}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Closing Time:</b></td>
                                                    <td>{{tender.tenderClosingTime}}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Compulsory Meeting Details:</b></td>
                                                    <td>{{tender.tenderCompulsoryMeeting}}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Tender Awarded To:</b></td>
                                                    <td>{{tender.tenderAward | uppercase}}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>BEE Status:</b></td>
                                                    <td>Level : {{tender.tenderBEEStatus}}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Tender Value:</b></td>
                                                    <td>{{tender.tenderValue}}</td>
                                                </tr> -->
                                                <tr>
                                                    <td><b>Tender Status:</b></td>
                                                    <td>{{tender.tenderStatus}}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Advertisement Download:</b></td>
                                                    <td><a href="{{tender.tenderLink}}" download><u>{{tender.tenderReferenceNumber}}</u></a></td>
                                                </tr>
                                    </tbody>
                                </table>  
                            </mz-collapsible-item-body>
                    </mz-collapsible-item>
            </mz-collapsible>
        </div>
    </div>
</div>
