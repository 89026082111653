
export class TendersModule {

  public tenderTitle:string;
  public tenderReferenceNumber:string;
  public tenderUploadedDate:string;
  public tenderUploadedTime:string;
  public tenderClosingDate:string;
  public tenderClosingTime:string;
  public tenderCompulsoryMeeting:string;
  public tenderDescription:string;
  public tenderLink:string;s
  public tenderAward:string;
  public tenderBEEStatus:string;
  public tenderValue:string;
  public tenderStatus:string

  constructor(
    title:string,
    reference:string,
    uploadDate:string,
    uploadTime:string,
    closingDate:string,
    closingTime:string,
    meeting:string,
    desc:string,
    link:string,
    award:string,
    BEE:string,
    value:string,
    status:string

  ) {
    this.tenderTitle = title;
    this.tenderReferenceNumber=reference;
    this.tenderUploadedDate=uploadDate;
    this.tenderUploadedTime=uploadTime;
    this.tenderClosingDate=closingDate;
    this.tenderClosingTime=closingTime;
    this.tenderCompulsoryMeeting=meeting;
    this.tenderDescription=desc;
    this.tenderLink=link;
    this.tenderAward=award;
    this.tenderBEEStatus=BEE;
    this.tenderValue=value;
    this.tenderStatus = status;

  }
}

