<h3 class="center">Public Participation</h3>
<div class="row" >
  <h5 class="center">PUBLIC PARTICIPATION: REVIEW OF THE INTEGRATED DEVELOPMENT PLAN (IDP) AND BUDGET 2025-2026</h5>
  <div class="container">
    <p align ="justify">
      Notice is hereby given in terms of the Constitution of the Republic of South Africa, Act 108 of 1996, as well 
      as the Local Governement: Municipal Systems Act, 2000, Act 32 of 2000 (Chapter 3 & 4 ) and under Section 21 of 
      the Local Government: Municipal Finance Management Act to prepare the 2025/2026 budget to encourage involvement 
      of communities and community organisations in the affairs of the municipality. Members of the public are hereby 
      invited to participate in the above process under Section 16 of the Local Government: Municipal Systems Act.
      To give effect to the above legislation, the Council of Matzikama Municipality plans a series of public 
      consultation engagements where officers representing each directorate will be available to take inputs.These 
      events will therefore not take place in a meeting format. See table below for details:
    </p>
  </div>
  <table class="responsive-table highlight centered">
    <thead>
      <tr>
          <th>Ward</th>
          <th>Date</th>
          <th>Town</th>
          <th>Venue</th>
          <th>Time</th>
      </tr>
    </thead>

    <tbody>
      <tr  *ngFor="let publicMeetings of publicMeeting">
        <td>{{publicMeetings.publicMeetingWard}}</td>
        <td>{{publicMeetings.publicMeetingDayandTime}}</td>
        <td>{{publicMeetings.publicMeetingTown}}</td>
        <td>{{publicMeetings.publicMeetingPlace}}</td>
        <td>{{publicMeetings.publicMeetingTime}}</td>
      </tr>
    </tbody>
  </table>
</div>
<div class="container">
  <p>
    Comments must be submitted in writing to the Municipal Manager, Matzikama Municipality, PO Box 98, Vredendal, 8160 
    or by e-mail to deonew@matzikama.gov.za to reach him no later than 12:00 or Friday,18 October 2024. Those who do 
    not have aforementioned facilities can call the IDP office on telephone numbers 027 201 3396/3426/3421 or visit for 
    assistance in this regard. 
  </p>
</div>
<app-footer></app-footer>
 