
<app-slider></app-slider>
<ng-marquee speed="slow" [stopOnHover]="true" direction="alternate">
    <h5>      
        <a [routerLink]="['/municipal-notices']">SSEG NOTICE</a>♦ 
        <a [routerLink]="['/municipal-notices']">SPAZA SHOPS NOTICE</a>♦
        <a [routerLink]="['/stratdocs']">2024-25 APPROVED IDP & BUDGET</a>♦
        <a [routerLink]="['/municipal-notices']">2025 EASTER BOOKING</a>♦



        <!-- Please note that Formal Quotes 16, 17, 18 and 19 has been cancelled. -->

    </h5>
</ng-marquee>
<hr>
<!-- End of marquee -->
<div class="row">
  <div class="col s12 m6 l6" >
    <div class="card #1a237e indigo darken-4 hoverable">
      <div class="card-content white-text">
        <span class="card-title">MUNISIPALE BANKBESONDERHEDE</span>
        <p class="truncate">Die Standard Bank besonderhede is soos volg:  <br>

          Rekening naam:                  Matzikama Munisipaliteit <br>
          Rekening nommer:              082 451 885 <br>
          Takkode:                                050706 <br>
          Verwysingsnommer:           Munisipale Rekening nommer <br>
      </p>
      </div>
    </div>
  </div>
  <div class="col s12 m6 l6">
    <div class="card #311b92 deep-purple darken-4 hoverable">
      <div class="card-content white-text">
        <span class="card-title">MUNICIPAL BANK ACCOUNT DETAILS</span>
        <p class="truncate">
          The Standard Bank details is as follow: <br>

          Account name:                     Matzikama Municipality <br>
          Account number:                  082 451 885 <br>
          Branch code:                        050706 <br>
          Reference number:              Municipal Account number <br>
      </p>
      </div>
    </div>
  </div>
</div>
<hr>
<!-- <div class="row" >
  <h5 class="center">PUBLIC PARTICIPATION: REVIEW OF THE INTEGRATED DEVELOPMENT PLAN (IDP) AND BUDGET 2025-2026</h5>
  <div class="container">
    <p align ="justify">
      Notice is hereby given in terms of the Constitution of the Republic of South Africa, Act 108 of 1996, as well 
      as the Local Governement: Municipal Systems Act, 2000, Act 32 of 2000 (Chapter 3 & 4 ) and under Section 21 of 
      the Local Government: Municipal Finance Management Act to prepare the 2025/2026 budget to encourage involvement 
      of communities and community organisations in the affairs of the municipality. Members of the public are hereby 
      invited to participate in the above process under Section 16 of the Local Government: Municipal Systems Act.
      To give effect to the above legislation, the Council of Matzikama Municipality plans a series of public 
      consultation engagements where officers representing each directorate will be available to take inputs.These 
      events will therefore not take place in a meeting format. See table below for details:
    </p>
  </div>
  <table class="responsive-table highlight centered">
    <thead>
      <tr>
          <th>Ward</th>
          <th>Date</th>
          <th>Town</th>
          <th>Venue</th>
          <th>Time</th>
      </tr>
    </thead>

    <tbody>
      <tr  *ngFor="let publicMeetings of publicMeeting">
        <td>{{publicMeetings.publicMeetingWard}}</td>
        <td>{{publicMeetings.publicMeetingDayandTime}}</td>
        <td>{{publicMeetings.publicMeetingTown}}</td>
        <td>{{publicMeetings.publicMeetingPlace}}</td>
        <td>{{publicMeetings.publicMeetingTime}}</td>
      </tr>
    </tbody>
  </table>
</div>
<div class="container">
  <p align="justify">
    Comments must be submitted in writing to the Municipal Manager, Matzikama Municipality, PO Box 98, Vredendal, 8160 
    or by e-mail to deonew@matzikama.gov.za to reach him no later than 12:00 or Friday,18 October 2024. Those who do 
    not have aforementioned facilities can call the IDP office on telephone numbers 027 201 3396/3426/3421 or visit for 
    assistance in this regard. 
  </p>
</div>
<hr> -->
<!-- 
<div class="row">
  <div class="col s12 m12 l6">
      <div class="card #81d4fa light-blue lighten-3">
          <div class="card-content white-text">
              <span class="card-title center-align ">BELANGRIKE KENNISGEWING</span>
              <p class="justify">
              Aandag Matzikama Inwoners: <br>
              24 November 2022<br>

              <strong>PERSVERKLARING</strong>  <br>
              
              Matzikama Munisipaliteit wil hiermee inwoners in kennis stel dat die water geskik is vir menslike gebruik.<br>
           
              Die uwe <br>
              G SEAS <br>
              MUNISIPALE BESTUURDER
              </p>
          </div>
          <div class="card-action">
              <a [routerLink]="['/municipal-notices']">View</a>
          </div>
      </div>
  </div>
  <div class="col s12 m12 l6">
    <div class="card #80cbc4 teal lighten-3">
        <div class="card-content white-text">
            <span class="card-title center-align">IMPORTANT NOTICE</span>
            <p class="justify">
              Attention Matzikama Residents: <br>
              24 November 2022 <br>

            <strong>PRESS RELEASE</strong>  <br>

            Matzikama Municipality hereby wishes to inform residents that the water is suitable for human consumption. <br>
          
              Kind regards <br>
  
              G SEAS <br>
              MUNICIPAL MANAGER
  
            </p>
        </div>
        <div class="card-action">
            <a [routerLink]="['/municipal-notices']">View</a>
        </div>
    </div>
  </div>
  </div> -->
<div class="row">
  <div class="container">
    <div class="col s12 m12 l12">
      <div class="card #1565c0 blue darken-3">
          <div class="card-content center-align white-text">
              <span class="card-title center-align">IMPORTANT NOTICE</span>
              <p class="justify">
                The Matzikama Municipality invites applications
                for Strandfontein bookings for Easter holidays
                period : <b>17 April 2025 to 5 May 2025.</b> <br>
                <b>Important Dates:</b>
                 <li>Applications open: <b>Wednesday, 11 December
                  2024.</b></li>
                  <li>Applications close: <b>Tuesday, 17 December 2024.</b></li> 
                  <li>Notification to successful applicants: <b>Monday,
                    6 January 2025.</b></li> <br>
                Application Process:
                <li>Application forms will be available from<b>Wednesday, 11 December 2024 </b>at the municipal
                  offices and website.</li>
                <li>Successful applicants must rent the plots for
                  the full period.</li>
                <li>No plots may be sold or exchanged privately.</li> 
                <li>If the successful applicant does not continue to
                  rent the plot, the plot must be returned to the
                  municipality.</li>
    
                Note: If you do not receive an award letter by
                <b>Monday, 6 January 2025,</b> your application has
                been unsuccessful. <br>
                For more information contact: <b>arinac@matzikama.gov.za</b>
          </div>
          <div class="card-action">
              <a [routerLink]="['/municipal-notices']">View Notice</a>
              <a href="/assets/STRANDFONTEIN APPLICATION FORM EASTER WEEKEND 2025.pdf" download>Application Form</a>
              <a href="/assets/C 15 Final Rental of caravan camping sites and chalets in Matzikama 2024-2025 (Reviewed - Changed).pdf" download>Policy</a>

      </div>
    </div>
  </div>
</div>
<!-- <div class="row">
  <div class="col s12 m6">
    <div class="card #1b5e20 green darken-4">
      <div class="card-content white-text">
        <span class="card-title">REGISTRATION ON DATABASIS FOR EPWP PROJECTS (2022/2023)</span>
        <p>Unemployed people, who are interest in EPWP, are hereby invited to register on the EPWP  database for EPWP projects.
          The following projects will be considered:
          •	Water and Waste Water Maintenance
          •	Pothole Repair and Road Marking
          •	Electrical Maintenance and Repair
          •	Safeguarding (Guards)
          •	Administrative Clerks & Support
          •	Cleaning of Informal Settlement Areas (Koekenaap / Klawer / Lutzville / Vredendal )
          •	Cleaning of Streets, Parks, and Open Spaces
          •	Cleaning of Landfill Sites
           <br>
      </p>
      </div>
      <div class="card-action">
        <a [routerLink]="['/municipal-notices']">View Notice</a>
        <a href="/assets/municipal-notices/EPWP KENNISGEWING.pdf" download>Download Application Form</a>
        <a href="/assets/municipal-notices/Aansoek vorm - EPWP project.pdf" download>Download Application Form</a>
      </div>
    </div>
  </div>
  <div class="col s12 m6">
    <div class="card #01579b #33691e light-green darken-4">
      <div class="card-content white-text">
        <span class="card-title">TRONOX REVIEW OF SOCIAL AND LABOUR PLAN</span>
        <p>Notice is hereby given of a Public Participation Process as required in terms of the Regulation 46B of the Regulations
          for Petroleum Exploration and Production, 2015 . This relates to the review of a Social and Labour Plan (SLP) every 5
          (five) years for the duration of Tronox’s mining rights.Tronox’s SLP (2018 – 2022) has reached the end of the 5 (five)
          year cycle and has therefore been revised. Once the Public Participation Process is complete, the SLP will be submitted
          to the DMRE for approval.
      </p>
      </div>
      <div class="card-action">
        <a [routerLink]="['/municipal-notices']">View Notice</a>
      </div>
    </div>
  </div>
</div> -->
<!-- <div class="container">
  <div class="row">
    <div class="col s12 m12 l6">
        <div class="card">
          <div class="card-image">
            <img src="/assets/Media Statements/img4.png" alt="DA Welcomes Patriotic Alliance Councillors">
            <span>
              <h6>DA verwelkom lede van die Patriotic Alliance</h6>
            </span>
          </div>
          <div class="card-content">
            <p class="justify">
             <span class="bolderText">Deur Tertuis Simmers – DA Tussentydse Provinsiale Leier, Wes-Kaap <br>

              Vrystelling: Onmiddellik <br>
              Datum: 3 Mei 2022 <br>
            </span>
              By ’n perskonferensie vroeër vandag het die Demokratiese Alliansie in die Wes-Kaap lede van die Patriotiese Alliansie van Matzikama by die party verwelkom.

              Saam met DA Tussentydse Provinsiale Leier, Tertuis Simmers, DA Provinsiale Voorsitter, Jaco Londt en Matzikama-burgemeester, raadslid Van Der Hoven, het hulle raadslid Christo Boks en leier van die PA in Matzikama, Mnr Brenden Owies, in die party verwelkom.

              Raadslid Boks is gister terug na sy wyk, om sy kiesers te raadpleeg. Hul oorweldigende reaksie was om hul raadslid 'n mandaat te gee om uit die PA te bedank en by die DA aan te sluit. Hulle het aangedui dat hulle verkies dat die DA die Matzikama-munisipaliteit moet bestuur, in plaas van kleiner partye wat die ANC bevorder.

              Van regoor die Wes-Kaap heen, veral in die landelike gebiede, is daar 'n hulpkreet van frustrasie deur kiesers teenoor kleiner partye wat hul kiesers uitverkoop en die ANC in staat stel om hul tirade van swak dienslewering voort te sit waar hulle regeer.

              As gevolg van die DA se positiewe rekord van goeie bestuur en dienslewering, veral in kwesbare gemeenskappe, is raadslid Boks en Mnr Owies opgewonde om by die DA aan te sluit.

              Ons is vol vertroue dat hulle daartoe sal bydra dat ons die wyk wen en sodoende die Matzikama Munisipaliteit behou, om te verseker dat goeie bestuur en goeie dienslewering in Matzikama voortgaan. <br>

              <span class="boldText">
                Medianavrae: <br>
              </span>
                Tertuis Simmers <br>
                DA Tussentydse Provinsiale Leier, Wes-Kaap <br>
                082 387 4045 <br>

                Melt Botes <br>
                Provinsiale kommunikasiebestuurder <br>
                082 431 0068 <br>
            </p>
          </div>
        </div>
    </div>
    <div class="col s12 m12 l6">
      <div class="card">
        <div class="card-image">
          <img src="/assets/Media Statements/img3.png" alt="DA Welcomes Patriotic Alliance Councillors">
          <span>
            <h6>DA welcomes councillors from the Patriotic Alliance</h6>
          </span>
        </div>
        <div class="card-content">
          <p class="justify">
            <span class="bolderText">
                By Tertuis Simmers – DA Interim Provincial Leader, Western Cape <br>
                Release: Immediately <br>
                Date: 3 May 2022 <br>
            </span>
                At a press conference earlier today, the Democratic Alliance in the Western Cape welcomed members of the Patriotic Alliance from Matzikama to the party.

                Joined by DA Interim Provincial Leader, Tertuis Simmers, DA Provincial Chair, Jaco Londt and Matzikama Mayor, Councillor Van Der Hoven, they welcomed Councillor Christo Boks and leader of the PA in Matzikama, Mr Brenden Owies, into the fold.

                Yesterday, councillor Boks went back to his ward, to consult his voters. Their overwhelming response was to give their councillor a mandate to resign from the PA and to join the DA. They indicated that they would prefer the DA to run the Matzikama Municipality, instead of smaller parties who enables the ANC.

                From across the Western Cape, especially in the rural areas, there is an outpouring of frustration by voters towards smaller parties who has effectively sold out their voters and are enabling the ANC to continue their stunt of poor service delivery where they govern.

                Because of the DA’s positive track record of good governance and service delivery, especially in vulnerable communities, Councillor Boks and Mr Owies are excited to join the DA.

                We are confident that they will contribute towards us winning the ward and retaining the Matzikama Municipality, ensuring that good governance continues in Matzikama.
              <span class="boldText">Media Enquiries:</span> <br>
                Tertuis Simmers <br>
                DA Interim Provincial Leader, Western Cape <br>
                082 387 4045 <br>

                Melt Botes <br>
                Provincial Communications Manager <br>
                082 431 0068 <br>

          </p>
        </div>
      </div>
    </div>
  </div>
</div> -->
<!-- <div class="row">
  <div class="col s12 m12 l12">
    <a href="https://getcounted.statssa.gov.za/#!/home">
      <img src="/assets/statsSaExtension.gif" alt="Census 2022 banner" class="responsive-img" id="censusBanner2022" >
    </a>

  </div>
</div> -->
<!-- <div class="container">
  <div class="row hide-on-small-only">
    <div class="col s12 m12 l12 offset-l3">
      <youtube-player videoId="ElV4K9Aol3c"></youtube-player>
    </div>
  </div>
</div> -->
  <!-- <hr> -->

<div class="container">
    <!-- Start of Internship cards -->
    <div class="row">
        <!-- <div class="col s12 m12 l6">
            <div class="card #0d47a1 blue darken-4">
                <div class="card-content white-text">
                    <span class="card-title">Municipal Internship Database 2021-2022</span>
                </div>
                <div class="card-action">
                    <a [routerLink]="['/municipal-notices']">View</a>
                    <a href="/assets/municipal-notices/Advert - Registration for Internship.pdf" download>Advert</a>
                    <a href="/assets/municipal-notices/Application form - Registration on municipal data base for Internship.pdf" download>Application Form</a>
                </div>
            </div>
        </div> -->
    </div>
        <!--Important Notifications   -->
        <!-- <h4 class="center">Important Notifications</h4>
        <div class="row">
          <div class="col s12 m12 l12">
            <div class="card #33691e light-green darken-4 hoverable">
              <div class="card-content white-text">
                  <span class="card-title">STRANDFONTEIN RESERVATIONS – FESTIVE SEASON</span>
                  <p>
                    The reservations for caravan sites for the period 13 December 2024 to 15 January 2025 is now open.
                    Application forms is available on the municipality’s website and at the offices of Matzikama Municipality.
                    Application forms must be handed in at the respective offices or sent by email to arinac@matzikama.gov.za.
                    Minimum required number of days for camping is 21 days.Closing date for applications is 16 August 2024.
                  </p>
              </div>
          </div>
          </div>
        </div>
        <div class="row">
          <div class="col s12 m12 l6">
              <div class="card #3949ab indigo darken-1 hoverable">
                  <div class="card-content white-text">
                      <span class="card-title">Strandfontein Reservations</span>
                      <p class="truncate">Download reservation form for caravan sites.</p>
                  </div>
                  <div class="card-action">
                    <a href="/assets/municipal-notices/2024-25/STRANDFONTEIN APPLICATION FORM.pdf" download>Download Form</a>
                  </div>
              </div>
          </div>
          <div class="col s12 m12 l6">
              <div class="card #3949ab indigo darken-1 hoverable">
                  <div class="card-content white-text">
                      <span class="card-title">Strandfontein Reservation Tariffs</span>
                      <p class="truncate">Download camping site tariffs and policy</p>
                  </div>
                  <div class="card-action">
                    <a href="/assets/municipal-notices/2024-25/STRANDFONTEIN TARIEWE 2024-2025.pdf" download>Download Tariffs</a>
                    <a href="/assets/municipal-notices/2024-25/C 15 Final Rental of caravan camping sites and chalets in Matzikama 2024-2025 (Reviewed - Changed).pdf" download>Download Policy</a>
                  </div>
              </div>
          </div>

      </div> -->
        <!-- Online Services Row  -->
     <!-- Upcoming Events  -->
     <!-- <div class="row">
      <h4 class="center">Upcoming Events</h4>
      <div class="col s12 m12 l4">
          <div class="card #0d47a1 blue darken-4 hoverable">
              <div class="card-content white-text">
                  <span class="card-title">SARS Visit</span>
                  <p>VREDENDAL-NORTH THUSONG CENTRE 10 & 11 JULY 09HOO - 15H00</p>
              </div>
              <div class="card-action">
                  <a href="assets/Events/2023-24/MTU TAX SEASON POSTER JULY 2024 VREDENDAL.pdf" download>Download Poster</a>
              </div>
          </div>
      </div>
      <div class="col s12 m12 l4">
          <div class="card #0d47a1 blue darken-4 hoverable">
              <div class="card-content white-text">
                  <span class="card-title">Road Accident Fund Visit</span>
                  <p>VREDENDAL-NORTH THUSONG CENTRE 10 & 11 JULY 09HOO - 15H00</p>
              </div>
              <div class="card-action">
                <a href="assets/Events/2023-24/Vredendal Community Hall English (002).pptx" download>Download Poster</a>
              </div>
          </div>
      </div>
      <div class="col s12 m12 l4">
          <div class="card #0d47a1 blue darken-4 hoverable">
              <div class="card-content white-text">
                  <span class="card-title">Construction Expo</span>
                  <p>Construction Information Session - EXPO 7 August 2024 08H30 - 14H30</p>
              </div>
              <div class="card-action">
                <a href="assets/Events/2023-24/Poster-CIS.pdf" download>Download Poster</a>
              </div>
          </div>
      </div>
  </div> -->
    <!-- Online Services Row  -->
    <div class="row">
        <h4 class="center">Online Services</h4>
        <div class="col s12 m12 l4">
            <div class="card #0d47a1 blue darken-4 hoverable">
                <div class="card-content white-text">
                    <span class="card-title">Accommodation</span>
                    <p>Book at strandfontein@matzikama.gov.za</p>
                </div>
                <div class="card-action">
                    <!-- <a href="https://book.nightsbridge.com/28520 " target="_blank">Book Now</a> -->
                    <a href="mailto:strandfontein@matzikama.gov.za">Email Now</a><br>
                </div>
            </div>
        </div>
        <div class="col s12 m12 l4">
            <div class="card #0d47a1 blue darken-4 hoverable">
                <div class="card-content white-text">
                    <span class="card-title">EasyPay</span>
                    <p>Buy prepaid electricity online here.</p>
                </div>
                <div class="card-action">
                    <a href="https://new.easypay.co.za" target="_blank">Buy Prepaid</a>
                </div>
            </div>
        </div>
        <div class="col s12 m12 l4">
            <div class="card #0d47a1 blue darken-4 hoverable">
                <div class="card-content white-text">
                    <span class="card-title">View Municipal Account</span>
                    <p>View municipal account here.</p>
                </div>
                <div class="card-action">
                    <a href="https://matzikama.cabedocs.com/auth/login" target="_blank">View Account</a>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container">
    <!-- <app-notifications></app-notifications> -->
        <h3 class="center-align">What are you looking for?</h3>
    <div class="row">
        <div class="col  s12 m12 l4">
            <div class="card hoverable">
                <div class="card-image">
                    <img src="/assets/abalone.jpg">
                    <span class="card-title .center-align"><h4>Municipal Leadership</h4></span>
                    <a class="btn-floating halfway-fab waves-effect waves-light #01579b light-blue darken-4" [routerLink]="['/leadership']">
                        <i class="material-icons">double_arrow</i>
                    </a>
                </div>
                <div class="card-content">
                    <p class="truncate">View the municipal leadership structure here.
                    </p>
                </div>
            </div>
        </div>
        <div class="col  s12 m12 l4">
            <div class="card hoverable">
                <div class="card-image">
                    <img src="/assets/abalone.jpg">
                        <span class="card-title center-align"><h4>Tenders</h4></span>
                    <a class="btn-floating halfway-fab waves-effect waves-light #01579b light-blue darken-4" [routerLink]="['/tenders']">
                        <i class="material-icons">double_arrow</i>
                    </a>
                </div>
                <div class="card-content">
                    <p class="truncate">View available tenders and tender history here.
                    </p>
                </div>
            </div>
        </div>
        <div class="col  s12 m12 l4">
            <div class="card hoverable">
                <div class="card-image">
                    <img src="/assets/abalone.jpg">
                        <span class="card-title"><h4 class="center">Documents</h4></span>
                    <a class="btn-floating halfway-fab waves-effect waves-light #01579b light-blue darken-4" [routerLink]="['/documents']">
                        <i class="material-icons">double_arrow</i>
                    </a>
                </div>
                <div class="card-content">
                    <p class="truncate">View municipal documents like the budget,IDP,Annual Report here.
                    </p>
                </div>
            </div>
        </div>
    </div>
    <!-- End of first row -->
    <div class="row">
        <div class="col  s12 m12 l4">
            <div class="card hoverable">
                <div class="card-image">
                    <img src="/assets/abalone.jpg">
                    <span class="card-title"><h4>Work For Us</h4></span>
                    <a class="btn-floating halfway-fab waves-effect waves-light #01579b light-blue darken-4" [routerLink]="['/vacancies']">
                        <i class="material-icons">double_arrow</i>
                    </a>
                </div>
                <div class="card-content">
                    <p class="truncate">View municipal vacancies here.
                    </p>
                </div>
            </div>
        </div>
        <div class="col  s12 m12 l4">
            <div class="card hoverable">
                <div class="card-image">
                    <img src="/assets/abalone.jpg">
                        <span class="card-title"><h4>Procurement</h4></span>
                    <a class="btn-floating halfway-fab waves-effect waves-light #01579b light-blue darken-4" [routerLink]="['/procurement']">
                        <i class="material-icons">double_arrow</i>
                    </a>
                </div>
                <div class="card-content">
                    <p class="truncate">View all tourism related information here.
                    </p>
                </div>
            </div>
        </div>
        <div class="col  s12 m12 l4">
            <div class="card hoverable">
                <div class="card-image">
                    <img src="/assets/abalone.jpg">
                        <span class="card-title"><h4>Investor Relations</h4></span>
                    <a class="btn-floating halfway-fab waves-effect waves-light #01579b light-blue darken-4" [routerLink]="['/investor']">
                        <i class="material-icons">double_arrow</i>
                    </a>
                </div>
                <div class="card-content">
                    <p class="truncate">View investor related information here.
                    </p>
                </div>
            </div>
        </div>
    </div>
    <!-- End of Second row -->
    <div class="row">
        <div class="col  s12 m12 l4">
            <div class="card hoverable">
                <div class="card-image">
                    <img src="/assets/abalone.jpg">
                    <span class="card-title"><h4>Application Forms</h4></span>
                    <a class="btn-floating halfway-fab waves-effect waves-light #01579b light-blue darken-4" [routerLink]="['/forms']">
                            <i class="material-icons">double_arrow</i>
                    </a>
                </div>
                <div class="card-content">
                    <p class="truncate">Find municipal services related application forms here.
                    </p>
                </div>
            </div>
        </div>
        <div class="col  s12 m12 l4">
            <div class="card hoverable">
                <div class="card-image">
                    <img src="/assets/abalone.jpg">
                        <span class="card-title"><h4>IDP Roadshows</h4></span>
                    <a class="btn-floating halfway-fab waves-effect waves-light #01579b light-blue darken-4" [routerLink]="['/publicparticipation']">
                        <i class="material-icons">double_arrow</i>
                    </a>
                </div>
                    <div class="card-content">
                        <p class="truncate">View the municipal IDP roadshows information here.

                        </p>
                    </div>
            </div>
        </div>
        <div class="col  s12 m12 l4">
            <div class="card hoverable">
                <div class="card-image">
                    <img src="/assets/abalone.jpg">
                        <span class="card-title"><h4>FAQ</h4></span>
                    <a class="btn-floating halfway-fab waves-effect waves-light #01579b light-blue darken-4" [routerLink]="['/services']">
                        <i class="material-icons">double_arrow</i>
                    </a>
                </div>
                <div class="card-content">
                    <p class="truncate">
                      Frequently asked questions
                    </p>
                </div>
            </div>
        </div>
    </div>
        <!-- End of Third row -->
 </div> <!-- End of Container -->
<app-footer></app-footer>
<router-outlet></router-outlet>

