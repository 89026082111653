<app-townplanning-nav></app-townplanning-nav>
<div class="container">
    <h5 class="center">TownPlanning Notices</h5>
    <div class="row">
        <div class="col s12 m12 l12">
            <mz-collapsible [mode]="'expandable'" [popout]="true">
                <mz-collapsible-item [active]="true">
                    <mz-collapsible-item-header>
                        <i class="material-icons">keyboard_arrow_down</i>
                        Townplanning Notices 2022-23
                    </mz-collapsible-item-header>
                    <mz-collapsible-item-body>
                        <mz-collection>
                            <mz-collection-item >
                                SECOND AND FINAL NOTICE: PROPOSED DRAFT INTEGRATED ZONING SCHEME BY-LAW
                                <a href="/assets/municipal-notices/2023/2de kennisgewing- MMIZSB soos 1 Maart 2023.pdf" mz-secondary-content download>
                                    {{zoningBylaw2023 | date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                              </mz-collection-item>
                        </mz-collection>
                    </mz-collapsible-item-body>
                </mz-collapsible-item>
            </mz-collapsible>
        </div>
    </div>

    <div class="row">
        <div class="col s12 m12 l12">
            <mz-collapsible [mode]="'expandable'" [popout]="true">
                <mz-collapsible-item [active]="true">
                    <mz-collapsible-item-header>
                        <i class="material-icons">keyboard_arrow_down</i>
                        Townplanning Notices 2020-21
                    </mz-collapsible-item-header>
                    <mz-collapsible-item-body>
                        <mz-collection>
                            <mz-collection-item>
                                PROPOSED REVIEW TO THE SPATIAL DEVELOPMENT FRAMEWORK, 2021/2022 
                                <a href="/assets/townplanning/notices/MAt MUN DubbelS.pdf" mz-secondary-content download>
                                   {{ror2021UploadDate |date : 'MMM, dd yyyy, HH:mm'}}
                                <i class=" material-icons download right" download>cloud_download</i>
                                </a>
                            </mz-collection-item> 
                           <mz-collection-item>
                                Auction: Sale of Properties
                                <a href="/assets/townplanning/notices/K51-2020.pdf" mz-secondary-content download>
                                   {{k51UploadDate |date : 'MMM, dd yyyy, HH:mm'}}
                                <i class=" material-icons download right" download>cloud_download</i>
                                </a>
                             </mz-collection-item> 
                             <!--<mz-collection-item>
                                AMENDMENT OF THE MATZIKAMA MUNICIPALITY LAND USE PLANNING BY-LAW,2015
                                <a href="/assets/townplanning/notices/K14-2020 LUPBL.PDF" mz-secondary-content download>
                                   {{k14UploadDate |date : 'MMM, dd yyyy, HH:mm:ss'}}
                                    <i class=" material-icons download right" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                PROPOSED DRAFT INTEGRATED ZONING SCHEME BY-LAW
                                <a href="/assets/townplanning/notices/K13-2020 IZSBL.PDF" mz-secondary-content download>
                                   {{k13UploadDate |date : 'MMM, dd yyyy, HH:mm:ss'}}
                                    <i class=" material-icons download right" download>cloud_download</i>
                                </a>
                            </mz-collection-item> -->
                        </mz-collection>
                    </mz-collapsible-item-body>
                </mz-collapsible-item>
            </mz-collapsible>
        </div>
    </div>
    <div class="row">
        <div class="col s12 m12 l12">
            <mz-collapsible [mode]="'expandable'" [popout]="true">
                <mz-collapsible-item [active]="true">
                    <mz-collapsible-item-header>
                        <i class="material-icons">keyboard_arrow_down</i>
                        Townplanning Notices 2019-20
                    </mz-collapsible-item-header>
                    <mz-collapsible-item-body>
                        <mz-collection>
                            <mz-collection-item>
                                AMENDMENT OF THE MATZIKAMA MUNICIPALITY LAND USE PLANNING BY-LAW,2015
                                <a href="/assets/townplanning/notices/K14-2020 LUPBL.PDF" mz-secondary-content download>
                                   {{k14UploadDate |date : 'MMM, dd yyyy, HH:mm:ss'}}
                                    <i class=" material-icons download right" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                PROPOSED DRAFT INTEGRATED ZONING SCHEME BY-LAW
                                <a href="/assets/townplanning/notices/K13-2020 IZSBL.PDF" mz-secondary-content download>
                                   {{k13UploadDate |date : 'MMM, dd yyyy, HH:mm:ss'}}
                                    <i class=" material-icons download right" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                        </mz-collection>
                    </mz-collapsible-item-body>
                </mz-collapsible-item>
            </mz-collapsible>
        </div>
    </div>
</div>