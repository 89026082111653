/* import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';



@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
}) */
export class TownplanningModule {

  public townplanningTitle:string;
  public townplanningOwner:string;
  public townplanningApplicant:string;
  public townplanningProperty:string;
  public townplanningExtent:string;
  public townplanningLocality:string;
  public townplanningCurrentZoning:string;
  public townplanningNoticeDoc:string;
 
  
  constructor(title:string,owner:string,applicant:string,property:string,extent:string,locality:string,currentZoning:string,noticeDoc:string){
    this.townplanningTitle=title;
    this.townplanningOwner=owner;
    this.townplanningApplicant=applicant;
    this.townplanningProperty=property;
    this.townplanningExtent=extent;
    this.townplanningLocality=locality;
    this.townplanningCurrentZoning=currentZoning;
    this.townplanningNoticeDoc=noticeDoc;

  }
 }
