import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sector-plans',
  templateUrl: './sector-plans.component.html',
  styleUrls: ['./sector-plans.component.css']
})
export class SectorPlansComponent implements OnInit {

  sectorPlansUploadDate : Date = new Date('2020-03-27 16:45');
  finalSectorPlansUploadDate : Date = new Date('2020-05-03 11:25')
  draftSectorPlansMarch21UploadDate : Date = new Date('2021-03-30 16:24')
  finalSectorPlansMarch21UploadDate : Date = new Date('2021-05-31 12:40')
  finalSectorPlansMay22UploadDate : Date = new Date('2021-06-01 16:13')



  constructor() { }

  ngOnInit() {
  }

}
