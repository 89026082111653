<div class="container">
    <h5 class="center">Emergency Loadshedding Relief Grant Reporting</h5>
    <div class="row">
        <div class="col s12 m12 l12">
            <mz-collapsible [mode]="'expandable'" [popout]="true">
                <mz-collapsible-item [active]="true">
                    <mz-collapsible-item-header>
                        <i class="material-icons">keyboard_arrow_down</i>
                            2023 - 2024 Reports
                    </mz-collapsible-item-header>
                    <mz-collapsible-item-body>
                        <mz-collection>
                            <mz-collection-item >                                
                                Close Out Report
                                <a href="/assets/Loadshedding Grant/2023-24/Close Out Report_WC011_Emergency Municipal Loadshedding Relief Grant_M03_20232024.pdf" mz-secondary-content download>
                                    {{lg032324 | date : 'MMM, dd yyyy, HH:mm: aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item >                                
                                M02 August 2023
                                <a href="/assets/Loadshedding Grant/2023-24/Annexure A_WC011_Emergency Municipal Loadshedding Relief Grant_M02_20232024.pdf" mz-secondary-content download>
                                    {{lg022324 | date : 'MMM, dd yyyy, HH:mm: aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item >                                
                                M01 July 2023
                                <a href="/assets/Loadshedding Grant/2023-24/Annexure A_WC011_Emergency Municipal Loadshedding Relief Grant_M01_20232024.pdf" mz-secondary-content download>
                                    {{lg012324 | date : 'MMM, dd yyyy, HH:mm: aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <!-- <mz-collection-item >
                                M12 June 2023
                                <a href="/assets/Loadshedding Grant/Annexure A_WC011_Emergency Municipal Loadshedding Relief Grant_M12_20222023.pdf" mz-secondary-content download>
                                    {{lgM12 | date : 'MMM, dd yyyy, HH:mm: aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                M11 May 2023
                                <a href="/assets/Loadshedding Grant/Annexure A_WC011_Emergency Municipal Loadshedding Relief Grant_M11_20222023.pdf" mz-secondary-content download>
                                    {{lgM11 | date : 'MMM, dd yyyy, HH:mm: aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                M10 April 2023
                                <a href="/assets/Loadshedding Grant/Annexure A_WC011_Emergency Municipal Loadshedding Relief Grant_M10_20222023.pdf" mz-secondary-content download>
                                    {{lgM10 | date : 'MMM, dd yyyy, HH:mm: aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                M09 March 2023
                                <a href="/assets/Loadshedding Grant/Annexure A_WC011_Emergency Municipal Loadshedding Relief Grant_M09_20222023.pdf" mz-secondary-content download>
                                    {{lgM09 | date : 'MMM, dd yyyy, HH:mm: aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item> -->
                        </mz-collection>
                    </mz-collapsible-item-body>
                </mz-collapsible-item>
            </mz-collapsible>
        </div>
    </div>
    <div class="row">
        <div class="col s12 m12 l12">
            <mz-collapsible [mode]="'expandable'" [popout]="true">
                <mz-collapsible-item [active]="true">
                    <mz-collapsible-item-header>
                        <i class="material-icons">keyboard_arrow_down</i>
                            Monthly Reports
                    </mz-collapsible-item-header>
                    <mz-collapsible-item-body>
                        <mz-collection>
                            <mz-collection-item >                                
                                Close Out Report
                                <a href="/assets/Loadshedding Grant/Close Out Report_WC011_Emergency Municipal Loadshedding Relief Grant_20222023.pdf" mz-secondary-content download>
                                    {{lgReport | date : 'MMM, dd yyyy, HH:mm: aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                M12 June 2023
                                <a href="/assets/Loadshedding Grant/Annexure A_WC011_Emergency Municipal Loadshedding Relief Grant_M12_20222023.pdf" mz-secondary-content download>
                                    {{lgM12 | date : 'MMM, dd yyyy, HH:mm: aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                M11 May 2023
                                <a href="/assets/Loadshedding Grant/Annexure A_WC011_Emergency Municipal Loadshedding Relief Grant_M11_20222023.pdf" mz-secondary-content download>
                                    {{lgM11 | date : 'MMM, dd yyyy, HH:mm: aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                M10 April 2023
                                <a href="/assets/Loadshedding Grant/Annexure A_WC011_Emergency Municipal Loadshedding Relief Grant_M10_20222023.pdf" mz-secondary-content download>
                                    {{lgM10 | date : 'MMM, dd yyyy, HH:mm: aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                M09 March 2023
                                <a href="/assets/Loadshedding Grant/Annexure A_WC011_Emergency Municipal Loadshedding Relief Grant_M09_20222023.pdf" mz-secondary-content download>
                                    {{lgM09 | date : 'MMM, dd yyyy, HH:mm: aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                        </mz-collection>
                    </mz-collapsible-item-body>
                </mz-collapsible-item>
            </mz-collapsible>
        </div>
    </div>
</div> 



