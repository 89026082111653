<app-townplanning-nav></app-townplanning-nav>
<div class="container">
    <h5 class="center">Matzikama Municipality SDF</h5>
    <div class="row">
        <div class="col s12 m12 l12">
            <mz-collapsible [mode]="'expandable'" [popout]="true">
                <mz-collapsible-item [active]="true">
                    <mz-collapsible-item-header>
                        <i class="material-icons">keyboard_arrow_down</i>
                            Matzikama Municipality SDF
                    </mz-collapsible-item-header>
                    <mz-collapsible-item-body>
                        <mz-collection>
                            <mz-collection-item >
                                Ratify Concept SDF March 2023 
                                <a href="/assets/townplanning/sdf/Ratify Concept SDF 2023 24 March 29 2023 (final).pdf" mz-secondary-content download>
                                    {{sdfMarch2023UploadDate |date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                Matzikama Municipality SDF 2021-22
                                <a href="/assets/townplanning/sdf/Concept Ratify SDF 2021 22 final concept May 2021.pdf" mz-secondary-content download>
                                    {{sdfMay2021UploadDate |date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                Approved Amendment to the Matzikama SDF 2020-21
                                <a href="/assets/townplanning/sdf/Approved amended to the Matzikama SDF 2020-21.pdf" mz-secondary-content download>
                                    {{sdfUploadDate |date : 'MMM, dd yyyy, HH:mm:ss'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                Amendment to the Matzikama SDF 2019 - May 2019 
                                <a href="/assets/townplanning/sdf/Approved Amendment to the Matzikama SDF 2019 - May 2019.pdf" mz-secondary-content download>
                                    {{websiteLiveDate |date : 'MMM, dd yyyy'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                Amendment to the Matzikama SDF 2018 - May 2018 
                                <a href="/assets/StrategicDocs/sdf/Amendment to the Matzikama SDF 2018 - May 2018 - SML.pdf" mz-secondary-content download>
                                    {{websiteLiveDate |date : 'MMM, dd yyyy'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                Matzikama Municipality Spatial Development Framework 2014
                                <a href="/assets/townplanning/sdf/MATZ SDF 2014 complete doc.PDF" mz-secondary-content download>
                                    {{websiteLiveDate |date : 'MMM, dd yyyy'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                Matzikama Municipality Spatial Development Framework Status Quo Report
                                <a href="/assets/townplanning/sdf/MATZ SPATIAL STATUS QUO COMPLETE DOC.PDF" mz-secondary-content download>
                                    {{websiteLiveDate |date : 'MMM, dd yyyy'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                Matzikama Municipality Draft Spatial Development Framework
                                <a href="/assets/townplanning/sdf/Matzikama Draft Spatial Devt Framework - June 2014.pdf" mz-secondary-content download>
                                    {{websiteLiveDate |date : 'MMM, dd yyyy'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                           <!--  <mz-collection-item>
                                APPLICATION FOR DEPARTURE:Mr MS Mranqu
                                <a href="/assets/townplanning/applications/erf2519.pdf" mz-secondary-content download>
                                   {{erf2519UploadDate |date : 'MMM, dd yyyy, HH:mm:ss'}}
                                    <i class=" material-icons download right" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                APPLICATION FOR DEPARTURE, CONSOLIDATION, CONSENT USE & AMENDMENT OF RESTRICTIVE CONDITIONS
                                <a href="/assets/townplanning/applications/bluedot.pdf" mz-secondary-content download>
                                   {{bluedotUploadDate |date : 'MMM, dd yyyy, HH:mm:ss'}}
                                    <i class=" material-icons download right" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                APPLICATION FOR REZONING OF PORTION 2 OF THE FARM VADERLANDSCHE RIETKUIL NO 308
                                <a href="/assets/townplanning/applications/rietkuil.pdf" mz-secondary-content download>
                                   {{rietkuilUploadDate |date : 'MMM, dd yyyy, HH:mm:ss'}}
                                    <i class=" material-icons download right" download>cloud_download</i>
                                </a>
                            </mz-collection-item> -->
                        </mz-collection>
                    </mz-collapsible-item-body>
                </mz-collapsible-item>
            </mz-collapsible>
        </div>
    </div>
</div> 




 



