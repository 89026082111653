
   <div class="container hide-on-med-and-down">
    <div class="row">
        <div class="col s3 mayor">
           <app-lead-side-bar></app-lead-side-bar>
        </div>
        <div class="col s9">
           <h4 class="center">Financial Services</h4>
           <hr>
           <div class="col s12">
                <div class="col s5">
                     <img src="/assets/Council/Matz Mun_038 w.JPG" alt="Matzikama CFO" class="responsive-img">
                     <span class="flow-text center">Mr. E. Alfred</span>
                </div>
                <div class="col s7">
                    <span class="black-text">
                        <p class="leadtext right" align='justify'>
                                <b>The Director Financial Services is responsible to:</b> <br>
                                 1. Manage and control the implementation of budget policies, systems and
                                     procedures and financial statement processes, assets and financial management
                                     information system and internal control procedures to ensure legislative
                                     compliance and sound financial management practices<br>
                                 2. Implement and maintain revenue and credit control policies and procedures
                                     to ensure sound revenue management practices and compliance<br>
                                 3. Manage expenditure to ensure proper execution of policies, systems,
                                     procedures, transactions and compliance<br>
                                4. Render supply chain management services to ensure proper systems, policies,
                                     procedures and control for demand, acquisition, logistics, assets and disposal
                                     management<br>
                                5. Provide effective and efficient information and communication technology
                                     services<br>
                                6. Render management and line function executive support services to the
                                     directorate<br>
                                <b>Contact Information</b><br>
                                    Tel: +27 (0) 27 201 3304<br>
                                    E-mail:<a href="mailto:cfo@matzikama.gov.za">cfo@matzikama.gov.za</a><br>
                        </p>
                    </span>
                </div>
            </div>
        </div>
    </div>
</div> 
<!-- Mobile View -->
<div class="container hide-on-large-only">
    <div class="row">
        <h4 class="center">Financial Services</h4>
    </div>
    <div class="row">
        <div class="col s12 m12 l4"></div>
        <img src="/assets/Council/Matz Mun_038 w.JPG" alt="Matzikama CFO" class="responsive-img">
        <span class="flow-text center">Mr. E. Alfred</span>
        <span class="black-text">
            <p class="leadtext right" align='justify'>
                    <b>The Director Financial Services is responsible to:</b> <br>
                     1. Manage and control the implementation of budget policies, systems and
                         procedures and financial statement processes, assets and financial management
                         information system and internal control procedures to ensure legislative
                         compliance and sound financial management practices<br>
                     2. Implement and maintain revenue and credit control policies and procedures
                         to ensure sound revenue management practices and compliance<br>
                     3. Manage expenditure to ensure proper execution of policies, systems,
                         procedures, transactions and compliance<br>
                    4. Render supply chain management services to ensure proper systems, policies,
                         procedures and control for demand, acquisition, logistics, assets and disposal
                         management<br>
                    5. Provide effective and efficient information and communication technology
                         services<br>
                    6. Render management and line function executive support services to the
                         directorate<br>
                    <b>Contact Information</b><br>
                        Tel: +27 (0) 27 201 3304<br>
                        E-mail:<a href="mailto:cfo@matzikama.gov.za">cfo@matzikama.gov.za</a><br>
            </p>
        </span>
    </div>
</div> 
<app-footer></app-footer>






