/* import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';



@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
}) */
export class PhotoModule {

  public photoUrl: string;

  constructor( photoUrl:string){
    this.photoUrl=photoUrl;
  }
 }

