<h4 class="center">Investor Relations</h4>
<div class="container">
<div class="row">
    <div class="col s12 m12 l12">
         
        <mz-collapsible
            [mode]="'expandable'"
            [popout]="true">
            
            <mz-collapsible-item [active]="true">
                <mz-collapsible-item-header>
                         <i class="material-icons">keyboard_arrow_down</i>
                            Matzikama Municipality 2018-19 Audited Financial Statements & Auditor General Report                    
                </mz-collapsible-item-header>
                <mz-collapsible-item-body>
                    <mz-collection>
                        <mz-collection-item >
                            Matzikama Municipality 2018-19 Audited Financial Statements & Auditor General Report 
                                <a href="/assets/AFS/Copy of WC011 Financial Statements 2018-19 - 7 December  2019.pdf" mz-secondary-content download>
                                    {{websiteLiveDate | date: 'MMM, dd yyyy'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>     
                    </mz-collection>
                </mz-collapsible-item-body>
            </mz-collapsible-item>
            <!-- End of Investor Collapsible & Collection -->   
        </mz-collapsible>
    </div>
</div>


