<h4 class="center">Request For Proposals</h4>
<div class="container">
<div class="row">
    <div class="col s12 m12 l12">
        <mz-collapsible
        [mode]="'expandable'"
        [popout]="true">
    <mz-collapsible-item [active]="true">
        <mz-collapsible-item-header>
                 <i class="material-icons">keyboard_arrow_down</i>
                 RFP01-2024-2025 REQUEST FOR PROPOSALS (RFP) FOR THE APPOINTMENT OF SERVICE PROVIDERS 
                TO MANAGE MUNICIPAL FACILITIES IN STRANDFONTEIN CHALETS AND CAMPING SITES AND VREDENDAL 
                CARAVAN PARK AND SWIMMING POOLS                
        </mz-collapsible-item-header>
        <mz-collapsible-item-body>
            <mz-collection>
                <mz-collection-item >
                    RFP01-2024-2025 REQUEST FOR PROPOSALS (RFP) FOR THE APPOINTMENT OF SERVICE PROVIDERS 
                    TO MANAGE MUNICIPAL FACILITIES IN STRANDFONTEIN CHALETS AND CAMPING SITES AND VREDENDAL 
                    CARAVAN PARK AND SWIMMING POOLS 
                        <a href="/assets/Tenders/2024-25/RFP01-2024-2025.pdf" mz-secondary-content download>
                            {{rfp012425 | date : 'MMM, dd yyyy, HH:mm:aaa'}}
                            <i class=" material-icons download rigth" download>cloud_download</i></a>
                </mz-collection-item>      
            </mz-collection>
        </mz-collapsible-item-body>
    </mz-collapsible-item>
    </mz-collapsible>
    </div>
</div>
<div class="row">
    <div class="col s12 m12 l12">
        <mz-collapsible
            [mode]="'expandable'"
            [popout]="true">
        <mz-collapsible-item [active]="true">
            <mz-collapsible-item-header>
                     <i class="material-icons">keyboard_arrow_down</i>
                     MATZIKAMA MUNICIPALITY AFFORDABLE HOUSING DEVELOPMENT PROJECT                 
            </mz-collapsible-item-header>
            <mz-collapsible-item-body>
                <mz-collection>
                    <mz-collection-item >
                        MATZIKAMA MUNICIPALITY AFFORDABLE HOUSING DEVELOPMENT PROJECT   
                            <a href="/assets/rfp/Affordable Housing.pdf" mz-secondary-content download>
                                {{rfpHousing | date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                <i class=" material-icons download rigth" download>cloud_download</i></a>
                    </mz-collection-item>      
                </mz-collection>
            </mz-collapsible-item-body>
        </mz-collapsible-item>
        <!-- End of AFFORDABLE HOUSING DEVELOPMENT PROJECT Collapsible & Collection -->   
        </mz-collapsible>
        <mz-collapsible
            [mode]="'expandable'"
            [popout]="true">
            
            <mz-collapsible-item [active]="true">
                <mz-collapsible-item-header>
                        <i class="material-icons">keyboard_arrow_down</i>
                            PROPOSAL FOR MANAGING THE MATZIKAMA BUSINESS DEVELOPMENT CENTRE                  
                </mz-collapsible-item-header>
                <mz-collapsible-item-body>
                    <mz-collection>
                        <mz-collection-item >
                            PROPOSAL FOR MANAGING THE MATZIKAMA BUSINESS DEVELOPMENT CENTRE  
                                <a href="/assets/rfp/Request for proposals - Advert revised 24 08 2020.pdf" mz-secondary-content download>
                                    {{rfpBusCentre | date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>      
                    </mz-collection>
                </mz-collapsible-item-body>
            </mz-collapsible-item>
            <!-- End of RFP Collapsible & Collection -->   
        </mz-collapsible>
    </div>
</div>
</div>
