import { Component, OnInit, Injectable } from '@angular/core';
import {Router} from '@angular/router';
import * as M from '../../../../node_modules/materialize-css';



@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})


export class NavbarComponent implements OnInit {
options={
  "outDuration":"100"
}
  constructor(private router:Router){ }


  ngOnInit() {

    var elems = document.querySelectorAll('.sidenav');
    var instances = M.Sidenav.init(elems, this.options);
  }
  covid19Redirect(){

    // this.router.navigateByUrl('../../Covid-19 Matzikama Website/index.html')

    // this.router.navigate(["/"]).then(result =>{window.location.href='../../Covid-19 Matzikama Website/index.html'})
    // this.router.navigate(["/"]).then(result =>{window.location.href='../../Covid-19 Matzikama Website/index.html'})
  }
}
