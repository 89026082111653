import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule} from '@angular/forms';
import { NgModule, Renderer2 } from '@angular/core';
import { Ng2SearchPipeModule} from 'ng2-search-filter';
import { YouTubePlayerModule} from '@angular/youtube-player'
//import { Ng2SearchPipeModule } from 'ng2-search-filter';

import { MzButtonModule, MzInputModule,MzCollapsibleModule,MzCollectionModule,MzSidenavModule,MzTabModule } from 'ngx-materialize';
import { ToastrModule } from 'ngx-toastr';
import { NgxTwitterTimelineModule } from 'ngx-twitter-timeline';
import { NgMarqueeModule } from 'ng-marquee';






import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { FooterComponent } from './components/footer/footer.component';
import { SliderComponent } from './components/slider/slider.component';
import { ContactComponent } from './components/contact/contact.component';
import { HomeComponent } from './components/home/home.component';
import { DocumentsComponent } from './components/documents/documents.component';
import { GalleryComponent } from './components/gallery/gallery.component';
import { SearchComponent } from './components/home/search/search.component';
import { BioComponent } from './components/home/bio/bio.component';
import { NotificationsComponent } from './components/home/notifications/notifications.component';
import { LeadershipComponent } from './components/leadership/leadership.component';
import { TendersComponent } from './components/documents/tenders/tenders.component';
import { LedComponent } from './components/documents/led/led.component';
import { InvestorComponent } from './components/documents/investor/investor.component';
import { PublicparticipationComponent } from './components/documents/publicparticipation/publicparticipation.component';
import { VacanciesComponent } from './components/documents/vacancies/vacancies.component';
import { StratdocsComponent } from './components/documents/stratdocs/stratdocs.component';
import { MayorComponent } from './components/leadership/mayor/mayor.component';
import { SpeakerComponent } from './components/leadership/speaker/speaker.component';
import { MaycoComponent } from './components/leadership/mayco/mayco.component';
import { LeadershipHeadComponent } from './components/leadership/leadership-head/leadership-head.component';
import { CouncillorsComponent } from './components/leadership/councillors/councillors.component';
import { LeadSideBarComponent } from './components/leadership/lead-side-bar/lead-side-bar.component';
import { DeputyComponent } from './components/leadership/deputy/deputy.component';
import { MmComponent } from './components/leadership/mm/mm.component';
import { CfoComponent } from './components/leadership/cfo/cfo.component';
import { CorporateComponent } from './components/leadership/corporate/corporate.component';
import { CommunityComponent } from './components/leadership/community/community.component';
import { InfrastructureComponent } from './components/leadership/infrastructure/infrastructure.component';
import { UnderconstructComponent } from './components/underconstruct/underconstruct.component';
import { FinanceComponent } from './components/documents/finance/finance.component';
import { FinpolComponent } from './components/documents/finpol/finpol.component';
import { MidyearComponent } from './components/documents/midyear/midyear.component';
import { CouncilComponent } from './components/documents/council/council.component';
import { TownplanningComponent } from './components/documents/townplanning/townplanning.component';
import { TendersAwardedComponent } from './components/documents/tenders/tenders-awarded/tenders-awarded.component';
import { TendersOpenComponent } from './components/documents/tenders/tenders-open/tenders-open.component';
import { TendersInProgressComponent } from './components/documents/tenders/tenders-in-progress/tenders-in-progress.component';
import { FloatingButtonComponent } from './components/floating-button/floating-button.component';
import { BuildingControlComponent } from './components/documents/building-control/building-control.component';
import { TourismComponent } from './components/documents/tourism/tourism.component';
import { ServicesComponent } from './components/services/services.component';
import { FormsComponent } from './components/forms/forms.component';
import { SarsComponent } from './components/sars/sars.component';
import { ValuationComponent } from './components/documents/valuation/valuation.component';
import { DatePipe } from '@angular/common';
import { ProcurementComponent } from './components/procurement/procurement.component';
import { ContractsComponent } from './components/documents/contracts/contracts.component';
import { SectorPlansComponent } from './components/documents/sector-plans/sector-plans.component';
import { AppealsComponent } from './components/documents/townplanning/appeals/appeals.component';
import { ApplicationsComponent } from './components/documents/townplanning/applications/applications.component';
import { BylawsComponent } from './components/documents/townplanning/bylaws/bylaws.component';
import { DecisionsComponent } from './components/documents/townplanning/decisions/decisions.component';
import { NoticesComponent } from './components/documents/townplanning/notices/notices.component';
import { SpatialPlansComponent } from './components/documents/townplanning/spatial-plans/spatial-plans.component';
import { TownPlanningContactComponent } from './components/documents/townplanning/town-planning-contact/town-planning-contact.component';
import { TownPlansComponent } from './components/documents/townplanning/town-plans/town-plans.component';
import { TribunalComponent } from './components/documents/townplanning/tribunal/tribunal.component';
import { Covid19Component } from './components/covid19/covid19.component';
import { TownplanningNavComponent } from './components/documents/townplanning/townplanning-nav/townplanning-nav.component';
import { SdfComponent } from './components/documents/townplanning/sdf/sdf.component';
import { CovidNavbarComponent } from './components/covid19/covid-navbar/covid-navbar.component';
import { CovidAboutComponent } from './components/covid19/covid-about/covid-about.component';
import { CovidContactComponent } from './components/covid19/covid-contact/covid-contact.component';
import { PerformanceComponent } from './components/documents/performance/performance.component';
import { TenderService } from './Modules/tenders/tender-service';
import { RfpComponent } from './components/documents/tenders/rfp/rfp.component';
import { ViewEventComponent } from './components/gallery/view-event/view-event.component';
import { CoastalCleanupComponent } from './components/gallery/coastal-cleanup/coastal-cleanup.component';
import { MunicipalNoticesComponent } from './components/documents/municipal-notices/municipal-notices.component';
import { SafePipe } from './components/documents/publicparticipation/safe.pipe';
import { MediaStatementsComponent } from './components/media-statements/media-statements.component';
import { WardCommitteeComponent } from './components/documents/ward-committee/ward-committee.component';
import { PublicationsComponent } from './components/publications/publications.component';


// import {MatTableModule} from '@angular/material/table';
import { GrantLoadsheddingComponent } from './components/grant-loadshedding/grant-loadshedding.component';
import { AuctionComponent } from './components/auction/auction.component';
import { GovtTendersComponent } from './components/documents/tenders/govt-tenders/govt-tenders.component';
import { TendersCancelledComponent } from './components/documents/tenders/tenders-cancelled/tenders-cancelled.component';
import { ArchivedTendersComponent } from './components/documents/tenders/archived-tenders/archived-tenders.component';



@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    SliderComponent,
    ContactComponent,
    HomeComponent,
    DocumentsComponent,
    GalleryComponent,
    SearchComponent,
    BioComponent,
    NotificationsComponent,
    LeadershipComponent,
    TendersComponent,
    LedComponent,
    InvestorComponent,
    PublicparticipationComponent,
    VacanciesComponent,
    StratdocsComponent,
    MayorComponent,
    SpeakerComponent,
    MaycoComponent,
    LeadershipHeadComponent,
    CouncillorsComponent,
    LeadSideBarComponent,
    DeputyComponent,
    MmComponent,
    CfoComponent,
    CorporateComponent,
    CommunityComponent,
    InfrastructureComponent,
    UnderconstructComponent,
    FinanceComponent,
    FinpolComponent,
    MidyearComponent,
    CouncilComponent,
    TownplanningComponent,
    TendersAwardedComponent,
    TendersOpenComponent,
    TendersInProgressComponent,
    FloatingButtonComponent,
    BuildingControlComponent,
    TourismComponent,
    ServicesComponent,
    FormsComponent,
    SarsComponent,
    ValuationComponent,
    ProcurementComponent,
    ContractsComponent,
    SectorPlansComponent,
    AppealsComponent,
    ApplicationsComponent,
    BylawsComponent,
    DecisionsComponent,
    NoticesComponent,
    SpatialPlansComponent,
    TownPlanningContactComponent,
    TownPlansComponent,
    TribunalComponent,
    Covid19Component,
    TownplanningNavComponent,
    SdfComponent,
    CovidNavbarComponent,
    CovidAboutComponent,
    CovidContactComponent,
    PerformanceComponent,
    RfpComponent,
    ViewEventComponent,
    CoastalCleanupComponent,
    MunicipalNoticesComponent,
    SafePipe,
    MediaStatementsComponent,
    WardCommitteeComponent,
    PublicationsComponent,
    GrantLoadsheddingComponent,
    AuctionComponent,
    GovtTendersComponent,
    TendersCancelledComponent,
    ArchivedTendersComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    YouTubePlayerModule,
    MzButtonModule,MzCollapsibleModule,MzInputModule,MzCollectionModule,MzSidenavModule,MzTabModule,
    Ng2SearchPipeModule,
    ToastrModule.forRoot(),
    NgxTwitterTimelineModule,
    NgMarqueeModule,
    // MatTableModule


  ],
  providers: [DatePipe,TenderService,YouTubePlayerModule],
  bootstrap: [AppComponent]
})
export class AppModule { }
