import { Component, OnInit } from '@angular/core';
import {MzCollapsibleModule } from 'ngx-materialize';



import{TendersModule} from "../../../../Modules/tenders/tenders.module";
import { TenderService } from 'src/app/Modules/tenders/tender-service';

@Component({
  selector: 'app-tenders-awarded',
  templateUrl: './tenders-awarded.component.html',
  styleUrls: ['./tenders-awarded.component.css']
})
export class TendersAwardedComponent implements OnInit {
  awardedTenders:TendersModule[];

  constructor(private tenderService:TenderService) { 
    this.awardedTenders = this.tenderService.getAllTenders();
   /*  this.awardedTenders=[
      new TendersModule(
        "SUPPLY OF SPEED LAW ENFORCEMENT EQUIPMENT AND PROVIDING OF BACK UP OFFICE FOR COLLECTION OF TRAFFIC AND MUNICIPAL BY LAW FINES.", 
        "T11/2020",
        "2020-04-09",
        "15:23",
        "2020-05-18",
        "No later than 12:00",
        `N/A`,
        `SUPPLY OF SPEED LAW ENFORCEMENT EQUIPMENT AND PROVIDING OF BACK UP OFFICE FOR COLLECTION OF TRAFFIC AND MUNICIPAL BY LAW FINES.`,
        "/assets/Tenders/T11-2020 web advert.pdf",
        "N/A",
        "N/A",
        "N/A",
        ""    
      ),
      new TendersModule(
        "APPOINTMENT OF A CONSULTANT TO HANDLE AND EVALUATE THE MUNICIPAL MANAGER POSITION", 
        "FQ18/2020",
        "2020-03-31",
        "15:45",
        "2020-05-08",
        "No later than 12:00",
        `N/A`,
        `APPOINTMENT OF A CONSULTANT TO HANDLE AND EVALUATE THE MUNICIPAL MANAGER POSITION`,
        "/assets/Tenders/FQ18-2020.pdf",
        "Roy Steele and Associates",
        "4",
        "R 36 000.00",
        ""      
      ),
      new TendersModule(
        "PROVISION OF SECURITY SERVICES FOR MATZIKAMA MUNICIPALITY", 
        "T10/2020",
        "2020-03-26",
        "11:14",
        "2020-05-27",
        "No later than 12:00",
        `N/A`,
        `PROVISION OF SECURITY SERVICES FOR MATZIKAMA MUNICIPALITY`,
        "/assets/Tenders/T10-2020 SECURITY.pdf",
        "N/A",
        "N/A",
        "N/A",
        ""      
      ),
      new TendersModule(
        "CIVIL WORKS FOR THE UPGRADING OF VREDENDAL SEWAGE PUMP STATION:PHASE1(RE-ADVERTISED)", 
       "FQ17/2020",
       "2020-03-19",
       "12:30",
       "2020-03-26",
       "No later than 12:00",
       `N/A`,
       `CIVIL WORKS FOR THE UPGRADING OF VREDENDAL SEWAGE PUMP STATION:PHASE1
       (RE-ADVERTISED)`,
       "/assets/Tenders/FQ17-2020.pdf",
       "PR Civils",
       "1",
       "R199 406.55",
       ""      
     ),
      new TendersModule(
        "SUPPLY,DELIVERY AND INSTALLATION OF FENCING MATERIAL FOR THE UPGRADING OF VREDENDAL PUMP STATION:PHASE1(RE-ADVERTISED)", 
       "FQ16/2020",
       "2020-03-19",
       "12:27",
       "2020-03-26",
       "No later than 12:00",
       `N/A`,
       `SUPPLY,DELIVERY AND INSTALLATION OF FENCING MATERIAL FOR THE UPGRADING OF VREDENDAL PUMP STATION:PHASE1
       (RE-ADVERTISED)`,
       "/assets/Tenders/FQ16-2020.pdf",
       "PR Civils",
       "1",
       "R173 305.00",
       ""      
     ),
      new TendersModule(
        "ALTERATIONS TO KOELBLY PUMP STATION BUILDING FOR THE UPGRADING OF VREDENDAL SEWAGE PUMP STATION:PHASE1(RE-ADVERTISED)",
       "FQ15/2020",
       "2020-03-19",
       "12:26",
       "2020-03-26",
       "No later than 12:00",
       `N/A`,
       `ALTERATIONS TO KOELBLY PUMP STATION BUILDING FOR THE UPGRADING OF VREDENDAL SEWAGE PUMP STATION:PHASE1
       (RE-ADVERTISED)`,
       "/assets/Tenders/FQ15-2020.pdf",
       "N/A",
       "N/A",
       "N/A",
       ""      
     ),
      new TendersModule(
        "ACCOUNTING SUPPORT SERVICES FOR ANNUAL FINANCIAL STATEMENTS AND UNBUNDLING OF INFRASTRUCTURE ASSETS",
       "T07/2020",
       "2020-03-12",
       "13:44",
       "2020-05-08",
       "No later than 12:00",
       `N/A`,
       `ACCOUNTING SUPPORT SERVICES FOR ANNUAL FINANCIAL STATEMENTS AND UNBUNDLING OF INFRASTRUCTURE ASSETS`,
       "/assets/Tenders/T07-2020.pdf",
       "Mubesko Africa",
       "0",
       "R414000",
       ""      
     ),
      new TendersModule(
        "BANKING SERVICES",
        "T05/2020",
        "2020-02-24",
        "15:59",
        "2020-05-29",
        "No later than 12:00",
        `N/A`,
        `BANKING SERVICES`,
        "/assets/Tenders/T05-2020.pdf",
        "N/A",
        "N/A",
        "N/A",
        ""      
      ),
      new TendersModule(
       "CIVIL WORKS FOR THE UPGRADING OF VREDENDAL SEWAGE PUMP STATION:PHASE1(NOT AWARDED. RE-ADVERTISED)",
       "FQ14/2020",
       "2020-03-09",
       "11:53",
       "2020-03-16",
       "No later than 12:00",
       `N/A`,
       `CIVIL WORKS FOR THE UPGRADING OF VREDENDAL SEWAGE PUMP STATION:PHASE1`,
       "/assets/Tenders/FQ14-2020.pdf",
       "(NOT AWARDED. RE-ADVERTISED)",
       "(NOT AWARDED. RE-ADVERTISED)",
       "(NOT AWARDED. RE-ADVERTISED)",
       ""       
     ),
      new TendersModule(
       "SUPPLY,DELIVERY AND INSTALLATION OF FENCING MATERIAL FOR THE UPGRADING OF VREDENDAL PUMP STATION:PHASE1(NOT AWARDED. RE-ADVERTISED)",
       "FQ13/2020",
       "2020-03-09",
       "11:56",
       "2020-03-16",
       "No later than 12:00",
       `N/A`,
       `SUPPLY,DELIVERY AND INSTALLATION OF FENCING MATERIAL FOR THE UPGRADING OF VREDENDAL PUMP STATION:PHASE1`,
       "/assets/Tenders/FQ13-2020.pdf",
       "(NOT AWARDED. RE-ADVERTISED)",
       "(NOT AWARDED. RE-ADVERTISED)",
       "(NOT AWARDED. RE-ADVERTISED)",
       ""      
     ),
      new TendersModule(
       "ALTERATIONS TO KOELBLY PUMP STATION BUILDING FOR THE UPGRADING OF VREDENDAL SEWAGE PUMP STATION:PHASE1(NOT AWARDED. RE-ADVERTISED)",
       "FQ11/2020",
       "2020-03-09",
       "11:59",
       "2020-03-19",
       "No later than 12:00",
       `N/A`,
       `ALTERATIONS TO KOELBLY PUMP STATION BUILDING FOR THE UPGRADING OF VREDENDAL SEWAGE PUMP STATION:PHASE1`,
       "/assets/Tenders/FQ11-2020.pdf",
       "NOT AWARDED. RE-ADVERTISED",
       "NOT AWARDED. RE-ADVERTISED",
       "NOT AWARDED. RE-ADVERTISED",
       ""      
     ),
      new TendersModule(
        "APPOINTMENT OF AN AUCTIONEER FOR SALE OF MUNICIPAL PROPERTY", 
       "T04/2020",
       "2020-02-20",
       "15:42",
       "2020-02-20",
       "No later than 12:00",
       `N/A`,
       `APPOINTMENT OF AN AUCTIONEER FOR SALE OF MUNICIPAL PROPERTY`,
       "/assets/Tenders/T04-2020.pdf",
       "N/A",
       "N/A",
       "N/A",
       ""      
     ),
      
       new TendersModule(
       "APPOINTMENT OF A CONSULTANT FOR THE COMPILATION OF MSCOA BUSINESS PROCESS PROCEDURES", 
       "FQ12/2020",
       "2020-02-19",
       "14:07",
       "2020-02-26",
       "No later than 12:00",
       `N/A`,
       `APPOINTMENT OF A CONSULTANT FOR THE COMPILATION OF MSCOA BUSINESS PROCESS PROCEDURES`,
       "/assets/Tenders/FQ12-2020.pdf",
       "Gerald Seas",
       "0",
       "R165 000",
       ""    
     ),
     new TendersModule(
       "MUNICIPAL PROPERTIES FOR SALE", 
       "T01/2020",
       "2020-02-14",
       "14:28",
       "2020-03-06",
       "No later than 12:00",
       `N/A`,
       `MUNICIPAL PROPERTIES FOR SALE`,
       "/assets/Tenders/TENDER AWARDS OF T01-2020.pdf",
       "See Latest Documents section on home page for full list of awards made.",
       "N/A",
       "N/A",
       ""      
     ),
     new TendersModule(
       "RENTAL OF MUNICIPAL AGRICULTURAL IRRIGATION WATER FROM THE LORWUA SCHEME", 
       "T03/2020",
       "2020-02-14",
       "13:49",
       "2020-02-28",
       "No later than 12:00",
       `N/A`,
       `RENTAL OF MUNICIPAL AGRICULTURAL IRRIGATION WATER FROM THE LORWUA SCHEME`,
       "/assets/Tenders/T03-2020.pdf",
       "N/A",
       "N/A",
       "N/A",
       ""      
     ),
     new TendersModule(
       "APPOINTMENT OF A CONSULTANT TO HANDLE AND EVALUATE THE MUNICIPAL MANAGER POSITION",
       "FQ10/2020",
       "2020-02-14",
       "13:44",
       "2020-02-21",
       "No later than 12:00",
       `N/A`,
       `APPOINTMENT OF A CONSULTANT TO HANDLE AND EVALUATE THE MUNICIPAL MANAGER POSITION`,
       "/assets/Tenders/FQ10-2020.pdf",
       "N/A",
       "N/A",
       "N/A",
       ""  
     ), new TendersModule(
      "SUPPLY AND DELIVERY OF PINE TRANSMISSION POLES(160/180)(RE-ADVERTISED)",
      "FQ08/2020",
      "2020-02-06",
      "11:26",
      "2020-02-13",
      "12:00",
      `N/A`,
      `SUPPLY AND DELIVERY OF PINE TRANSMISSION POLES(160/180)`,
      "/assets/Tenders/FQ02-2020.pdf",
      "Kaap Agri",
      "3",
      "R 70 242,00",
      ""    
    ), 
    
    new TendersModule(
      "SUPPLY AND DELIVERY OF TWO RIDE ON LAWNMOWERS TO VREDENDAL (RE-ADVERTISED)",
      "FQ09/2020",
      "2020-02-06",
      "11:15",
      "2020-02-13",
      "12:00",
      `N/A`,
      `SUPPLY AND DELIVERY OF TWO RIDE ON LAWNMOWERS TO VREDENDAL`,
      "/assets/Tenders/FQ09-2020.pdf",
      "BEE Turf Suppliers",
      "2",
      "R 62 532,00",
      ""    
    ), new TendersModule(
      "APPOINTMENT OF A SERVICE PROVIDER FOR THE IMPLEMENTATION OF ELECTRICITY RATES(RE-ADVERTISED)",
      "FQ6/2020",
      "2020-02-06",
      "11:36",
      "2020-02-13",
      "12:00",
      `N/A`,
      `APPOINTMENT OF A SERVICE PROVIDER FOR THE IMPLEMENTATION OF ELECTRICITY RATES(RE-ADVERTISED)`,
      "/assets/Tenders/FQ06-2020.pdf",
      "Electrical Engery Management Systems",
      "0",
      "R 145 175",
      ""      
    ),
    new TendersModule(
      "SUPPLY AND DELIVERY OF HI-FOAM MATRESSES TO VREDENDAL (RE-ADVERTISED)",
      "FQ07/2020",
      "2020-02-06",
      "11:09",
      "2020-02-13",
      "12:00",
      `N/A`,
      `SUPPLY AND DELIVERY OF HI-FOAM MATRESSES TO VREDENDAL`,
      "/assets/Tenders/FQ07-2020.pdf",
      "Tops Meubels",
      "4",
      "R 86 160",
      ""    
    ),   new TendersModule(
      "LABOUR COSTS FOR UPGRADING OF MIRIAM OWIES COMMUNITY HALL AT DORING BAY",
      "FQ03/2020",
      "2020-01-31",
      "11:41",
      "2020-01-29",
      "12:00",
      `At Miriam Owies Community Hall in Doring Bay on 24 January 2020 at 11:00`,
      `LABOUR COSTS FOR UPGRADING OF MIRIAM OWIES COMMUNITY HALL AT DORING BAY`,
      "/assets/Tenders/FQ03-2020.pdf",
      "APA Supplies",
      "1",
      "R 70 850",
      ""    
    ),
     new TendersModule(
       "SUPPLY OF SPEED LAW ENFORCEMENT EQUIPMENT AND PROVIDING OF BACK UP OFFICE FOR COLLECTION OF TRAFFIC AND MUNICIPAL BY LAW FINES.",
       "T02/2019",
       "2020-02-12",
       "10:57",
       "2020-03-12",
       "No later than 12:00",
       `N/A`,
       `SUPPLY OF SPEED LAW ENFORCEMENT EQUIPMENT AND PROVIDING OF BACK UP OFFICE FOR COLLECTION OF TRAFFIC AND MUNICIPAL BY LAW FINES.`,
       "/assets/Tenders/T02-2020 WEB ADVERT.pdf",
       "N/A",
       "N/A",
       "N/A",
       ""      
     ),
     
     new TendersModule(
       "RE-ADVERTISEMENT OF UPGRADING OF VREDENDAL SOUTH WWTW: MECHANICAL AND ELECTRICAL WORKS", 
       "T34/2019",
       "2020-01-28",
       "11:47",
       "2020-02-14",
       "12:00",
       `04 February 2020 at 10h00 Matzikama Municipal Technical Offices Boardroom, 
       1 Sirkel Street, Vredendal South Industrial Area, Vredendal,8160`,
       `RE-ADVERTISEMENT OF UPGRADING OF VREDENDAL SOUTH WWTW: MECHANICAL AND ELECTRICAL WORKS`,
       "/assets/Tenders/T34-2019.pdf",
       "Hidro-Tech Systems",
       "2",
       "R5 278 608.45",
       ""    
     ),
     new TendersModule(
       "RE-ADVERTISEMENT OF NEW WATERBORNE SEWER SYSTEM AND ASSOCIATED INFRASTRUCTURE IN KLIPRAND", 
       "T36/2019",
       "2020-01-28",
       "11:59",
       "2020-02-14",
       "12:00",
       `05 February 2020 at 11h00 at the Community Hall Kliprand`,
       `RE-ADVERTISEMENT OF NEW WATERBORNE SEWER SYSTEM AND ASSOCIATED INFRASTRUCTURE IN KLIPRAND`,
       "/assets/Tenders/T36-2019.pdf",
       "Ikapa Reticulation and Flow CC",
       "1",
       "R8 136 868.36",
       ""    
     ),
     new TendersModule(
       "RE-ADVERTISEMENT OF SUPPLY AND INSTALLATION SECTIONAL STEEL RESERVOIR IN KLIPRAND",
       "T37/2019",
       "2020-01-28",
       "12:05",
       "2020-02-14",
       "12:00",
       `05 February 2020 at 11h00 at the Community Hall Kliprand`,
       `RE-ADVERTISEMENT OF NEW WATERBORNE SEWER SYSTEM AND ASSOCIATED INFRASTRUCTURE IN KLIPRAND`,
       "/assets/Tenders/T37-2019.pdf",
       "Structa Technology (PTY) LTD",
       " 1 ",
       "R 544 522,13",
       ""    
     ),
      new TendersModule(
        "RE-ADVERTISEMENT CONSTRUCTION OF NEW TOILET STRUCTURES & ASSOCIATED INFRASTRUCTURE WORKS IN KLIPRAND",
        "T38/2019",
        "2020-01-28",
        "12:11",
        "2020-02-14",
        "12:00",
        `05 February 2020 at 11h00 at the Community Hall Kliprand`,
        `RE-ADVERTISEMENT CONSTRUCTION OF NEW TOILET STRUCTURES & ASSOCIATED INFRASTRUCTURE WORKS IN KLIPRAND`,
        "/assets/Tenders/T38-2019.pdf",
        "Ikapa Reticulation and Flow CC",
        "1",
        "R2 686 800.55",
        ""    
      ),
      new TendersModule(
        "T29-2019 UPGRADING OF VREDENDAL SEWAGE PUMP STATIONS",
        "T29-2019",
        "2019-10-23",
        "09:00",
        "2019-11-13",
        "12:00",
        `29 October 2019 11:00 at Matzikama Municipality Technical Offices Boardroom,
        1 Sirkel Street, Vredendal South`,
        `UPGRADING OF VREDENDAL SEWAGE PUMP STATIONS: PHASE 1: MECHANICAL AND ELECTRICAL WORKS`,
        "/assets/Tenders/T29-2019 WEB ADVERT.pdf",
        "Hidro-Tech Systems",
        "2",
        "R6 742 075.56",
        ""   
      ),
      new TendersModule(
        "T28-2019 UPGRADING OF VREDENDAL SOUTH WWTW",
        "T28-2019",
        "2019-10-12",
        "13:20",
        "2019-11-01",
        "12:00",
        `18 October 2019 at 11:00 in the Matzikama Municipal Council Chambers`,
        `UPGRADING OF VREDENDAL SOUTH WWTW:CIVIL, MECHANICAL AND ELECTRICAL WORKS AND UPGRADING OF PUMP STATION:
         CIVIL WORK`,
        "/assets/Tenders/T28-2019 WEB ADVERT.pdf",
        "",
        "",
        "",
        ""    
      ),
      new TendersModule(
        "T27-2019 CONSTRUCTION OF 1,0ML RESERVOIR AT KOEKENAAP",
        "T27-2019",
        "2019-09-19",
        "09:05",
        "2019-10-11",
        "12:00",
        `02 October 2019 at 11:00 in the Community Hall Koekenaap`,
        `CONSTRUCTION OF 1,0ML RESERVOIR AT KOEKENAAP`,
        "/assets/Tenders/T27-2019 WEB ADVERT.pdf",
        "EMPA Structures",
        "1",
        "R 4 794 620,40",
        ""    
      ),
      new TendersModule(
        "T26-2019 ROAD STONE AND ROAD BUILDING MATERIAL",
        "T26-2019",
        "2019-09-06",
        "10:25",
        "2019-10-07",
        "12:00",
        ``,
        `THE SUPPLY AND DELIVERY OF ROAD STONE AND ROAD BUILDING MATERIAL 
         FOR ROAD MAINTENANCE IN THE MATZIKAMA AREA UNTILL 30 JUNE 2020`,
        "/assets/Tenders/t26.pdf",
        "TRU TRU TRADING",
        "2",
        "R 833 980",
        ""    
      ),
      new TendersModule(
        "T25-2019 BITUMEN PRODUCTS FOR ROAD MAINTENANCE",
        "T26-2019",
        "2019-09-06",
        "10:30",
        "2019-10-07",
        "12:00",
        ``,
        `SUPPLY AND DELIVERY OF BITUMEN PRODUCTS 
         FOR ROAD MAINTENANCE IN THE MATZIKAMA AREA UNTILL 30 JUNE 2020`,
        "/assets/Tenders/t25.pdf",
        "TOSAS",
        " 1 ",
        "R754 032.00",
        ""    
      ),
      new TendersModule(
        "T21-2019 SUPPLY AND DELIVERY OF COMPUTER EQUIPMENT",
        "T21-2019",
        "2019-09-06",
        "12:15",
        "2019-10-07",
        "12:00",
        "Matzikama Head Office Vredendal",
        "T21-2019 SUPPLY AND DELIVERY OF COMPUTER EQUIPMENT FOR MATZIKAMA MUNICIPALITY UNTIL 30 JUNE 2020",
        "/assets/Tenders/T20-2019 NEW WATERBORNE SEWER SYSTEM AND ASSOCIATED INFRASTRUCTURE IN KLIPRAND.pdf",
        `Dekatshe Consulting`,
        `Dekatshe Consulting :1`,
        `Dekatshe Consulting :R 77 311.02`,
        ""    
      ),
      new TendersModule(
        "T20-2019 NEW WATERBORNE SEWER SYSTEM",
        "T20-2019",
        "2019-10-02",
        "16:15",
        "2019-10-22",
        "16:15",
        "10 October 2019 AT 11:00 AT THE COMMUNITY HALL KLIPRAND",
        "T20-2019 NEW WATERBORNE SEWER SYSTEM AND ASSOCIATED INFRASTRUCTURE",
        "/assets/Tenders/t21.pdf",
        "Ann Doe",
        "Level 2",
        "R900 000",
        ""   
      ),
      new TendersModule(
        "APPOINTMENT OF A CONSULTANT FOR A STRATEGIC PLANNING SESSION",
        "K1/2020",
        "2020-01-09",
        "13:35",
        "2020-01-15",
        "12:00",
        `N/A`,
        `UPGRADING OF VREDENDAL SEWAGE PUMP STATIONS: PHASE 1: MECHANICAL AND ELECTRICAL WORKS`,
        "/assets/Tenders/T30-2020 WEB ADVERT.pdf",
        "Kalideen Management Services",
        "0",
        "R 194 300.00",
        ""     
      )
    ] */
  }

  ngOnInit() {
  }

}
