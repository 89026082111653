import { Component, OnInit, AfterViewInit } from '@angular/core';
import * as M from '../../../../node_modules/materialize-css';
declare let $ : any;

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.css']
})
export class SliderComponent implements OnInit, AfterViewInit {

  constructor() { }

  ngOnInit() {

     // Slider
    /*  document.addEventListener('DOMContentLoaded', function() {
      var elems = document.querySelectorAll('.slider');
      var instances = M.Slider.init(elems, {
        indicators:false,
        height:500,
        duration:500,
        interval:5000
      });

    }); */
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    $(document).ready(function(){
      $('.slider').slider({
        height:500
      });
    });
  }

}
