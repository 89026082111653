<h4 class="center">Supply Chain Documents</h4>


<div class="container">   <div class="row">
  <div class="col s12 m12 l12" >
    <mz-collapsible [mode]="'expandable'" [popout]="true">
       <mz-collapsible-item [active]="true">
         <mz-collapsible-item-header>
           <i class="material-icons">keyboard_arrow_down</i>
           REGISTER ON CSD AS PROSPECTIVE SUPPLIER
            </mz-collapsible-item-header>
           <mz-collapsible-item-body>
             <mz-collection>
               <mz-collection-item>
                   Upload Date:
                   <a href="#" mz-secondary-content download> {{ csdMay2023 | date: 'MMM, dd yyyy, HH:mm aaa'}}</a>
               </mz-collection-item>
               <mz-collection-item>
                  MBD 4
                  <a href="/assets/procurement/MBD 4.pdf"mz-secondary-content download>
                      <i class=" material-icons download rigth" download>cloud_download</i>
                  </a>
                </mz-collection-item>
                <mz-collection-item>
                  MBD 8
                  <a href="/assets/procurement/MBD 8.pdf"mz-secondary-content download>
                      <i class=" material-icons download rigth" download>cloud_download</i>
                  </a>
                </mz-collection-item>
                <mz-collection-item>
                  MBD 9
                  <a href="/assets/procurement/MBD 9.pdf"mz-secondary-content download>
                      <i class=" material-icons download rigth" download>cloud_download</i>
                  </a>
                </mz-collection-item>
           </mz-collection>
           </mz-collapsible-item-body>
       </mz-collapsible-item>
    </mz-collapsible>
  </div>
 </div>
  <!-- Start of Amended SCM -->
    <div class="row">
        <div class="col s12 m12 l12" >
          <mz-collapsible [mode]="'expandable'" [popout]="true">
             <mz-collapsible-item [active]="true">
               <mz-collapsible-item-header>
                 <i class="material-icons">keyboard_arrow_down</i>
                    AMENDED SUPPLY CHAIN DOCUMENTS FEBRUARY 2023
                  </mz-collapsible-item-header>
                 <mz-collapsible-item-body>
                   <mz-collection>
                     <mz-collection-item>
                         Upload Date:
                         <a href="#" mz-secondary-content download> {{ scmFeb2023 | date: 'MMM, dd yyyy, HH:mm aaa'}}</a>
                     </mz-collection-item>
                     <mz-collection-item>
                        NOTICE: PREFERENTIAL PROCUREMENT POLICY
                        <a href="/assets/procurement/Notice to public PPR.pdf"mz-secondary-content download>
                            <i class=" material-icons download rigth" download>cloud_download</i>
                        </a>
                      </mz-collection-item>
                      <mz-collection-item>
                        PREFERENTIAL PROCUREMENT POLICY OF MATZIKAMA MUNICIPALITY 
                        <a href="/assets/procurement/Amended  Municipal Preferential Procurement Policy 2022.pdf"mz-secondary-content download>
                            <i class=" material-icons download rigth" download>cloud_download</i>
                        </a>
                      </mz-collection-item>
                      <mz-collection-item>
                        AMENDED SCM POLICY 2022-2023
                        <a href="/assets/procurement/Amended SCM Policy 2022-2023.pdf"mz-secondary-content download>
                            <i class=" material-icons download rigth" download>cloud_download</i>
                        </a>
                      </mz-collection-item>
                     <mz-collection-item>
                        MBD 6.1 
                       <a href="/assets/procurement/MBD 6.1 final.pdf" mz-secondary-content download>
                           <i class=" material-icons download rigth" download>cloud_download</i>
                       </a>
                     </mz-collection-item>
                     <mz-collection-item>
                        BEE_Affidavit-EME-Generic
                        <a href="/assets/procurement/BEE_Affidavit-EME-Generic.pdf"mz-secondary-content download>
                            <i class=" material-icons download rigth" download>cloud_download</i>
                        </a>
                      </mz-collection-item>
                      <mz-collection-item>
                        BEE_Affidavit-EME-Specific
                        <a href="/assets/procurement/BEE_Affidavit-EME-Specific.pdf"mz-secondary-content download>
                            <i class=" material-icons download rigth" download>cloud_download</i>
                        </a>
                      </mz-collection-item>
                      <mz-collection-item>
                        BEE_Affidavit-QSE-Generic
                        <a href="/assets/procurement/BEE_Affidavit-QSE-Generic.pdf"mz-secondary-content download>
                            <i class=" material-icons download rigth" download>cloud_download</i>
                        </a>
                      </mz-collection-item>
                      <mz-collection-item>
                        BEE_Affidavit-QSE-Specific
                        <a href="/assets/procurement/BEE_Affidavit-QSE-Specific.pdf"mz-secondary-content download>
                            <i class=" material-icons download rigth" download>cloud_download</i>
                        </a>
                      </mz-collection-item>
                 </mz-collection>
                 </mz-collapsible-item-body>
             </mz-collapsible-item>
          </mz-collapsible>
        </div>
       </div>
    <div class="row">
        <h5 class="center">How do I register on the CSD?</h5>
        <div class="col s12 m12 l12">
            <img src="/assets/CSDProcess.png" alt="CSD Registration Process" class="center">
        </div>
    </div>
</div>
<app-footer></app-footer>
