<app-townplanning-nav></app-townplanning-nav>
<h5 class="center">Town Plans</h5>
<div class="container">
         <!-- First Card -->
    <div class="row">
        <div class="col s12 m12 l3">
            <div class="card small">
                <div class="card-image">
                    <img class="materialboxed" src="assets/townplanning/townPlans/Bitterfontein.JPG">
                        <span class=" black-text card-title center">Bitterfontein</span>
                </div>
                <div class="card-content">
                        <p>Click to enlarge Bitterfontein Town Plan image.</p>
                </div>
                <div class="card-action">
                    <a href="assets/townplanning/townPlans/Bitterfontein.JPG" download>Download</a>
                </div>
            </div>
        </div>
           <!-- Second Card -->
        <div class="col s12 m12 l3">
            <div class="card small">
                <div class="card-image">
                    <img class="materialboxed" src="assets/townplanning/townPlans/Doringbaai.JPG">
                        <span class=" black-text card-title center">Doringbaai</span>
                </div>
                <div class="card-content">
                        <p>Click to enlarge Doringbaai Town Plan image.</p>
                </div>
                <div class="card-action">
                  <a href="assets/townplanning/townPlans/Doringbaai.JPG" download>Download</a>
                </div>
            </div>
        </div>
              <!-- Third Card -->
        <div class="col s12 m12 l3">
            <div class="card small">
                <div class="card-image">
                    <img class="materialboxed" src="assets/townplanning/townPlans/Ebenhaeser.JPG">
                        <span class=" black-text card-title center">Ebenhaeser</span>
                </div>
                <div class="card-content">
                        <p>Click to enlarge Ebenhaeser Town Plan image.</p>
                </div>
                <div class="card-action">
                    <a href="assets/townplanning/townPlans/Ebenhaeser.JPG" download>Download</a>
                </div>
            </div>
        </div>
             <!-- Fourth Card -->
        <div class="col s12 m12 l3">
            <div class="card small">
                <div class="card-image">
                    <img class="materialboxed" src="assets/townplanning/townPlans/Klawer.JPG">
                        <span class=" black-text card-title center">Klawer</span>
                </div>
                <div class="card-content">
                        <p>Click to enlarge Klawer Town Plan image.</p>
                </div>
                <div class="card-action">
                    <a href="assets/townplanning/townPlans/Klawer.JPG" download>Download</a>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
            <!-- Fifth Card -->
        <div class="col s12 m12 l3">
            <div class="card small">
                <div class="card-image">
                    <img class="materialboxed" src="assets/townplanning/townPlans/Kliprand.JPG">
                        <span class=" black-text card-title center">Kliprand</span>
                </div>
                <div class="card-content">
                        <p>Click to enlarge Kliprand Town Plan image.</p>
                </div>
                <div class="card-action">
                    <a href="assets/townplanning/townPlans/Kliprand.JPG" download>Download</a>
                </div>
            </div>
        </div> 
            <!-- Sixth Card -->
        <div class="col s12 m12 l3">
            <div class="card small">
                <div class="card-image">
                    <img class="materialboxed" src="assets/townplanning/townPlans/Koekenaap.JPG">
                        <span class=" black-text card-title center">Koekenaap</span>
                </div>
                <div class="card-content">
                        <p>Click to enlarge Koekenaap Town Plan image.</p>
                </div>
                <div class="card-action">
                    <a href="assets/townplanning/townPlans/Koekenaap.JPG" download>Download</a>
                </div>
            </div>
        </div>
        <!-- Seventh Card -->
        <div class="col s12 m12 l3">
            <div class="card small">
                <div class="card-image">
                    <img class="materialboxed" src="assets/townplanning/townPlans/Lutzville.JPG">
                        <span class=" black-text card-title center">Lutzville</span>
                </div>
                <div class="card-content">
                        <p>Click to enlarge Lutzville Town Plan image.</p>
                </div>
                <div class="card-action">
                    <a href="assets/townplanning/townPlans/Lutzville.JPG" download>Download</a>
                </div>
            </div>
        </div>  
        <!-- Seventh Card -->
        <div class="col s12 m12 l3">
            <div class="card small">
                <div class="card-image">
                    <img class="materialboxed" src="assets/townplanning/townPlans/Lutzville-Wes.JPG">
                        <span class=" black-text card-title center">Lutzville-Wes</span>
                </div>
                <div class="card-content">
                        <p>Click to enlarge Lutzville-Wes Town Plan image.</p>
                </div>
                <div class="card-action">
                    <a href="assets/townplanning/townPlans/Lutzville-Wes.JPG" download>Download</a>
                </div>
            </div>
        </div>      
    </div>
    <div class="row">
        <!-- Eight Card -->
        <div class="col s12 m12 l3">
            <div class="card small">
                <div class="card-image">
                    <img class="materialboxed" src="assets/townplanning/townPlans/Nuwerus.JPG">
                        <span class=" black-text card-title center">Nuwerus</span>
                </div>
                <div class="card-content">
                        <p>Click to enlarge Nuwerus Town Plan image.</p>
                </div>
                <div class="card-action">
                    <a href="assets/townplanning/townPlans/Nuwerus.JPG" download>Download</a>
                </div>
            </div>
        </div> 
        <!-- Ninth Card -->
        <div class="col s12 m12 l3">
            <div class="card small">
                <div class="card-image">
                    <img class="materialboxed" src="assets/townplanning/townPlans/Papendorp.JPG">
                        <span class=" black-text card-title center">Papendorp</span>
                </div>
                <div class="card-content">
                        <p>Click to enlarge Papendorp Town Plan image.</p>
                </div>
                <div class="card-action">
                    <a href="assets/townplanning/townPlans/Papendorp.JPG" download>Download</a>
                </div>
            </div>
        </div>
    <!-- Tenth Card -->
        <div class="col s12 m12 l3">
            <div class="card small">
                <div class="card-image">
                    <img class="materialboxed" src="assets/townplanning/townPlans/Rietpoort.JPG">
                        <span class=" black-text card-title center">Rietpoort</span>
                </div>
                <div class="card-content">
                        <p>Click to enlarge Rietpoort Town Plan image.</p>
                </div>
                <div class="card-action">
                    <a href="assets/townplanning/townPlans/Rietpoort.JPG" download>Download</a>
                </div>
            </div>
        </div>  
    <!-- Eleventh Card -->
        <div class="col s12 m12 l3">
            <div class="card small">
                <div class="card-image">
                    <img class="materialboxed" src="assets/townplanning/townPlans/Strandfontein.JPG">
                        <span class=" black-text card-title center">Strandfontein</span>
                </div>
                <div class="card-content">
                        <p>Click to enlarge Strandfontein Town Plan image.</p>
                </div>
                <div class="card-action">
                    <a href="assets/townplanning/townPlans/Strandfontein.JPG" download>Download</a>
                </div>
            </div>
        </div>      
    </div>
    <div class="row">
        <!-- Twelth Card -->
        <div class="col s12 m12 l3">
            <div class="card small">
                <div class="card-image">
                    <img class="materialboxed" src="assets/townplanning/townPlans/Vanrhynsdorp.JPG">
                        <span class=" black-text card-title center">Vanrhynsdorp</span>
                </div>
                <div class="card-content">
                        <p>Click to enlarge Vanrhynsdorp Town Plan image.</p>
                </div>
                <div class="card-action">
                    <a href="assets/townplanning/townPlans/Vanrhynsdorp.JPG" download>Download</a>
                </div>
            </div>
        </div> 
        <!-- Thirteenth Card -->
        <div class="col s12 m12 l3">
            <div class="card small">
                <div class="card-image">
                    <img class="materialboxed" src="assets/townplanning/townPlans/Vredendal-Noord.JPG">
                        <span class=" black-text card-title center">Vredendal-Noord</span>
                </div>
                <div class="card-content">
                        <p>Click to enlarge Vredendal-Noord Town Plan image.</p>
                </div>
                <div class="card-action">
                    <a href="assets/townplanning/townPlans/Vredendal-Noord.JPG" download>Download</a>
                </div>
            </div>
        </div>
    <!-- Fourteenth Card -->
    <div class="col s12 m12 l3">
        <div class="card small">
            <div class="card-image">
                <img class="materialboxed" src="assets/townplanning/townPlans/Vredendal-Suid.JPG">
                    <span class=" black-text card-title center">Vredendal-Suid</span>
            </div>
            <div class="card-content">
                    <p>Click to enlarge Vredendal-Suid Town Plan image.</p>
            </div>
            <div class="card-action">
                <a href="assets/townplanning/townPlans/Vredendal-Suid.JPG" download>Download</a>
            </div>
        </div>
    </div>       
    </div>
</div>

