<h4 class="center">Local Economic Development</h4>
<div class="container">
    <div class="row">
        <div class="s12 m12 l12">
            <mz-collapsible  
            [mode]="'expandable'"
            [popout]="true">
                <mz-collapsible-item [active]="true">
                    <mz-collapsible-item-header>
                        <i class="material-icons">keyboard_arrow_down</i>
                        REGISTRATION OF LOCAL BLACK ENTREPRENEUR DATABASE
                    </mz-collapsible-item-header>
                    <mz-collapsible-item-body>
                        <mz-collection>
                            <mz-collection-item>
                                REGISTRATION OF LOCAL BLACK ENTREPRENEUR DATABASE
                                <a href="/assets/StrategicDocs/led/K13.2021 BLACK LOCAL DATABASE ADVERT MAY 2021.pdf" mz-secondary-content download>
                                    {{ledDatabase | date: 'MMM, dd yyyy, HH:mm:ss aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i></a>
                            </mz-collection-item>
                        </mz-collection>
                    </mz-collapsible-item-body>
                </mz-collapsible-item>
            </mz-collapsible>
        </div>
    </div>
<div class="row">
    <div class="col s12 m12 l12">
         
        <mz-collapsible
            [mode]="'expandable'"
            [popout]="true">
            
            <mz-collapsible-item [active]="true">
                <mz-collapsible-item-header>
                         <i class="material-icons">keyboard_arrow_down</i>
                            Matzikama Municipality LED Strategy                   
                </mz-collapsible-item-header>
                <mz-collapsible-item-body>
                    <mz-collection>
                        <mz-collection-item >
                            REGISTRATION OF LOCAL BLACK ENTREPRENEUR DATABASE
                                <a href="/assets/StrategicDocs/led/K13.2021 BLACK LOCAL DATABASE ADVERT MAY 2021.pdf" mz-secondary-content download>
                                    {{websiteLiveDate | date: 'MMM, dd yyyy'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>
                        <mz-collection-item >
                            Matzikama 2018 LED Strategy 2018-2022
                                <a href="/assets/StrategicDocs/led/Matzikama 2018 LED Strategy 2018-2022.pdf" mz-secondary-content download>
                                    {{websiteLiveDate | date: 'MMM, dd yyyy'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>
                        <!-- <mz-collection-item >
                            Tussenskatting 1STE 2018_20 Doringbaai
                                <a href="/assets/valuations/Tussenskatting 1STE 2018_20 Doringbaai.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>       
                        <mz-collection-item >
                            Tussenskatting 1STE 2019_20 BITTERFONTEIN
                                <a href="/assets/valuations/Tussenskatting 1STE 2019_20 BITTERFONTEIN.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>
                        <mz-collection-item >
                            Tussenskatting 1STE 2019_20 Ebenezer
                                <a href="/assets/valuations/Tussenskatting 1STE 2019_20 Ebenezer.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>  
                        <mz-collection-item >
                            Tussenskatting 1STE 2019_20 Klawer
                                <a href="/assets/valuations/Tussenskatting 1STE 2019_20 Klawer.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>  
                        <mz-collection-item >
                            Tussenskatting 1STE 2019_20 Lutzville
                                <a href="/assets/valuations/Tussenskatting 1STE 2019_20 Lutzville.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>  
                        <mz-collection-item >
                            Tussenskatting 1STE 2019_20 Olifantsrivier Neder Sett
                                <a href="/assets/valuations/Tussenskatting 1STE 2019_20 Olifantsrivier Neder Sett.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>
                        <mz-collection-item >
                            Tussenskatting 1STE 2019_20 RIETPOORT
                                <a href="/assets/valuations/Tussenskatting 1STE 2019_20 RIETPOORT.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>   
                         <mz-collection-item >
                            Tussenskatting 1STE 2019_20 Strandfontein
                                <a href="/assets/valuations/Tussenskatting 1STE 2019_20 Strandfontein.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>
                        <mz-collection-item >
                            Tussenskatting 1STE 2019_20 Vanrhynsdorp
                            <a href="/assets/valuations/Tussenskatting 1STE 2019_20 Vanrhynsdorp.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>   
                        <mz-collection-item >
                            Tussenskatting_Landelik 1STE 2019_20 
                            <a href="/assets/valuations/Tussenskatting_Landelik 1STE 2019_20 .pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>
                        <mz-collection-item >
                            Vredendal noord 1STE 2019_20
                            <a href="/assets/valuations/Vredendal noord 1STE 2019_20.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>  -->        
                    </mz-collection>
                </mz-collapsible-item-body>
            </mz-collapsible-item>
            <!-- End of Investor Collapsible & Collection -->   
        </mz-collapsible>
    </div>
</div>
</div>

