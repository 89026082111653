
   <div class="container hide-on-med-and-down">
    <div class="row">
        <div class="col s3 mayor">
           <app-lead-side-bar></app-lead-side-bar>
        </div>
        <div class="col s9">
           <h4 class="center">Community Development Services</h4>
           <hr>
           <div class="col s12">
                <div class="col s5">
                     <img src="/assets/Council/placeholder-md.png" alt="" class="responsive-img">
                     <span class="flow-text">Mr. R. Saul</span>
                </div>
                <div class="col s7">
                    <span class="black-text">
                        <p class="leadtext right" align="justify">
                                <b>Overview</b><br>
                                The Community Development Services directorate must render integrated community and development services to enhance
                                     community development in general and promote a clean and safe environment<br>
                                <b>The Director Community Development Services is responsible for:</b><br>
                                1. Manage the rendering of community services in accordance with applicable
                                     legislation, by-laws and standards<br>
                                2. Provide and manage planning, land affairs and building control<br>
                                3. Manage and coordinate economic development and tourism processes in
                                     accordance with legislative requirements<br>
                                4. Render protection services to ensure the safety of the community<br>
                                5. Render management and line function executive support services to the
                                     directorate<br>
                                <b>Contact Information</b><br>
                                Tel: +27 (0) 27 201 3350<br>
                                E-mail:<a href="mailto:lionelp@matzikama.gov.za">lionelp@matzikama.gov.za</a><br>
                        </p>
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Mobile View -->
<div class="container hide-on-large-only">
     <div class="row">
          <h4 class="center">Community Development Services</h4>
     </div>
     <div class="row">
          <div class="col s12 m12 l4">
               <img src="/assets/Council/placeholder-md.png" alt="" class="responsive-img">
               <span class="flow-text">Mr. R. Saul</span>
               <span class="black-text">
                    <p class="leadtext right" align="justify">
                            <b>Overview</b><br>
                            The Community Development Services directorate must render integrated community and development services to enhance
                                 community development in general and promote a clean and safe environment<br>
                            <b>The Director Community Development Services is responsible for:</b><br>
                            1. Manage the rendering of community services in accordance with applicable
                                 legislation, by-laws and standards<br>
                            2. Provide and manage planning, land affairs and building control<br>
                            3. Manage and coordinate economic development and tourism processes in
                                 accordance with legislative requirements<br>
                            4. Render protection services to ensure the safety of the community<br>
                            5. Render management and line function executive support services to the
                                 directorate<br>
                            <b>Contact Information</b><br>
                            Tel: +27 (0) 27 201 3350<br>
                            E-mail:<a href="mailto:lionelp@matzikama.gov.za">lionelp@matzikama.gov.za</a><br>
                    </p>
                </span>
          </div>
     </div>
</div>
<app-footer></app-footer>




