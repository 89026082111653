import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContactComponent } from './components/contact/contact.component';
import { HomeComponent } from './components/home/home.component';
import { DocumentsComponent } from './components/documents/documents.component';
import { GalleryComponent } from './components/gallery/gallery.component';
import { LeadershipComponent } from './components/leadership/leadership.component';
import { TendersComponent } from './components/documents/tenders/tenders.component';
import { StratdocsComponent } from './components/documents/stratdocs/stratdocs.component';
import { InvestorComponent } from './components/documents/investor/investor.component';
import { LedComponent } from './components/documents/led/led.component';
import { PublicparticipationComponent } from './components/documents/publicparticipation/publicparticipation.component';
import { VacanciesComponent } from './components/documents/vacancies/vacancies.component';
import { MayorComponent } from './components/leadership/mayor/mayor.component';
import { SpeakerComponent } from './components/leadership/speaker/speaker.component';
import { MaycoComponent } from './components/leadership/mayco/mayco.component';
import { CouncillorsComponent } from './components/leadership/councillors/councillors.component';
import { DeputyComponent } from './components/leadership/deputy/deputy.component';
import { MmComponent } from './components/leadership/mm/mm.component';
import { CfoComponent } from './components/leadership/cfo/cfo.component';
import { CorporateComponent } from './components/leadership/corporate/corporate.component';
import { CommunityComponent } from './components/leadership/community/community.component';
import { InfrastructureComponent } from './components/leadership/infrastructure/infrastructure.component';
import { FinanceComponent } from './components/documents/finance/finance.component';
import { FinpolComponent } from './components/documents/finpol/finpol.component';
import { MidyearComponent } from './components/documents/midyear/midyear.component';
import { CouncilComponent } from './components/documents/council/council.component';
import { TownplanningComponent } from './components/documents/townplanning/townplanning.component';
import { TendersAwardedComponent } from './components/documents/tenders/tenders-awarded/tenders-awarded.component';
import { TendersOpenComponent } from './components/documents/tenders/tenders-open/tenders-open.component';
import { TendersInProgressComponent } from './components/documents/tenders/tenders-in-progress/tenders-in-progress.component';
import { TendersCancelledComponent } from './components/documents/tenders/tenders-cancelled/tenders-cancelled.component';
import { BuildingControlComponent } from './components/documents/building-control/building-control.component';
import { TourismComponent } from './components/documents/tourism/tourism.component';
import { ServicesComponent } from './components/services/services.component';
import { FormsComponent } from './components/forms/forms.component';
import { SarsComponent } from './components/sars/sars.component';
import { ValuationComponent } from './components/documents/valuation/valuation.component';
import { ProcurementComponent } from './components/procurement/procurement.component';
import { ContractsComponent } from './components/documents/contracts/contracts.component';
import { SectorPlansComponent } from './components/documents/sector-plans/sector-plans.component';
import { AppealsComponent } from './components/documents/townplanning/appeals/appeals.component';
import { ApplicationsComponent } from './components/documents/townplanning/applications/applications.component';
import { BylawsComponent } from './components/documents/townplanning/bylaws/bylaws.component';
import { DecisionsComponent } from './components/documents/townplanning/decisions/decisions.component';
import { NoticesComponent } from './components/documents/townplanning/notices/notices.component';
import { SpatialPlansComponent } from './components/documents/townplanning/spatial-plans/spatial-plans.component';
import { TownPlanningContactComponent } from './components/documents/townplanning/town-planning-contact/town-planning-contact.component';
import { TownPlansComponent } from './components/documents/townplanning/town-plans/town-plans.component';
import { TribunalComponent } from './components/documents/townplanning/tribunal/tribunal.component';
import { Covid19Component } from './components/covid19/covid19.component';
import { SdfComponent } from './components/documents/townplanning/sdf/sdf.component';
import { CovidAboutComponent } from './components/covid19/covid-about/covid-about.component';
import { CovidContactComponent } from './components/covid19/covid-contact/covid-contact.component';
import { PerformanceComponent } from './components/documents/performance/performance.component';
import { RfpComponent } from './components/documents/tenders/rfp/rfp.component';
import { ViewEventComponent } from './components/gallery/view-event/view-event.component';
import { CoastalCleanupComponent } from './components/gallery/coastal-cleanup/coastal-cleanup.component';
import { MunicipalNoticesComponent } from './components/documents/municipal-notices/municipal-notices.component';
import { MediaStatementsComponent} from './components/media-statements/media-statements.component';
import { WardCommitteeComponent } from './components/documents/ward-committee/ward-committee.component';
import { PublicationsComponent } from './components/publications/publications.component';
import { GrantLoadsheddingComponent } from './components/grant-loadshedding/grant-loadshedding.component';
import { AuctionComponent } from './components/auction/auction.component';
import {GovtTendersComponent} from './components/documents/tenders/govt-tenders/govt-tenders.component';
import { ArchivedTendersComponent } from './components/documents/tenders/archived-tenders/archived-tenders.component';



const routes: Routes = [
  { path:'leadership', component: LeadershipComponent},
  { path:'home', component: HomeComponent},
  { path:'documents', component: DocumentsComponent},
  { path:'tourism', component: TourismComponent},
  { path:'services', component: ServicesComponent},
  { path:'sars', component: SarsComponent},
  { path:'forms', component: FormsComponent},
  { path:'tenders', component:TendersComponent},
  { path:'tenders-awarded', component:TendersAwardedComponent},
  { path:'tenders-open', component:TendersOpenComponent},
  { path:'tenders-in-progress', component:TendersInProgressComponent},
  { path:'tenders-cancelled', component:TendersCancelledComponent},
  { path:'request-for-proposal', component: RfpComponent},
  { path:'govt-tenders', component:GovtTendersComponent},
  { path:'archived-tenders', component:ArchivedTendersComponent},
  { path:'stratdocs', component:StratdocsComponent},
  { path:'finance', component:FinanceComponent},/* S71 reports  */
  { path:'finpol', component:FinpolComponent}, /* Financial Policies  */
  { path:'midyear', component:MidyearComponent}, /* Section 52 & S72 report  */
  { path:'investor', component:InvestorComponent},
  { path:'led', component:LedComponent},
  { path:'publicparticipation', component:PublicparticipationComponent},
  { path:'performance', component:PerformanceComponent},
  { path:'vacancies', component:VacanciesComponent},
  { path:'council', component:CouncilComponent},/* Council Documents  */
  { path:'townplanning', component:TownplanningComponent},
  { path:'sdf', component:SdfComponent},
  { path:'appeals', component:AppealsComponent},
  { path:'applications', component:ApplicationsComponent},
  { path:'bylaws', component:BylawsComponent},
  { path:'decisions', component:DecisionsComponent},
  { path:'notices', component:NoticesComponent},
  { path:'spatialPlans', component:SpatialPlansComponent},
  { path:'townPlanningContact', component:TownPlanningContactComponent},
  { path:'townplans', component:TownPlansComponent},
  { path:'tribunal', component:TribunalComponent},
/*   { path:'townplanning', component:SdfComponent,
      children :[
        {path:'sdf', component:SdfComponent},
        {path:'appeals', component:AppealsComponent},
        {path:'applications', component:ApplicationsComponent},
        {path:'bylaws', component:BylawsComponent},
        {path:'decisions', component:DecisionsComponent},
        {path:'notices', component:NoticesComponent},
        {path:'spatialPlans', component:SpatialPlansComponent},
        {path:'townPlanningContact', component:TownPlanningContactComponent},
        {path:'townplans', component:TownPlansComponent},
        {path:'tribunal', component:TribunalComponent},
      ]}, */
  { path:'building-control', component:BuildingControlComponent},
  { path:'valuation', component:ValuationComponent},
  { path:'contracts', component:ContractsComponent},
  { path:'gallery', component:GalleryComponent},
  {path:'view-event', component:ViewEventComponent},
  {path:'coastal-cleanup', component:CoastalCleanupComponent},
  { path:'contact', component:ContactComponent},
  { path:'mayor', component:MayorComponent},
  { path:'speaker', component:SpeakerComponent},
  { path:'mayco', component:MaycoComponent},
  { path:'councillors', component:CouncillorsComponent},
  { path:'deputy', component:DeputyComponent},
  { path:'mm', component:MmComponent},
  { path:'cfo', component:CfoComponent},
  { path:'corporate', component:CorporateComponent},
  { path:'community', component:CommunityComponent},
  { path:'infrastructure', component:InfrastructureComponent},
  { path:'procurement', component:ProcurementComponent},
  { path:'sectorPlans', component:SectorPlansComponent}, //IDP Sector Plans
  { path:'covid19', component:Covid19Component},
  { path:'covid-about', component:CovidAboutComponent},
  { path:'covid-contact', component:CovidContactComponent},
  { path:'municipal-notices', component:MunicipalNoticesComponent},
  { path:'mediaStatements', component:MediaStatementsComponent},
  { path:'ward-committee', component:WardCommitteeComponent},
  {path:'publications', component:PublicationsComponent},
  {path:'grantLoadshedding', component:GrantLoadsheddingComponent},
  {path:'auction', component:AuctionComponent},






  { path:'**', redirectTo:'home', pathMatch:'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash:true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
