import { Component, OnInit } from '@angular/core';
declare let $ : any;

@Component({
  selector: 'app-rfp',
  templateUrl: './rfp.component.html',
  styleUrls: ['./rfp.component.css']
})
export class RfpComponent implements OnInit {

  rfpBusCentre:Date = new Date("2020-08-28 09:39");
  rfpHousing:Date = new Date("2021-11-08 12:47");
  rfp012425:Date = new Date("2024-07-05 15:12");



  constructor() { }

  ngOnInit() {

  }

}
