<h4 class="center">Forms</h4>
<div class="container">
    <div class="row">
        <div class="col s12 m12 l4">
            <div class="card #0d47a1 blue darken-4 hoverable">
                <div class="card-content white-text">
                    <span class="card-title">EPWP</span>
                    <p>Application form.</p>
                </div>
                <div class="card-action">
                    <a href="/assets/Forms/Aansoek vorm - EPWP project.pdf" download>Afrikaans</a>

                </div>
            </div>
        </div>
        <div class="col s12 m12 l4">
            <div class="card #0d47a1 blue darken-4 hoverable">
                <div class="card-content white-text">
                    <span class="card-title">ODOUR/EMISSIONS</span>
                    <p>Download complaint form.</p>
                </div>
                <div class="card-action">
                    <a href="/assets/Forms/Matzikama Municipality Odour - Emissions Complaint Investigation Form.pdf" download>English</a>
                    <a href="/assets/Forms/Matzikama Munisipaliteit Reuk - Emissies Klagte Ondersoekvorm.pdf" download>Afrikaans</a>

                </div>
            </div>
        </div>
        <div class="col s12 m12 l4">
            <div class="card #0d47a1 blue darken-4 hoverable">
                <div class="card-content white-text">
                    <span class="card-title">SSEG</span>
                    <p>Download application form.</p>
                </div>
                <div class="card-action">
                    <a href="/assets/municipal-notices/2023-24/Matzikama SSEG Policy (14 Oct 2023).pdf" download>Policy</a>
                    <a href="/assets/municipal-notices/2023-24/Matzikama SSEG Application Form (Sep2023).pdf" download>Application Form</a>

                </div>
            </div>
        </div>
        <!-- <div class="col s12 m12 l4">
            <div class="card #0d47a1 blue darken-4 hoverable">
                <div class="card-content white-text">
                    <span class="card-title">EasyPay</span>
                    <p>Buy prepaid electricity online here.</p>
                </div>
                <div class="card-action">
                    <a href="https://new.easypay.co.za" target="_blank">Buy Prepaid</a>
                </div>
            </div>
        </div>
        <div class="col s12 m12 l4">
            <div class="card #0d47a1 blue darken-4 hoverable">
                <div class="card-content white-text">
                    <span class="card-title">View Municipal Account</span>
                    <p>View you municipal account here.</p>
                </div>
                <div class="card-action">
                    <a href="https://matzikama.cabedocs.com/auth/login" target="_blank">View Account</a>
                </div>
            </div>
        </div> -->
    </div>
    <div class="row">
        <div class="col s12 m12 l4">
            <div class="card #0d47a1 blue darken-4 hoverable">
                <div class="card-content white-text">
                    <span class="card-title">Electricity Capacity Change</span>
                    <p class="truncate">Application Form For Selection Of Supply Capacity </p>
                </div>
                <div class="card-action">
                  <a href="assets/Important Notifications/2024-25/Change of Capacity Form - Final (02 Jul 2024).pdf" download>Download Form</a>
                </div>
            </div>
        </div>
    </div>
</div>

