<h4 class="center">Gallery</h4>
<!-- <div class="row">
    <h4 class="center">EPWP Building and Civil Construction Painting </h4>
    <div class="col s12 m7 l7 offset-l3">
      <div class="card">
        <div class="card-image">
          <img src="../../../../assets/Industrial painters.jpg" class="materialboxed">
          <span class="card-title">EPWP Industrial Painters</span>
        </div>
        <div class="card-content">
          <p>Matzikama Municipality Industrial Painters project in partnership with Zama Training & Development through 
            the Expanded Public Works Programme(EPWP). Click on image to see more.</p>
        </div>
      </div>
    </div>
  </div> -->

  <!-- Cards for gallery -->
  <div class="row">
    <div class="col s12 m7 l4">
      <div class="card medium">
        <div class="card-image">
          <img src="../../../../assets/Industrial painters.jpg" class="materialboxed">
          <span class="card-title white-text ">EPWP Industrial Painters</span>
        </div>
        <div class="card-content">
          <p>Matzikama Municipality Industrial Painters project in partnership with Zama Training & Development through 
            the Expanded Public Works Programme(EPWP). Click on image to see more.</p>
        </div>
        <div class="card-action">
          <a>Click on image to view</a>
        </div>
      </div>
    </div>
    <div class="col s12 m7 l4">
      <div class="card medium">
        <div class="card-image">
          <img src="../../../../assets/gallery/Rietpoort Erfenisdag/IMG_7307.JPG">
          <span class="card-title white-text">Erfenisdagvieringe te Rietpoort</span>
        </div>
        <div class="card-content">
          <p>Die historiese dorpie in Wyk 8 van Matzikama Munisipaliteit het die naweek gewemel van mense toe verskeie 
            afgevaardiges van die Matzikama munispaliteit die dorp besoek het. 
            </p>
        </div>
        <div class="card-action">
          <a  [routerLink]="['/view-event']">View photos</a>
        </div>
      </div>
    </div>
    <div class="col s12 m7 l4">
      <div class="card medium">
        <div class="card-image">
          <img src="../../../../assets/gallery/Coastal Cleanup/1.jpg">
          <span class="card-title white-text">Coastal Cleanup</span>
        </div>
        <div class="card-content">
          <p>Coastal Cleanup Campaign</p>
        </div>
        <div class="card-action">
          <a [routerLink]="['/coastal-cleanup']">View photos</a>
        </div>
      </div>
    </div> 
  </div>

