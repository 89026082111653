<app-townplanning-nav></app-townplanning-nav>
<div class="container">
    <h5 class="center">Matzikama Municipality By-Laws</h5>
    <div class="row">
        <div class="col s12 m12 l12">
            <mz-collapsible [mode]="'expandable'" [popout]="true">
                <mz-collapsible-item [active]="true">
                    <mz-collapsible-item-header>
                        <i class="material-icons">keyboard_arrow_down</i>
                            Matzikama Municipality By-Laws
                    </mz-collapsible-item-header>
                    <mz-collapsible-item-body>
                        <mz-collection>
                            <mz-collection-item >
                                Credit Control & Debt Management
                                <a href="/assets/townplanning/bylaws/9002 GAZETTE BY-LAWS 2024 2025.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                            </mz-collection-item>
                          <mz-collection-item >
                            Approved Concept Matzikama Municipality Zoning Scheme Bylaw 30 May 2022
                            <a href="/assets/municipal-notices/Approved Concept Matzikama Zoning Scheme 30 May 2022 PPP (004).pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>
                            <mz-collection-item >
                                Matzikama Municipality Draft By-law on Municipal Land Use Planning 2020
                                <a href="/assets/townplanning/bylaws/Draft Amended MM Land Use Planning Bylaw.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                            </mz-collection-item>
                            <mz-collection-item >
                                Artikel 8 Regulasie
                                <a href="/assets/townplanning/bylaws/Art 8 Regulasies Afr.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                            </mz-collection-item>
                            <mz-collection-item>
                                DRAFT INTEGRATED ZONING SCHEME BY-LAW
                                <a href="/assets/townplanning/bylaws/Geintegreerde soneringskema Verordening as konsep aanvaar.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                            </mz-collection-item>
                            <mz-collection-item >
                                SKEMAREGULASIES 1993
                                <a href="/assets/townplanning/bylaws/Vredendal skemaregulasies 1993.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                            </mz-collection-item>
                           <mz-collection-item >
                                 Policy on Guesthouses in the Matzikama Municipal Area
                                <a href="/assets/townplanning/bylaws/Policy on Guesthouses in the Matzikama Municipal Area.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                            </mz-collection-item>
                           <!--  <mz-collection-item>
                                APPLICATION FOR DEPARTURE:Mr MS Mranqu
                                <a href="/assets/townplanning/applications/erf2519.pdf" mz-secondary-content download>
                                   {{erf2519UploadDate |date : 'MMM, dd yyyy, HH:mm:ss'}}
                                    <i class=" material-icons download right" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                APPLICATION FOR DEPARTURE, CONSOLIDATION, CONSENT USE & AMENDMENT OF RESTRICTIVE CONDITIONS
                                <a href="/assets/townplanning/applications/bluedot.pdf" mz-secondary-content download>
                                   {{bluedotUploadDate |date : 'MMM, dd yyyy, HH:mm:ss'}}
                                    <i class=" material-icons download right" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                APPLICATION FOR REZONING OF PORTION 2 OF THE FARM VADERLANDSCHE RIETKUIL NO 308
                                <a href="/assets/townplanning/applications/rietkuil.pdf" mz-secondary-content download>
                                   {{rietkuilUploadDate |date : 'MMM, dd yyyy, HH:mm:ss'}}
                                    <i class=" material-icons download right" download>cloud_download</i>
                                </a>
                            </mz-collection-item> -->
                        </mz-collection>
                    </mz-collapsible-item-body>
                </mz-collapsible-item>
            </mz-collapsible>
        </div>
    </div>
</div>

