import { Component, OnInit } from '@angular/core';
import * as M from '../../../../../../node_modules/materialize-css';
declare let $ : any;

@Component({
  selector: 'app-town-plans',
  templateUrl: './town-plans.component.html',
  styleUrls: ['./town-plans.component.css']
})
export class TownPlansComponent implements OnInit {

  constructor() { }

  ngOnInit() {

      $(document).ready(function(){
        $('.materialboxed').materialbox();
      });
  }

}
