
 <app-covid-navbar></app-covid-navbar>
<section>
    <div class="parallax-container">
        <div class="parallax"><img src="../../../../assets/covid19/images/innerbg.jpg"></div>
    </div>
    <div class="section white">
        <div class="row container">
          <h2 class="header"><strong>Corona Virus</strong>- What Is It?</h2>
            <div class="col s12 m12 l6">
                <p class="grey-text text-darken-3 lighten-3 flow-text">
                    On 31 December 2019, the World Health Organization (WHO) reported a cluster of pneumonia cases in Wuhan City, China.
                    ‘Severe Acute Respiratory Syndrome Coronavirus 2’ (SARS-CoV-2) was confirmed as the causative agent of what we now know as
                    ‘Coronavirus Disease 2019’ (COVID-19). Since then, the virus has spread to more than 100 countries, including South Africa.
                </p>
                <a [routerLink]="['/covid-about']">Read More</a>
            </div>
            <div class="col s12 m12 l6">
                <img src="../../../../assets/covid19/images/stop.jpg" alt="" srcset="" width="500px" height="auto">
            </div>

        </div>
    </div>
    <div class="parallax-container">
        <div class="parallax"><img src="../../../../assets/covid19/images/banner.png"></div>
    </div>
    <div class="section white">
        <div class="row container">
            <h3 class="header center"><strong>Covid-19 Information</strong></h3>
            <div class="col s12 m12 l4">
                <div class="card hoverable">
                    <div class="card-content">
                        <span class="card-title center"><h4><strong>Don't Panic</strong></h4></span>
                            <blockquote class="flow-text">
                                There is no need to panic - 82% of Covid-19 cases are mild: patients only experience a slight fever,
                                fatigue and a cough.
                            </blockquote>
                           <blockquote class="flow-text">
                            Only 6% of patients need intensive care. The vast majority of people can stay at home
                            and get better without hospital treatment.
                           </blockquote>
                           <cite>Source: SAcoronavirus.co.za</cite>
                    </div>
                </div>
            </div>
            <div class="col s12 m12 l4">
                <div class="card hoverable">
                    <div class="card-content">
                        <span class="card-title center"><h4><strong>Symptoms</strong></h4></span>
                        <span class="flow-text">The most common symptoms are:</span>
                        <blockquote class="flow-text">Cough</blockquote>
                        <blockquote class="flow-text">Fever</blockquote>
                        <blockquote class="flow-text">Sore throat</blockquote>
                        <blockquote class="flow-text">Shortness of breath</blockquote>
                        <cite>Source: SAcoronavirus.co.za</cite>
                    </div>
                </div>
            </div>
            <div class="col s12 m12 l4">
                <div class="card hoverable">
                    <div class="card-content">
                        <span class="card-title center"><h4><strong>Preventative Tips</strong></h4></span>
                        <blockquote class="flow-text">
                            Wash your hands regularly with soap or an alcohol-based hand sanitiser
                        </blockquote>
                        <blockquote class="flow-text"> Avoid touching your eyes, nose and mouth with unwashed hands</blockquote>
                        <blockquote class="flow-text">Avoid close contact with people who are sick</blockquote>
                        <blockquote class="flow-text">Cover your cough or sneeze with a flexed elbow or a tissue, then throw the tissue into a bin</blockquote>
                        <!-- <blockquote class="flow-text">Clean and disinfect frequently touched objects and surfaces</blockquote> -->
                        <cite>Source: SAcoronavirus.co.za</cite>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>
<div class="parallax-container">
    <div class="parallax"><img src="../../../assets/covid19/images/corona-5174671_1920.jpg"></div>
</div>
<div class="container">
    <section>

        <div class="col s12 m12 l4 offset-l2">
            <ngx-twitter-timeline
                [data]="{sourceType: 'url', url: 'https://twitter.com/COVID_19_ZA?ref_src=twsrc%5Etfw'}"
                [opts]="{tweetLimit: 3}"
            ></ngx-twitter-timeline>

        </div>
    </section>
</div>
<app-footer></app-footer>
