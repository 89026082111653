
<div class="container ">
    <div class="row">
        <div class="col s3 mayor show-on-large hide-on-med-and-down">
           <app-lead-side-bar></app-lead-side-bar>
        </div>
        <div class="col s9">
           <h4 class="center">Councillors</h4>
           <hr>
            <div class="row">
               <div class="col s12 m4 l4">
                    <img src="/assets/Council/Screenshot_20211207-143608_WhatsAppBusiness.jpg" alt="" class="responsive-img">
                    <span class="flow-text">Cllr. J. Muller</span>
               </div>
               <div class="col s12 m4 l4">
                    <img src="/assets/Council/20211209_113619.jpg" alt="" class="responsive-img">
                    <span class="flow-text">Cllr. S. Tshabalala</span>
                </div>
                <div class="col s12 m4 l4">
                    <img src="/assets/Council/Matz Mun_043 w.png" alt="" class="responsive-img">
                    <span class="flow-text">Cllr. C. Le Fleur</span>
                </div>
            </div>
            <div class="row">
              <div class="col s12 m4 l4">
                <img src="/assets/Council/IMG-20211209-WA0023.jpg" width="399" height="600" alt="" class="responsive-img">
                    <span class="flow-text">Cllr. A. Pool</span>
              </div>
              <div class="col s12 m4 l4">
                  <img src="/assets/Council/193311868_2456426554502702_4062720313596341684_n.jpg" width="399" height="600" alt="" class="responsive-img">
                      <span class="flow-text">Cllr. J. Koopman</span>
              </div>
              <div class="col s12 m4 l4">
                <img src="/assets/Council/Christoffel.jpg"  width="399" height="600" alt="" class="responsive-img">
                <span class="flow-text">Cllr. C. van der Westhuizen</span>
            </div>
            </div>
            <div class="row">
              <div class="col s12 m4 l4">
                  <img src="/assets/Council/20211208_155940.jpg" width="399" height="600" alt="" class="responsive-img">
                      <span class="flow-text">Cllr. D. Witbooi</span>
              </div>
              <div class="col s12 m4 l4">
                  <img src="/assets/Council/IMG_9638.JPG"  width="399" height="600" alt="" class="responsive-img">
                  <span class="flow-text">Cllr. M. Bottem</span>
              </div>
              <div class="col s12 m4 l4">
                <img src="/assets/Council/IMG_9549.JPG"  width="399" height="600" alt="" class="responsive-img">
                <span class="flow-text">Cllr. S. Tshali</span>
            </div>
          </div>
        </div>  <!-- Closing tag for 9 col-->
    </div>
</div>
<app-footer></app-footer>
