
<div class="section">
    <div class="container">
        <div class="row">
            <div class="col s12">
                    <div class="card-panel #01579b light-blue darken-4 white-text center">
                            <i class="material-icons">local_phone</i>
                            <h5>Municipal Offices Contact Numbers</h5>
                            <table class="highlight">
                                    <thead>
                                      <tr>
                                          <th>Municipal Office</th>
                                          <th>Contact Number</th>
                                      </tr>
                                    </thead>
                            
                                    <tbody>
                                      <tr>
                                        <td>VREDENDAL</td>
                                        <td>(027) 201 3300 </td>
                        
                                      </tr>
                                      <tr>
                                        <td>DORINGBAAI</td>
                                        <td>027 201 3445</td>
                                      </tr>
                                      <tr>
                                        <td>STRANDFONTEIN</td>
                                        <td>027 201 3437</td>
                                      </tr>
                                      <tr>
                                        <td>Ebenhaezer</td>
                                         <td>027 201 3446/7</td>
                                      </tr>
                                      <tr>
                                        <td>Lutzville</td>
                                        <td>027 201 3441/3444</td>
                                      </tr>
                                      <tr>
                                        <td>Koekenaap</td>
                                        <td>027 201 3440 </td>
                                      </tr>
                                      <tr>
                                        <td>KLAWER</td>
                                        <td>027 201 3423/4</td>
                                      </tr>
                                      <tr>
                                        <td>VANRHYNSDORP</td>
                                        <td>027 201 3431/2</td>
                                      </tr>
                                      <tr>
                                        <td>BITTERFONTEIN</td>
                                         <td>027 201 3484</td>
                                      </tr>
                                      <tr>
                                        <td>NUWERUS</td>
                                        <td>027 201 3485</td>
                                      </tr>
                                       <tr>
                                        <td>KLIPRAND</td>
                                        <td>027 501 1095</td>
                                      </tr>
                                      <tr>
                                        <td>MOLSVLEI</td>
                                        <td>027 632 5076</td>
                                      </tr>
                                      <tr>
                                        <td>RIETPOORT</td>
                                         <td>027 632 5013</td>
                                      </tr>
                                      <tr>
                                        <td>STOFKRAAL</td>
                                        <td>027 632 5291</td>
                                      </tr>
                                      <tr>
                                        <td>Traffic Fines</td>
                                        <td>027 201 3399</td>
                                      </tr>
                                    </tbody>
                                  </table>
                            <div class="container">
                                <p>MUNICIPAL HEAD OFFICE CONTACT DETAILS
                                    VREDENDAL
                                    Box / PO Box 98, 37 Church Street, Vredendal, 8160
                                    Tel:(027) 201 3300 Fax:(027) 213 3238,
                                    Email:Headoff@matzikama.gov.za
                                    Website: www.matzikamamunicipality.co.za
                                    </p>
                            </div>
                    </div>
            </div>
<!-- End of first section of row -->

    </div>
</div>
<app-footer></app-footer>




<!-- <section id="contact" class="section section-contact scrollspy">
        <div class="container">
          <div class="row">
            <div class="col s12 m6">
              <div class="card-panel teal white-text center">
                <i class="material-icons">email</i>
                <h5>Contact Us For Booking</h5>
                <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Illo praesentium fugit tempore hic enim possimus molestias
                  quisquam impedit corrupti eveniet.</p>
              </div>
              <ul class="collection with-header">
                <li class="collection-header">
                  <h4>Location</h4>
                </li>
                <li class="collection-item">Travelville Agency</li>
                <li class="collection-item">555 Beach Rd, Suite 33</li>
                <li class="collection-item">Miami, FL 55555</li>
              </ul>
            </div>
            <div class="col s12 m6">
              <div class="card-panel grey lighten-3">
                <h5>Please fill out this form</h5>
                <div class="input-field">
                  <input type="text" placeholder="Name">
                </div>
                <div class="input-field">
                  <input type="text" placeholder="Email">
                </div>
                <div class="input-field">
                  <input type="text" placeholder="Phone">
                </div>
                <div class="input-field">
                  <textarea class="materialize-textarea" placeholder="Enter Message"></textarea>
                </div>
                <input type="submit" value="Submit" class="btn">
              </div>
            </div>
          </div>
        </div>
      </section> -->