<div class="container">
    <h4 class="center">Long Term Borrowing Contracts</h4>
<div class="row">
    <div class="col s12 m12 l12">
         
        <mz-collapsible
            [mode]="'expandable'"
            [popout]="true">
            
            <mz-collapsible-item [active]="true">
                <mz-collapsible-item-header>
                         <i class="material-icons">keyboard_arrow_down</i>
                        Borrowing Contracts                   
                </mz-collapsible-item-header>
                <mz-collapsible-item-body>
                    <mz-collection>
                        <mz-collection-item >
                            Standard bank, 5 year Term (2019) ,10.27% R10 000 000, Addendum
                                <a href="/assets/borrowing/loan4_1.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>
                        <mz-collection-item >
                            Standard bank, 5 year Term (2019),10.27% R10 000 000
                                <a href="/assets/borrowing/loan4.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>       
                        <mz-collection-item >
                            Development bank of South Africa,15 year Term (2012), 8.82%, R2 912 645
                                <a href="/assets/borrowing/loan3.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>
                        <mz-collection-item >
                            Development bank of South Africa,10 year Term(2012), 8.82%, R11 087 355
                                <a href="/assets/borrowing/loan2.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>  
                        <mz-collection-item >
                            Development bank of South Africa,10 year Term (2010),11.135%, R15 000 000
                                <a href="/assets/borrowing/loan1.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>  
                        <!--<mz-collection-item >
                            M06_FMR Matzikama Municipality_S71 December 2019
                                <a href="/assets/StrategicDocs/S71/2019-20/M06_FMR Matzikama Municipality_S71 December 2019.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>  
                         <mz-collection-item >
                            M07_FMR Matzikama Municipality_S71 January 2020
                                <a href="/assets/StrategicDocs/S71/2019-20/M07_FMR Matzikama Municipality_S71 January 2020.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>
                        <mz-collection-item >
                            Tussenskatting 1STE 2019_20 RIETPOORT
                                <a href="/assets/valuations/Tussenskatting 1STE 2019_20 RIETPOORT.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>   
                         <mz-collection-item >
                            Tussenskatting 1STE 2019_20 Strandfontein
                                <a href="/assets/valuations/Tussenskatting 1STE 2019_20 Strandfontein.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>
                        <mz-collection-item >
                            Tussenskatting 1STE 2019_20 Vanrhynsdorp
                            <a href="/assets/valuations/Tussenskatting 1STE 2019_20 Vanrhynsdorp.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>   
                        <mz-collection-item >
                            Tussenskatting_Landelik 1STE 2019_20 
                            <a href="/assets/valuations/Tussenskatting_Landelik 1STE 2019_20 .pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>
                        <mz-collection-item >
                            Vredendal noord 1STE 2019_20
                            <a href="/assets/valuations/Vredendal noord 1STE 2019_20.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item> -->         
                    </mz-collection>
                </mz-collapsible-item-body>
            </mz-collapsible-item>
            <!-- End of 2019-20 S71 Collapsible & Collection -->   
        </mz-collapsible>
    </div>
</div>   <!-- End of first row -->   
</div>
