import { Component, OnInit } from '@angular/core';
import * as M from '../../../../../../node_modules/materialize-css';
declare let $ : any;

@Component({
  selector: 'app-townplanning-nav',
  templateUrl: './townplanning-nav.component.html',
  styleUrls: ['./townplanning-nav.component.css']
})
export class TownplanningNavComponent implements OnInit {

  constructor() { }

  ngOnInit() {

    $(document).ready(function(){
      $('.sidenav').sidenav();
    });
  }

}
