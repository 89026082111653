import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-procurement',
  templateUrl: './procurement.component.html',
  styleUrls: ['./procurement.component.css']
})
export class ProcurementComponent implements OnInit {

  scmFeb2023:Date = new Date('2023-02-10 15:06');
  csdMay2023:Date = new Date('2023-05-05 10:23');
  


  constructor() { }

  ngOnInit() {
  }

}
