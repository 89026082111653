import { Component, OnInit } from '@angular/core';

declare let $ : any;

@Component({
  selector: 'app-investor',
  templateUrl: './investor.component.html',
  styleUrls: ['./investor.component.css']
})
export class InvestorComponent implements OnInit {

  websiteLiveDate :Date = new Date('2020-01-16');

  constructor() { }

  ngOnInit() {

    $(document).ready(function(){
      $('.collapsible').collapsible();
    });
  }

}
