import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sdf',
  templateUrl: './sdf.component.html',
  styleUrls: ['./sdf.component.css']
})
export class SdfComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
