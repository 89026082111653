import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cfo',
  templateUrl: './cfo.component.html',
  styleUrls: ['./cfo.component.css']
})
export class CfoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
