import { Component, OnInit } from '@angular/core';
import { TenderService } from 'src/app/Modules/tenders/tender-service';
import { TendersModule } from 'src/app/Modules/tenders/tenders.module';

@Component({
  selector: 'app-archived-tenders',
  templateUrl: './archived-tenders.component.html',
  styleUrls: ['./archived-tenders.component.css']
})
export class ArchivedTendersComponent implements OnInit {

  ArchivedTenders:TendersModule[];
  constructor( private tenderService:TenderService) { 
    this.ArchivedTenders = this.tenderService.getAllArchivedTenders();
  }

  ngOnInit() {
  }

}
