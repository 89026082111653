import { Injectable } from '@angular/core';
import { PublicMeetingsModule } from './public-participation.module';

@Injectable({
  providedIn: 'root'
})
export class PublicParticipationService {


  getAllPublicMeetings():PublicMeetingsModule[]{
    return this.publicMeetings;
  }
  publicMeetings:PublicMeetingsModule[] = [
    new PublicMeetingsModule(
      "Wyk 1",
      "Tuesday: 10 September 2024",
      "Lutzville-Wes",
      "Lutzville Gemeenskapsaal",
      "16:00 - 19:00"
    ),
    new PublicMeetingsModule(
      "Wyk 1",
      "Wednesday: 11 September 2024",
      "Lutzville/Uitkyk",
      "Lutzville/Uitkyk Thusongsaal",
      "16:00 - 19:00"
    ),
    new PublicMeetingsModule(
      "Wyk 8	",
      "Thursday: 12 September 2024",
      "Koekenaap	",
      "Koekenaap Gemeenskapsaal	",
      "16:00 - 19:00"
    ),
    new PublicMeetingsModule(
      "Wyk 8",
      "Friday: 13 September 2024",
      "Kliprand",
      "Kliprand Gemeenskapsaal",
      "11:00 - 13:00"
    ),
    new PublicMeetingsModule(
      "Wyk 8",
      "Monday: 16 September 2024",
      "Stofkraal",
      "Stofkraal Gemeenskapsaal",
      "11:00 - 13:00"
    ),
    new PublicMeetingsModule(
      "Wyk 8",
      "Monday: 16 September 2024",
      "Molsvlei",
      "Molsvlei Gemeenskapsaal	",
      "14:00 - 16:00"
    ),
    new PublicMeetingsModule(
      "Wyk 8	",
      "Tuesday: 17 September 2024",
      "Rietpoort",
      "Rietpoort Gemeenskapsaal",
      "11:00 - 13:00"
    ),   
    new PublicMeetingsModule(
      "Wyk 8	",
      "Tuesday: 17 September 2024",
      "Putsekloof",
      "Putsekloof Gemeenskapsaal",
      "14:00 - 16:00"
    ), 
    new PublicMeetingsModule(
      "Wyk 8",
      "Wednesday: 18 September 2024",
      "Bitterfontein",
      "Bitterfontein Gemeenskapsaal",
      "11:00 - 13:00"
    ),
    new PublicMeetingsModule(
      "Wyk 8",
      "Wednesday: 18 September 2024",
      "Nuwerus",
      "Nuwerus Gemeenskapsaal",
      "14:00 - 16:00"
    ),  
    new PublicMeetingsModule(
      "Wyk 4",
      "Thursday: 19 September 2024",
      "Vredendal-Noord	",
      "Vredendal-Noord Gemeenskapsaal",
      "14:00 - 16:00"
    ), 
    new PublicMeetingsModule(
      "Wyk 3",
      "Monday: 23 September 2024",
      "Vredendal-Noord",
      "Vergenoeg Skoolsaal",
      "16:00 - 19:00"
    ),
    new PublicMeetingsModule(
      "Wyk 5",
      "Wednesday: 25 September 2024",
      "Vredendal",
      "Vredendal Sportsaal",
      "16:00 - 19:00"
    ),
    new PublicMeetingsModule(
      "Wyk 6",
      "Thursday: 26 September 2024",
      "Klawer",
      "Klawer Gemeenskapsaal",
      "16:00 - 19:00"
    ),
    new PublicMeetingsModule(
      "Wyk 7		",
      "Monday: 30 Septmber 2024",
      "Vanrhynsdorp",
      "Maskamsig Gemeenskapsaal	",
      "16:00 - 19:00"
    ),
    new PublicMeetingsModule(
      "Wyk 2",
      "Tuesday: 1 Oktober 2024",
      "Ebenhaezer & Papendorp",
      "Ebenhaezer Gemeenskapsaal",
      "16:00 - 19:00"
    ),
    new PublicMeetingsModule(
      "Wyk 2",
      "Wednesday: 2 Oktober 2024",
      "Doringbaai",
      "Miriam Owies Gemeenskapsaal",
      "16:00 - 19:00"
    ),
  ]

  constructor() { }


}
