<h4 class="center">Auctions</h4>
<div class="container">
<div class="row">
    <div class="col s12 m12 l12">
         
        <div class="row">
            <div class="col s12 m7">
              <div class="card">
                <div class="">
                  <!-- <img class="responsive-img" src="assets/Assets/IMG-20230608-WA0000.jpg"> -->
                  <span class="card-title center">
                    <strong>
                        Online Auction
                    </strong>
                </span>
                </div>
                <div class="card-content">
                  <p>Redundant Moveable Assets, Vehicles & Scrap</p> <br>
                  <address> {{auctionJune23 | date: 'MMM, dd yyyy, HH:mm'}}</address>
                </div>
                <div class="card-action">
                  <a href="assets/Assets/IMG-20230608-WA0000.jpg" download>Advertisement</a>
                    <a href="assets/Assets/LIST OF DISPOSALS.pdf" download>List of Disposals</a>
                </div>
              </div>
            </div>
          </div>
    </div>
</div>