<h4 class="center">Midyear & Quarterly Reports (S72 & S52)</h4>
<div class="container">
<div class="row">
    <div class="col s12 m12 l12">

        <mz-collapsible
            [mode]="'expandable'"
            [popout]="true">

            <mz-collapsible-item [active]="true">
                <mz-collapsible-item-header>
                         <i class="material-icons">keyboard_arrow_down</i>
                        Midyear(S72) Reports
                </mz-collapsible-item-header>
                <mz-collapsible-item-body>
                    <mz-collection>
                        <mz-collection-item >
                            2023 2024 MID YEAR BUDGET AND FINANCIAL PERFORMANCE ASSESSMENT WC011
                                 <a href="/assets/s72/2023 2024 MID YEAR BUDGET AND FINANCIAL PERFORMANCE ASSESSMENT WC011.pdf" mz-secondary-content download>
                                     {{s72Jan2024UploadDate |date : 'MMM, dd yyyy, HH:mm: aaa'}}
                                     <i class=" material-icons download rigth" download>cloud_download</i>
                                 </a>
                         </mz-collection-item>
                        <mz-collection-item >
                            2022 2023 MID YEAR BUDGET AND FINANCIAL PERFORMANCE ASSESSMENT WC011
                                 <a href="/assets/s72/2022 2023 MID YEAR BUDGET AND FINANCIAL PERFORMANCE ASSESSMENT WC011.pdf" mz-secondary-content download>
                                     {{s72Jan2023UploadDate |date : 'MMM, dd yyyy, HH:mm: aaa'}}
                                     <i class=" material-icons download rigth" download>cloud_download</i>
                                 </a>
                         </mz-collection-item>
                        <mz-collection-item >
                           2021 2022 MID YEAR BUDGET AND FINANCIAL PERFORMANCE ASSESSMENT WC011
                                <a href="/assets/s72/2021 2022 MID YEAR BUDGET AND FINANCIAL PERFORMANCE ASSESSMENT WC011.pdf" mz-secondary-content download>
                                    {{s72Jan2022UploadDate |date : 'MMM, dd yyyy, HH:mm: aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                        </mz-collection-item>
                        <mz-collection-item >
                            2020-21 MID YEAR BUDGET AND FINANCIAL PERFORMANCE ASSESSMENT WC011
                                <a href="/assets/s72/2020 2021 Mid-Year Budget and financial Performance Assessment WC011.pdf" mz-secondary-content download>
                                    {{s72Jan2021UploadDate |date : 'MMM, dd yyyy, HH:mm: aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                        </mz-collection-item>
                        <mz-collection-item >
                            2019-20 MID YEAR BUDGET AND FINANCIAL PERFORMANCE ASSESSMENT WC011
                                <a href="/assets/s72/20192020 MID YEAR BUDGET AND FINANCIAL PERFORMANCE ASSESSMENT WC011.pdf" mz-secondary-content download>
                                    {{s72Jan2020UploadDate |date : 'MMM, dd yyyy, HH:mm: aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                        </mz-collection-item>
                        <mz-collection-item >
                            2018-19 MID YEAR BUDGET AND FINANCIAL PERFORMANCE ASSESSMENT WC011
                                <a href="/assets/s72/20182019 MID YEAR BUDGET AND FINANCIAL PERFORMANCE ASSESSMENT WC011.pdf" mz-secondary-content download>
                                    {{websiteLiveDate |date : 'MMM, dd yyyy'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                        </mz-collection-item>
                        <mz-collection-item >
                            2017-18 MID YEAR BUDGET PERFORMANCE ASSESSMENT WC011
                                <a href="/assets/s72/20182019 MID YEAR BUDGET AND FINANCIAL PERFORMANCE ASSESSMENT WC011.pdf" mz-secondary-content download>
                                    {{websiteLiveDate |date : 'MMM, dd yyyy'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                        </mz-collection-item>
                        <mz-collection-item >
                            2016-17 MID YEAR BUDGET AND FINANCIAL PERFORMANCE ASSESSMENT WC011
                                <a href="/assets/s72/2016 2017 MID YEAR BUDGET AND FINANCIAL PERFORMANCE ASSESSMENT WC011.pdf" mz-secondary-content download>
                                    {{websiteLiveDate |date : 'MMM, dd yyyy'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                        </mz-collection-item>
                        <mz-collection-item >
                            2015-16 MID YEAR BUDGET AND FINANCIAL PERFORMANCE ASSESSMENT WC011 Part 1
                                <a href="/assets/s72/2015 2016 MID YEAR BUDGET AND FINANCIAL PERFORMANCE ASSESSMENT WC011.pdf" mz-secondary-content download>
                                    {{websiteLiveDate |date : 'MMM, dd yyyy'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                        </mz-collection-item>
                        <mz-collection-item >
                            2015-16 MID YEAR BUDGET AND FINANCIAL PERFORMANCE ASSESSMENT WC011 Part 2
                                <a href="/assets/s72/Section 72 report of Matzikama Municipality for 2015_16 V2.pdf" mz-secondary-content download>
                                    {{websiteLiveDate |date : 'MMM, dd yyyy'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                        </mz-collection-item>
                        <mz-collection-item >
                            2014-15 MID YEAR BUDGET AND FINANCIAL PERFORMANCE ASSESSMENT WC011 Part 1
                                <a href="/assets/s72/Mid-year-budget-and-performance-assessment-report-for-the-period-July-to-December-2014 WC011.pdf" mz-secondary-content download>
                                    {{websiteLiveDate |date : 'MMM, dd yyyy'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                        </mz-collection-item>
                         <mz-collection-item >
                            2014-15 MID YEAR BUDGET AND FINANCIAL PERFORMANCE ASSESSMENT WC011 Part 2
                                <a href="/assets/s72/Section 72 Performance Report at 31 December 2014_15.pdf" mz-secondary-content download>
                                    {{websiteLiveDate |date : 'MMM, dd yyyy'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                        </mz-collection-item>
                    </mz-collection>
                </mz-collapsible-item-body>
            </mz-collapsible-item>
            <!-- End of 2019-20 S71 Collapsible & Collection -->
        </mz-collapsible>
    </div>
</div>   <!-- End of first row -->
<div class="row">
    <div class="col s12 m12 l12">
        <mz-collapsible
            [mode]="'expandable'"
            [popout]="true">
            <mz-collapsible-item [active]="true">
                <mz-collapsible-item-header>
                         <i class="material-icons">keyboard_arrow_down</i>
                         Quarterly Budget Implementation Reports (S52)
                </mz-collapsible-item-header>
                <mz-collapsible-item-body>
                    <mz-collection>
                        <mz-collection-item>
                            Quarterly Budget  Monitoring Q1 2024-2025
                            <a href="/assets/s52/S52 2024-25/Quarterly Budget  Monitoring Report Q1_2024_25.pdf" mz-secondary-content download>
                                {{s52Q1_24_25 | date : 'MMM, dd yyyy, HH:mm: aaa'}}
                                <i class=" material-icons download rigth" download>cloud_download</i>
                            </a>
                        </mz-collection-item>
                        <mz-collection-item>
                            Quarterly Budget  Monitoring Q4 2023-2024
                            <a href="/assets/s52/S52 2023-24/Quarterly Budget  Monitoring Report Q4_2023_24.pdf" mz-secondary-content download>
                                {{s52Q4_23_24 | date : 'MMM, dd yyyy, HH:mm: aaa'}}
                                <i class=" material-icons download rigth" download>cloud_download</i>
                            </a>
                        </mz-collection-item>
                        <mz-collection-item>
                            Quarterly Budget  Monitoring Q3 2023-2024
                            <a href="/assets/s52/S52 2023-24/Quarterly Budget  Monitoring Report Q3_2023_24.pdf" mz-secondary-content download>
                                {{s52Q3_23_24 | date : 'MMM, dd yyyy, HH:mm: aaa'}}
                                <i class=" material-icons download rigth" download>cloud_download</i>
                            </a>
                        </mz-collection-item>
                        <mz-collection-item>
                            Quarterly Budget  Monitoring Q2 2023-2024
                            <a href="/assets/s52/S52 2023-24/Quarterly Budget  Monitoring Report Q2_2023_24.pdf" mz-secondary-content download>
                                {{s52Q2_23_24 | date : 'MMM, dd yyyy, HH:mm: aaa'}}
                                <i class=" material-icons download rigth" download>cloud_download</i>
                            </a>
                        </mz-collection-item>
                        <mz-collection-item>
                            Quarterly Budget  Monitoring Q1 2023-2024
                            <a href="/assets/s52/S52 2023-24/Quarterly Budget  Monitoring Report Q1_2023_24.pdf" mz-secondary-content download>
                                {{s52Q1_23_24 | date : 'MMM, dd yyyy, HH:mm: aaa'}}
                                <i class=" material-icons download rigth" download>cloud_download</i>
                            </a>
                        </mz-collection-item>
                        <mz-collection-item>
                            Quarterly Budget  Monitoring Q4 2022-2023
                            <a href="/assets/s52/S52 2022-23/Quarterly Budget  Monitoring Report Q4_2022_23.pdf" mz-secondary-content download>
                                {{s52Q4_22_23 | date : 'MMM, dd yyyy, HH:mm: aaa'}}
                                <i class=" material-icons download rigth" download>cloud_download</i>
                            </a>
                        </mz-collection-item>
                        <mz-collection-item>
                            Quarterly Budget  Monitoring Q3 2022-2023
                            <a href="/assets/s52/S52 2022-23/Quarterly Budget  Monitoring Report Q3_2022_23.pdf" mz-secondary-content download>
                                {{s52Q3_22_23 | date : 'MMM, dd yyyy, HH:mm: aaa'}}
                                <i class=" material-icons download rigth" download>cloud_download</i>
                            </a>
                        </mz-collection-item>
                    <mz-collection-item>
                        Quarterly Budget  Monitoring Q2 2022-2023
                        <a href="/assets/s52/S52 2022-23/Quarterly Budget  Monitoring Report Q2_2022_23.pdf" mz-secondary-content download>
                            {{s52Q2_22_23 | date : 'MMM, dd yyyy, HH:mm: aaa'}}
                            <i class=" material-icons download rigth" download>cloud_download</i>
                        </a>
                    </mz-collection-item>
                      <mz-collection-item>
                        Quarterly Budget  Monitoring Q1 2022-2023
                        <a href="/assets/s52/S52 2022-23/Quarterly Budget  Monitoring Report Q1_2022_23.pdf" mz-secondary-content download>
                            {{s52Q1_22_23 | date : 'MMM, dd yyyy, HH:mm: aaa'}}
                            <i class=" material-icons download rigth" download>cloud_download</i>
                        </a>
                        </mz-collection-item>
                      <mz-collection-item>
                        Quarterly Budget  Monitoring Q4 2021-2022
                        <a href="/assets/s52/S52 2021-22/s52_report_Q4.pdf" mz-secondary-content download>
                            {{s522022Q4 | date : 'MMM, dd yyyy, HH:mm: aaa'}}
                            <i class=" material-icons download rigth" download>cloud_download</i>
                        </a>
                        </mz-collection-item>
                      <mz-collection-item>
                        Quarterly Budget  Monitoring Q3 2021-2022
                        <a href="/assets/s52/S52 2021-22/s52_report_Q3.pdf" mz-secondary-content download>
                            {{s522022Q3 | date : 'MMM, dd yyyy, HH:mm: aaa'}}
                            <i class=" material-icons download rigth" download>cloud_download</i>
                        </a>
                        </mz-collection-item>
                        <mz-collection-item>
                            Quarterly Budget  Monitoring Q2 2021-2022
                            <a href="/assets/s52/S52 2021-22/s52_report_Q2.pdf" mz-secondary-content download>
                                {{s522022Q2 | date : 'MMM, dd yyyy, HH:mm: aaa'}}
                                <i class=" material-icons download rigth" download>cloud_download</i>
                            </a>
                        </mz-collection-item>
                        <mz-collection-item>
                            Quarterly Budget  Monitoring Q1 2021-2022
                            <a href="/assets/s52/S52 2021-22/s52_report_Q1.pdf" mz-secondary-content download>
                                {{s522022Q1 | date : 'MMM, dd yyyy, HH:mm: aaa'}}
                                <i class=" material-icons download rigth" download>cloud_download</i>
                            </a>
                        </mz-collection-item>
                            <mz-collection-item>
                                Quarterly Budget  Monitoring Q4 2020-2021
                                <a href="/assets/s52/s52 2020-21/s52_report_Q4.pdf" mz-secondary-content download>
                                    {{s52Q4_20_21 | date : 'MMM, dd yyyy, HH:mm: aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                             <mz-collection-item>
                            Quarterly Budget  Monitoring Q3 2020-2021
                            <a href="/assets/s52/s52 2020-21/s52_report_Q3.pdf" mz-secondary-content download>
                                {{s52Q3_20_21 | date : 'MMM, dd yyyy, HH:mm: aaa'}}
                                <i class=" material-icons download rigth" download>cloud_download</i>
                            </a>
                        </mz-collection-item>
                        <mz-collection-item>
                            Quarterly Budget  Monitoring Q2 2020-2021
                            <a href="/assets/s52/s52 2020-21/s52_report_Q2.pdf" mz-secondary-content download>
                                {{s522021Q2 | date : 'MMM, dd yyyy, HH:mm: aaa'}}
                                <i class=" material-icons download rigth" download>cloud_download</i>
                            </a>
                        </mz-collection-item>
                        <mz-collection-item>
                            Quarterly Budget  Monitoring Q1 2020-2021
                            <a href="/assets/s52/s52 2020-21/s52_report_Q1.pdf" mz-secondary-content download>
                                {{s522021Q1 | date : 'MMM, dd yyyy, HH:mm: aaa'}}
                                <i class=" material-icons download rigth" download>cloud_download</i>
                            </a>
                        </mz-collection-item>
                    </mz-collection>
                </mz-collapsible-item-body>
                <mz-collapsible-item-body>
                    <mz-collection>
                        <mz-collection-item >
                            Quarterly Budget  Monitoring Q4 - Correction 2019-2020
                                <a href="/assets/s52/Quarterly Budget  Monitoring Q4_2 20192020.pdf" mz-secondary-content download>
                                    {{s52Q4_2 |date : 'MMM, dd yyyy, HH:mm:ss'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                        </mz-collection-item>
                        <mz-collection-item >
                            Quarterly Budget  Monitoring Q4 2019-2020
                                <a href="/assets/s52/Quarterly Budget  Monitoring Q4 20192020.pdf" mz-secondary-content download>
                                    {{s52Q4 |date : 'MMM, dd yyyy, HH:mm:ss'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                        </mz-collection-item>
                        <mz-collection-item >
                            Quarterly Budget  Monitoring Q3 2019-2020
                                <a href="/assets/s52/Quarterly Budget  Monitoring Q3 20192020.pdf" mz-secondary-content download>
                                    {{s52Q3 |date : 'MMM, dd yyyy, HH:mm:ss'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                        </mz-collection-item>
                        <mz-collection-item >
                            Quarterly Budget  Monitoring Q2 2019-2020
                                <a href="/assets/s52/Quarterly Budget  Monitoring Q2 20192020.pdf" mz-secondary-content download>
                                    {{websiteLiveDate |date : 'MMM, dd yyyy'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                        </mz-collection-item>
                        <mz-collection-item >
                            Quarterly Budget  Monitoring Q1 2019-2020
                                <a href="/assets/s52/Quarterly Budget  Monitoring Q1 2019-2020.pdf" mz-secondary-content download>
                                    {{websiteLiveDate |date : 'MMM, dd yyyy'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                        </mz-collection-item>
                        <mz-collection-item >
                            Quarterly Budget  Monitoring Q1 2018-2019
                                <a href="/assets/s52/Quarterly Budget  Monitoring Q1 2018-2019.pdf" mz-secondary-content download>
                                    {{websiteLiveDate |date : 'MMM, dd yyyy'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                        </mz-collection-item>
                        <mz-collection-item >
                            Quarterly Budget  Monitoring Q2 2018-2019 WC011
                                <a href="/assets/s52/Quarterly Budget  Monitoring Q2 2018-2019 WC011.pdf" mz-secondary-content download>
                                    {{websiteLiveDate |date : 'MMM, dd yyyy'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                        </mz-collection-item>
                        <mz-collection-item >
                            Quarterly Budget  Monitoring Q3 2018-2019
                                <a href="/assets/s52/Quarterly Budget  Monitoring Q3 2018-2019.pdf" mz-secondary-content download>
                                    {{websiteLiveDate |date : 'MMM, dd yyyy'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                        </mz-collection-item>
                        <mz-collection-item >
                            Quarterly Budget  Monitoring Q4 2018-2019 WC011
                                <a href="/assets/s52/Quarterly Budget  Monitoring Q4 2018-2019 WC011.pdf" mz-secondary-content download>
                                    {{websiteLiveDate |date : 'MMM, dd yyyy'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                        </mz-collection-item>
                        <mz-collection-item >
                            Quarterly Budget  Monitoring Q1 2017-2018 WC011 Part 1
                                <a href="/assets/s52/Q1 Quarterly Budget  Monitoring 2017-2018_WC011.pdf" mz-secondary-content download>
                                    {{websiteLiveDate |date : 'MMM, dd yyyy'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                        </mz-collection-item>
                        <mz-collection-item >
                            Quarterly Budget  Monitoring Q1 2017-2018 WC011 Part 2
                                <a href="/assets/s52/Q1 TOPlayer SDBIP_report 20172018_WC011.pdf" mz-secondary-content download>
                                    {{websiteLiveDate |date : 'MMM, dd yyyy'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                        </mz-collection-item>
                        <mz-collection-item >
                            Quarterly Budget  Monitoring Q2 2017-2018 WC011 Part 1
                                <a href="/assets/s52/Q2 Quarterly Budget  Monitoring 2017-2018.pdf" mz-secondary-content download>
                                    {{websiteLiveDate |date : 'MMM, dd yyyy'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                        </mz-collection-item>
                         <mz-collection-item >
                            Quarterly Budget  Monitoring Q2 2017-2018 WC011 Part 2
                                <a href="/assets/s52/Q2 TOP_Layer SDBIP _20172018.pdf" mz-secondary-content download>
                                    {{websiteLiveDate |date : 'MMM, dd yyyy'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                        </mz-collection-item>
                        <mz-collection-item >
                            Quarterly Budget  Monitoring Q3 2017-2018 WC011 Part 1
                            <a href="/assets/s52/Q3 Quarterly Budget  Monitoring 2017-2018.pdf" mz-secondary-content download>
                                {{websiteLiveDate |date : 'MMM, dd yyyy'}}
                                <i class=" material-icons download rigth" download>cloud_download</i>
                            </a>
                        </mz-collection-item>
                        <mz-collection-item >
                            Quarterly Budget  Monitoring Q3 2017-2018 WC011 Part 2
                            <a href="/assets/s52/Q3 TOP_SDBIP 20172018 _WC011.pdf" mz-secondary-content download>
                                {{websiteLiveDate |date : 'MMM, dd yyyy'}}
                                <i class=" material-icons download rigth" download>cloud_download</i>
                            </a>
                        </mz-collection-item>
                        <mz-collection-item >
                            Quarterly Budget  Monitoring Q4 2017-2018 WC011 Part 1
                            <a href="/assets/s52/Q4 Quarterly Budget  Monitoring 2017-2018_WC011.pdf" mz-secondary-content download>
                                {{websiteLiveDate |date : 'MMM, dd yyyy'}}
                                <i class=" material-icons download rigth" download>cloud_download</i>
                            </a>
                        </mz-collection-item>
                        <mz-collection-item >
                            Quarterly Budget  Monitoring Q4 2017-2018 WC011 Part 2
                            <a href="/assets/s52/Q4 TOP _report_ 20172018.pdf" mz-secondary-content download>
                                {{websiteLiveDate |date : 'MMM, dd yyyy'}}
                                <i class=" material-icons download rigth" download>cloud_download</i>
                            </a>
                        </mz-collection-item>
                    </mz-collection>
                </mz-collapsible-item-body>
            </mz-collapsible-item>
            <!-- End of 2018-19 S71 Collapsible & Collection -->
        </mz-collapsible>
    </div>
</div><!-- End of second row -->
<div class="row">
    <div class="col s12 m12 l12">
        <mz-collapsible
            [mode]="'expandable'"
            [popout]="true">
            <mz-collapsible-item [active]="true">
                <mz-collapsible-item-header>
                         <i class="material-icons">keyboard_arrow_down</i>
                         Quarterly Supply Chain Implementation Reports
                </mz-collapsible-item-header>
                <mz-collapsible-item-body>
                    <mz-collection>
                        <mz-collection-item>
                            Q3 SCM IMPLEMENTATION REPORT 3RD QUARTER: 01 JAN 2024 - 31 MAR 2024 
                              <a href="/assets/s52/scmImplementation/2023-24/scm_report_Q3.pdf" mz-secondary-content download>
                                  {{ scmQ32024  | date : 'MMM, dd yyyy, HH:mm: aaa'}}
                                  <i class=" material-icons download rigth" download>cloud_download</i>
                              </a>
                        </mz-collection-item>
                        <mz-collection-item>
                            Q2 SCM IMPLEMENTATION REPORT 2ND QUARTER: 01 OCT 2023 - 31 DEC 2023 
                              <a href="/assets/s52/scmImplementation/2023-24/scm_report_Q2.pdf" mz-secondary-content download>
                                  {{ scmQ22024  | date : 'MMM, dd yyyy, HH:mm: aaa'}}
                                  <i class=" material-icons download rigth" download>cloud_download</i>
                              </a>
                        </mz-collection-item> 
                        <mz-collection-item>
                            Q1 SCM IMPLEMENTATION REPORT 1ST QUARTER: 01 JULY 2023 - 30 SEPT 2023 
                              <a href="/assets/s52/scmImplementation/2023-24/scm_report_Q1.pdf" mz-secondary-content download>
                                  {{ scmQ12024  | date : 'MMM, dd yyyy, HH:mm: aaa'}}
                                  <i class=" material-icons download rigth" download>cloud_download</i>
                              </a>
                        </mz-collection-item>
                        <!-- <mz-collection-item>
                            Q4 SCM IMPLEMENTATION REPORT 4TH QUARTER: 01 APRIL 2023 - 30 JUNE 2023 
                              <a href="/assets/s52/scmImplementation/2022-23/scm_report_Q4.pdf" mz-secondary-content download>
                                  {{ scmQ42023  | date : 'MMM, dd yyyy, HH:mm: aaa'}}
                                  <i class=" material-icons download rigth" download>cloud_download</i>
                              </a>
                        </mz-collection-item> -->
                        <mz-collection-item>
                            Q3 SCM IMPLEMENTATION REPORT 3RD QUARTER: 01 JANUARY 2023 - 31 MARCH 2023 
                              <a href="/assets/s52/scmImplementation/2022-23/scm_report_Q3.pdf" mz-secondary-content download>
                                  {{ scmQ32023  | date : 'MMM, dd yyyy, HH:mm: aaa'}}
                                  <i class=" material-icons download rigth" download>cloud_download</i>
                              </a>
                        </mz-collection-item>
                        <mz-collection-item>
                            Q2 SCM IMPLEMENTATION REPORT 2ND QUARTER: 01 OCTOBER 2022 –  31 DECEMBER 2022
                              <a href="/assets/s52/scmImplementation/2022-23/scm_report_Q2.pdf" mz-secondary-content download>
                                  {{ scmQ22023  | date : 'MMM, dd yyyy, HH:mm: aaa'}}
                                  <i class=" material-icons download rigth" download>cloud_download</i>
                              </a>
                            </mz-collection-item>
                        <mz-collection-item>
                            Q1 SCM IMPLEMENTATION REPORT 1st QUARTER: 01 JULY 2022 –  30 SEPTEMBER 2022
                              <a href="/assets/s52/scmImplementation/2022-23/scm_report_Q1.pdf" mz-secondary-content download>
                                  {{ scmQ12023  | date : 'MMM, dd yyyy, HH:mm: aaa'}}
                                  <i class=" material-icons download rigth" download>cloud_download</i>
                              </a>
                            </mz-collection-item>
                      <mz-collection-item>
                        Q4 SCM IMPLEMENTATION REPORT 4th QUARTER: 01 APRIL 2022 –  30 JUNE 2022
                          <a href="/assets/s52/scmImplementation/2021-22/scm_report_Q4.pdf" mz-secondary-content download>
                              {{ scmQ42022  | date : 'MMM, dd yyyy, HH:mm: aaa'}}
                              <i class=" material-icons download rigth" download>cloud_download</i>
                          </a>
                        </mz-collection-item>
                        <mz-collection-item>
                        Q3 SCM IMPLEMENTATION REPORT 3rd QUARTER: 01 JANUARY 2022 –  31 MARCH 2022
                          <a href="/assets/s52/scmImplementation/2021-22/scm_report_Q3.pdf" mz-secondary-content download>
                              {{ scmQ32022  | date : 'MMM, dd yyyy, HH:mm: aaa'}}
                              <i class=" material-icons download rigth" download>cloud_download</i>
                          </a>
                        </mz-collection-item>
                        <mz-collection-item>
                          Q2 SCM IMPLEMENTATION REPORT 2nd QUARTER: 01 OCTOBER 2021 –  31 DECEMBER 2021
                            <a href="/assets/s52/scmImplementation/2021-22/scm_report_Q2.pdf" mz-secondary-content download>
                                {{ scmQ22022  | date : 'MMM, dd yyyy, HH:mm: aaa'}}
                                <i class=" material-icons download rigth" download>cloud_download</i>
                            </a>
                        </mz-collection-item>
                        <mz-collection-item>
                            Q1 SCM IMPLEMENTATION REPORT 1st QUARTER: 01 JULY 2021 –  30 SEPTEMBER 2021
                            <a href="/assets/s52/scmImplementation/2021-22/scm_report_Q1.pdf" mz-secondary-content download>
                                {{ scmQ12022  | date : 'MMM, dd yyyy, HH:mm: aaa'}}
                                <i class=" material-icons download rigth" download>cloud_download</i>
                            </a>
                        </mz-collection-item>
                        <mz-collection-item>
                            Q4 SCM IMPLEMENTATION REPORT 01 April 2021 –  30 JUNE 2021
                            <a href="/assets/s52/scmImplementation/2020-21/scm_report_Q4.pdf" mz-secondary-content download>
                                {{ scmQ42021  | date : 'MMM, dd yyyy, HH:mm: aaa'}}
                                <i class=" material-icons download rigth" download>cloud_download</i>
                            </a>
                        </mz-collection-item>
                        <mz-collection-item>
                            Q3 SCM IMPLEMENTATION REPORT 01 January 2020 - 31 March 2021
                            <a href="/assets/s52/scmImplementation/2020-21/scm_report_Q3.pdf" mz-secondary-content download>
                                {{ scmQ32021  | date : 'MMM, dd yyyy, HH:mm: aaa'}}
                                <i class=" material-icons download rigth" download>cloud_download</i>
                            </a>
                        </mz-collection-item>
                        <mz-collection-item>
                            Q2 SCM IMPLEMENTATION REPORT October 2020 - December 2020
                            <a href="/assets/s52/scmImplementation/2020-21/scm_report_Q2.pdf" mz-secondary-content download>
                                {{ scmQ22021  | date : 'MMM, dd yyyy, HH:mm: aaa'}}
                                <i class=" material-icons download rigth" download>cloud_download</i>
                            </a>
                        </mz-collection-item>
                        <mz-collection-item>
                            Q2 SCM Report to Executive Mayor
                            <a href="/assets/s52/scmImplementation/2020-21/Memorandum to the Executive Mayor - 08 January 2020.pdf" mz-secondary-content download>
                                {{ scmQ22021Mayor  | date : 'MMM, dd yyyy, HH:mm: aaa'}}
                                <i class=" material-icons download rigth" download>cloud_download</i>
                            </a>
                        </mz-collection-item>
                    </mz-collection>
                </mz-collapsible-item-body>
                <mz-collapsible-item-body>
                    <mz-collection>
                        <mz-collection-item>
                            SCM IMPLEMENTATION REPORT July 2020-September 2020
                            <a href="/assets/s52/scmImplementation/2020-21/scm_report_Q1.pdf" mz-secondary-content download>
                                {{scmSept2020 | date : 'MMM, dd yyyy, HH:mm: aaa'}}
                                <i class=" material-icons download rigth" download>cloud_download</i>
                            </a>
                        </mz-collection-item>
                    </mz-collection>
                </mz-collapsible-item-body>
                <mz-collapsible-item-body>
                    <mz-collection>
                        <mz-collection-item >
                            SCM IMPLEMENTATION REPORT APR-JUN20
                                <a href="/assets/s52/scmImplementation/SCM IMPL REPORT APR-JUN20.pdf" mz-secondary-content download>
                                    {{scmAprJun2020 | date : 'MMM, dd yyyy, HH:mm: aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                        </mz-collection-item>
                        <mz-collection-item >
                            SCM IMPLEMENTATION REPORT JAN-MARCH20
                                <a href="/assets/s52/scmImplementation/SCM IMPL REPORT JAN-MARCH19.pdf" mz-secondary-content download>
                                    {{scmJanMarch2020 | date : 'MMM, dd yyyy, HH:mm: aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                        </mz-collection-item>
                        <mz-collection-item >
                            SCM IMPLEMENTATION REPORT OCT-DEC19
                                <a href="/assets/s52/scmImplementation/SCM IMPL REPORT OCT-DEC19.pdf" mz-secondary-content download>
                                    {{websiteLiveDate |date : 'MMM, dd yyyy'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                        </mz-collection-item>
                        <mz-collection-item >
                            SCM IMPLEMENTATION REPORT  JUL-SEPT19
                                <a href="/assets/s52/scmImplementation/SCM IMPL REPORT  JUL-SEPT19 .pdf" mz-secondary-content download>
                                    {{websiteLiveDate |date : 'MMM, dd yyyy'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                        </mz-collection-item>
                    </mz-collection>
                </mz-collapsible-item-body>
            </mz-collapsible-item>
            <!-- End of SCM Implementation Collapsible & Collection -->
        </mz-collapsible>
    </div>
</div><!-- End of third row -->
</div>
