<app-covid-navbar></app-covid-navbar>
<div class="container">
    <div class="center">
        <h3>About Covid19</h3>
    </div>
    <div class="row">
        <div class="col s12 m12 l12">
            <h5 class="center">
                <strong>
                    What is COVID-19?
                </strong>
            </h5>
            <p class="flow-text">
                Human Coronaviruses are common throughout the world. There are many different coronaviruses identified in animals but only a 
                small number of these can cause disease in humans. <br>

                On 7 January 2020, ‘Severe Acute Respiratory Syndrome Coronavirus 2’ (SARS-CoV-2) was confirmed as the causative agent of 
                ‘Coronavirus Disease 2019’ or COVID-19. The majority of the case-patients initially identified were dealers and vendors at a 
                seafood, poultry and live wildlife market in China. Since then, the virus has spread to more than 100 countries, including South Africa.
            </p>
        </div>
        <div class="col s12 m12 l12">
            <h5 class="center">
                <strong>
                    Who is most at risk?
                </strong>
            </h5>
            <p class="flow-text">
                The elderly, individuals with co-morbidities and healthcare workers have been found to be at a higher risk of death.
            </p>
        </div>
        <div class="col s12 m12 l12">
            <h5 class="center">
                <strong>
                    How Is It Transmitted?
                </strong>
            </h5>
            <p class="flow-text">
                While the first cases probably involved exposure to an animal source, the virus now seems to be spreading from person-to-person.

                The spread of the disease is thought to happen mainly via respiratory droplets produced when an infected person coughs or sneezes, 
                similar to how influenza and other respiratory pathogens spread. Thus far, the majority of cases have occurred in people with close 
                physical contact to cases and healthcare workers caring for patients with COVID-19.
            </p>
        </div>
        <div class="col s12 m12 l12">
            <h5 class="center">
                <strong>
                    What are the symptoms of COVID-19?
                </strong>
            </h5>
            <p class="flow-text">
                Current symptoms reported for patients with COVID-19 have included mild to severe respiratory illness with cough, sore throat, shortness of breath or fever.

                The complete clinical picture with regard to COVID-19 is still not fully clear. Reported illnesses have ranged from infected people with little to no symptoms 
                to people being severely ill and dying.
            </p>
        </div>
        <div class="col s12 m12 l12">
            <h5 class="center">
                <strong>
                    How is COVID-19 treated?
                </strong>
            </h5>
            <p class="flow-text">
                Treatment is supportive (providing oxygen for patients with shortness of breath or treating a fever, for example). To date, there is no specific antiviral 
                treatment available. Antibiotics do not treat viral infections. However, antibiotics may be required if a bacterial secondary infection develops.
            </p>
        </div>
        <div class="col s12 m12 l12">
            <h5 class="center">
                <strong>
                    How can you prevent infection?
                </strong>
            </h5>
            <p class="flow-text">
                On 23 March 2020, President Cyril Ramaphosa announced a new measure to combat the spread of the Covid-19 coronavirus in South Africa – a three-week nationwide 
                lockdown with severe restrictions on travel and movement, supported by the South African National Defence Force – from midnight on Thursday, 26 March, to 
                midnight on Thursday, 16 April. The President said more needed to be done to avoid “an enormous catastrophe” among the population.

                In essence, this meant people would only be allowed to leave their homes to buy food, seek medical help or under other extreme circumstances.
                
                The lockdown follows government regulations that limited public gatherings, travel from high-risk countries and the sale of alcohol.
                
                In addition, borders were closed to reduce the rate of infection from those travelling into South Africa from other countries. A quarantine was also enforced 
                on inbound travellers and returning citizens.
            </p>
        </div>
        <div class="col s12 m12 l12">
            <h5 class="center">
                <strong>
                    Latest measures
                </strong>
            </h5>
            <p class="flow-text">
                On Wednesday, 1 April, Minister of Health, Dr Zweli Mkhize, launched 60 new mobile laboratories to boost the country’s capacity to test for COVID-19. The sampling and testing units, 
                procured by the National Health Laboratory Service (NHLS), will be deployed nationwide to all priority districts and metros.

                10,000 community health care workers will be deployed across the country for door-to-door household screening. Each province has been requested to start working on this strategy by deploying 
                provincial community healthcare workers, with appropriate Personal Protective Equipment, to undertake a house-to-house programme of “no-touch” screening for Covid-19 symptoms and to refer symptomatic 
                people to clinics for testing.
                
                In addition, PEPFAR-funded District Support Partners have been instructed to support provinces in this programme. South Africa currently has the capacity to conduct 5,000 tests for COVID-19 daily.
                
                However, with the addition of mobile testing units, combined with 180 testing sites and 320 testing units across the country, this number will now increase six-fold
            </p>
        </div>
        <cite>Source: SAcoronavirus.co.za</cite>
    </div>
</div>
<app-footer></app-footer>
