<div class="container">
    <h5 class="center">Ward Committees</h5>
    <div class="row">
        <div class="col s12 m12 l12">
            <mz-collapsible [mode]="'expandable'" [popout]="true">
                <mz-collapsible-item [active]="true">
                    <mz-collapsible-item-header>
                        <i class="material-icons">keyboard_arrow_down</i>
                            Ward Committee By-Law
                    </mz-collapsible-item-header>
                    <mz-collapsible-item-body>
                        <mz-collection>
                            <mz-collection-item >
                                BELEID OOR DIE STIGTING EN FUNKSIONERING VAN WYKSKOMITEES
                                <a href="/assets/Ward-Committee/BELEID OOR DIE STIGTING EN FUNKSIONERING Hersien Desember 2022.pdf" mz-secondary-content download>
                                    {{wcBylaw2023AFR | date : 'MMM, dd yyyy, HH:mm: aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                POLICY ON ESTABLISHMENT AND FUNCTIONING OF WARD COMMITTEES
                                <a href="/assets/Ward-Committee/Policy on Establishment and functioning of Ward Committees - Revised December 2022.pdf" mz-secondary-content download>
                                    {{wcBylaw2023ENG | date : 'MMM, dd yyyy, HH:mm: aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                        </mz-collection>
                    </mz-collapsible-item-body>
                </mz-collapsible-item>
            </mz-collapsible>
        </div>
    </div>
</div> 


