import { Component, OnInit } from '@angular/core';
import {MzCollapsibleModule,MzButtonModule } from 'ngx-materialize';

import{TendersModule} from "../../../../Modules/tenders/tenders.module";
import { TenderService } from 'src/app/Modules/tenders/tender-service';

@Component({
  selector: 'app-tenders-in-progress',
  templateUrl: './tenders-in-progress.component.html',
  styleUrls: ['./tenders-in-progress.component.css']
})
export class TendersInProgressComponent implements OnInit {
  
  inProgressTenders:TendersModule[];
  constructor( private tenderService:TenderService) { 
    this.inProgressTenders = this.tenderService.getAllTenders();
  }

  ngOnInit() {

  }


}
