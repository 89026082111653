import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-auction',
  templateUrl: './auction.component.html',
  styleUrls: ['./auction.component.css']
})
export class AuctionComponent implements OnInit {
  auctionJune23 :Date = new Date('2023-06-09 15:11');

  constructor() { }

  ngOnInit() {
  }

}
