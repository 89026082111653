<app-covid-navbar></app-covid-navbar>
<div class="container">
    <section>
        <div class="row">
            <div class="col s12 m12 l12">
                <h4 class="center">
                   <strong>Key Contact Points</strong>                    
                </h4>
            </div>
        </div>
    </section>
</div>
    <section id="contactRow">
        <div class="row ">
 
            <div class="col s12 m12 l12">
                <ul class="flow-text center">
                    <li>NATIONAL HOTLINE: 0800 029 999</li>
                    <li>PROVINCIAL HOTLINE: 021 928 4102</li>
                    <li>WHATSAPP: SEND HI TO 0600 123456</li>
                </ul>
            </div>
        </div>
    </section>
<div class="container">
    <section>
        <h4 class="center"><strong>Further Information</strong></h4>
        <div class="row">
            <!-- First Card First Row -->
            <div class="col s12 m12 l4">
                <div class="card hoverable">
                    <div class="card-content">
                        <span class="card-title center"><h4><strong>Social Relief of Distress</strong></h4></span>
                        <p class="flow-text"> Applications could be made by either calling:</p>
                           <blockquote class="flow-text">
                            <ul>
                                <li id="contact">0800 60 10 11</li>
                            </ul>
                           </blockquote>
                           <p class="flow-text">or by sending an email to:</p>
                           <blockquote class="flow-text">
                                <span id="contact">
                                    grantenquiries@sassa.gov.za
                                </span>   
                            </blockquote>
                    </div>
                </div>
             
            </div>
           <!-- Second Card First Row -->
            <div class="col s12 m12 l4">
                <div class="card hoverable">
                    <div class="card-content">
                        <span class="card-title center"><h4><strong>Sassa</strong></h4></span>
                        <p class="flow-text">Sassa also has contact staff in every province, to try and relieve the congestion at the national call center.
                            The contact numbers are as follows:
                        </p>
                           <blockquote class="flow-text">
                            National SASSA call center: <span id="contact"> 0800 60 10 11</span> 
                           </blockquote>
                            <blockquote class="flow-text">
                                Western Cape: <span id="contact">021 469 0235 </span> 
                            </blockquote>
                    </div>
                </div>
            </div>
            <!-- Third Card First Row -->
            <div class="col s12 m12 l4">
                <div class="card hoverable">
                    <div class="card-content">
                        <span class="card-title center"><h4><strong>Call Centre numbers & Hotlines</strong></h4></span>
                           <blockquote class="flow-text">
                            National Institute for Communicable Diseases: <span id="contact">0800 029 999</span> 
                           </blockquote>
                            <blockquote class="flow-text">
                                Gender-based violence command center 0800 428 428 or dial: *120*7867# (free for mobile)  
                            </blockquote>
                            <blockquote class="flow-text">
                                Support to SMME’s in Distress: <span id="contact">0860 663 7867</span> 
                            </blockquote>
                            <blockquote class="flow-text">
                                SAPS: <span id="contact">08600 10111</span>  
                            </blockquote>
                            <blockquote class="flow-text">
                                National Crisis Line <br>
                                <span id="contact"> 0861 322 322</span> 
                            </blockquote>
                    </div>
                </div>
            </div>
        </div>
    <!-- Second Row -->
        <div class="row">
            <!-- First Card Second Row -->
            <div class="col s12 m12 l4">
                <div class="card hoverable">
                    <div class="card-content">
                        <span class="card-title center"><h4><strong>Call Centre numbers & Hotlines (continued)</strong></h4></span>
                        <p class="flow-text"> Applications could be made by either calling:</p>
                           <blockquote class="flow-text">
                            Temporary Employer-Employee Relief Scheme: <br>
                             <span id="contact">012 337 1997 </span> and email <span id="contact">Corona19ters@labour.gov.za </span>
                           </blockquote>
                           <blockquote class="flow-text">
                            Support to businesses: <span id="contact"> info@dsbd.gov.za / info@mybindu.org.za</span> <br>

                           <span id="contact">0860 663 7867 or <br>
                             0860 ONE STOP</span> 
                            
                           
                            </blockquote>
                    </div>
                </div>
             
            </div>
           <!-- Second Card First Row -->
            <div class="col s12 m12 l4">
                <div class="card hoverable">
                    <div class="card-content">
                        <span class="card-title center"><h4><strong>Call Centre numbers & Hotlines (continued)</strong></h4></span>
                            <blockquote id="contact">
                                https://www.gov.za/Coronavirus/support-business 
                            </blockquote>
                           <blockquote>
                            Solidarity Fund:  <span id="contact">0860 001 001</span><br>
                            <span id="contact">info@solidarityfund.co.za</span><br>
                            <span id="contact">https://www.solidarityfund.co.za/</span>
                           </blockquote>
                            <blockquote>
                                Western Cape: <span id="contact">021 469 0235 </span> 
                            </blockquote>
                            <blockquote>
                                Reporting undue price increases: <span id="contact">0800 141 880</span> 
                            </blockquote>
                            <blockquote>
                                Department of Home Affairs: <span id="contact">0800 601 190</span> 
                            </blockquote>
                            <blockquote>
                                Department of Water Affairs: <span id="contact">0800 200 200</span> 
                            </blockquote>
                            <blockquote>
                                Department of Tourism: <span id="contact">0860 868 747</span> 
                            </blockquote>
                            <blockquote>
                                Presidential Hotline: <span id="contact">17737</span> 
                            </blockquote>
                    </div>
                </div>
            </div>
            <!-- Third Card First Row -->
            <div class="col s12 m12 l4">
                <div class="card hoverable">
                    <div class="card-content">
                        <span class="card-title center"><h4><strong>Designated Hospitals for Managing COVID-19 Cases</strong></h4></span>
                           <blockquote class="flow-text">
                            Western Cape- <br>
                            Designated Hospital:
                            <span id="contact">Tygerberg Hospital</span> 
                           </blockquote>
                            <blockquote class="flow-text">
                                Referral Hospital: <br>
                               <span id="contact">Tygerberg Hospital</span>
                            </blockquote>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
<app-footer></app-footer>

