<div class="fixed-action-btn">
    <a class="btn-floating pulse btn-large #01579b light-blue darken-4">
        <i class="large material-icons">mode_edit</i>
    </a>
    <ul>
        <li>
            <a class="btn-floating" [routerLink]="['/sars']">
                <img src="../../../../../assets/sars2.png" width="40" height="40">
            </a>
        </li>
        <!--  <li><a class="btn-floating yellow darken-1"><i class="material-icons">format_quote</i></a></li> -->
        <li>
            <a href="https://new.easypay.co.za/Home.aspx" class="btn-floating">
                <img src="../../../../../assets/easypay.png" width="40" height="40">
            </a>
        </li>  
        <li>
            <a href="https://www.facebook.com/MatzikamaMunisipaliteit/" class="btn-floating">
                <img src="../../../../../assets/facebook2.png" width="40" height="40">
            </a>
          </li>
    </ul>
</div>
<!--  <div class="fixed-action-btn">
     <button mz-button float="true" large="true" class="green">
            <i class="large material-icons">mode_edit</i>
            <ul>
                <li>
                    <a mz-button href="https://www.facebook.com/MatzikamaMunisipaliteit/" class="btn-floating blue">
                        <img src="../../../../../assets/facebook3.png">  
                    </a>
                </li>
                <li><a class="btn-floating red"><i class="material-icons">insert_chart</i></a></li>
            </ul>
     </button>
  </div> -->