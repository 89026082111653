
   <div class="container hide-on-med-and-down">
    <div class="row">
        <div class="col s3 mayor">
           <app-lead-side-bar></app-lead-side-bar>
        </div>
        <div class="col s9">
           <h4 class="center">Office of the Municipal Manager</h4>
           <hr>
           <div class="col s12">
                <div class="col s5">
                     <img src="/assets/Council/Matz Mun_037 w.jpg" alt="Matzikama Municipal Manager" class="responsive-img">
                     <span class="flow-text">Mr. L. Phillips</span>
                </div>
                <div class="col s7">
                    <span class="black-text">
                        <p class="leadtext right" align="justify">
                               <b>Municipal Manager</b> <br>
                                The Municipal Manager, as Accounting Officer and Head of Administration,
                                is responsible for the overall performance of the municipality and the management to
                                ensure that the objectives of Council are achieved.<br>
                                <b>KEY PERFORMANCE AREAS</b><br>
                                1.Promote good governance<br>
                                2.Ensure effective and efficient basic service delivery<br>
                                3.Ensure effective and efficient institutional development and transformation<br>
                                4.Ensure effective and efficient financial viability and management<br>
                                5.Promote participative management<br>
                                6.Strategic planning<br>
                                7.Promote Council’s objectives by ensuring tasks are implemented<br>
                                8.Governance & Strategic Services<br>
                                9.Public Participation<br>
                                10.Integrated Development Plan<br>
                                11.Ward Committee Management<br>
                                12.Risk & Institutional Performance Management<br>
                                13.Communication<br>
                            <b>Contact Information</b><br>
                                Tel: +27 (0) 27 201 3301<br>
                                E-mail:<a href="mailto:munman@matzikama.gov.za">munman@matzikama.gov.za</a><br>
                        </p>
                    </span>
                </div>
            </div>
        </div>
    </div>

</div>
<!-- Mobile View -->
<div class="container hide-on-large-only">
    <div class="row">
        <h4 class="center">Office of the Municipal Manager</h4>
    </div>
    <div class="row">
        <div class="col s12 m12 l4">
            <img src="/assets/Council/Matz Mun_037 w.jpg" alt="Matzikama Municipal Manager" class="responsive-img center">
                     <span class="flow-text center">Mr. L. Phillips</span>
        </div>
        <div class="col s12 m12 l4">
            <span class="black-text">
                <p class="leadtext right" align="justify">
                       <b>Municipal Manager</b> <br>
                        The Municipal Manager, as Accounting Officer and Head of Administration,
                        is responsible for the overall performance of the municipality and the management to
                        ensure that the objectives of Council are achieved.<br>
                        <b>KEY PERFORMANCE AREAS</b><br>
                        1.Promote good governance<br>
                        2.Ensure effective and efficient basic service delivery<br>
                        3.Ensure effective and efficient institutional development and transformation<br>
                        4.Ensure effective and efficient financial viability and management<br>
                        5.Promote participative management<br>
                        6.Strategic planning<br>
                        7.Promote Council’s objectives by ensuring tasks are implemented<br>
                        8.Governance & Strategic Services<br>
                        9.Public Participation<br>
                        10.Integrated Development Plan<br>
                        11.Ward Committee Management<br>
                        12.Risk & Institutional Performance Management<br>
                        13.Communication<br>
                    <b>Contact Information</b><br>
                        Tel: +27 (0) 27 201 3301<br>
                        E-mail:<a href="mailto:munman@matzikama.gov.za">munman@matzikama.gov.za</a><br>
                </p>
            </span>
        </div>
    </div>
</div>
<app-footer></app-footer>



