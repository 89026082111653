
       
        <div class="row">
            <div class="col-s3">
                
                <div class="col-3"><h5 class="center leadbutton">Council</h5></div>

                <div class="col s12">
                    <a [routerLink]="['/mayor']" routerLinkActive="router-link-active" class="waves-effect #1a237e indigo darken-4 btn leadbutton">Office of the Mayor</a>
                </div>
                <div class="col s12">
                    <a [routerLink]="['/deputy']" routerLinkActive="router-link-active" class="waves-effect #1a237e indigo darken-4 btn leadside leadbutton">Deputy Mayor</a>
                </div>
                <div class="col s12">
                     <a [routerLink]="['/speaker']" routerLinkActive="router-link-active" class="waves-effect #1a237e indigo darken-4 btn leadside leadbutton">Office of the Speaker</a>
                </div>
                <div class="col s12">
                     <a [routerLink]="['/mayco']" routerLinkActive="router-link-active" class="waves-effect #1a237e indigo darken-4 btn leadside leadbutton" >Mayoral Committee</a>
                </div>
                <div class="col s12">
                    <a [routerLink]="['/councillors']" routerLinkActive="router-link-active" class="waves-effect #1a237e indigo darken-4 btn leadside leadbutton" >Councillors</a>
                </div>
                <div class="col-3"><h5 class="center leadbutton">Administration</h5></div>

                <div class="col s12">
                    <a [routerLink]="['/mm']" routerLinkActive="router-link-active" class="waves-effect #1a237e indigo darken-4 btn leadbutton">Municipal Manager</a>
                </div>
                <div class="col s12">
                    <a [routerLink]="['/cfo']" routerLinkActive="router-link-active" class="waves-effect #1a237e indigo darken-4 btn leadside leadbutton">Financial Services</a>
                </div>
               <!--  <div class="col s12">
                    <a [routerLink]="['/corporate']" routerLinkActive="router-link-active" class="waves-effect #1a237e indigo darken-4 btn leadside leadbutton" >Corporate Services </a>
                </div> -->
                <div class="col s12">
                    <a [routerLink]="['/community']" routerLinkActive="router-link-active" class="waves-effect #1a237e indigo darken-4 btn leadside leadbutton" >Community Services</a>
                </div>
                <div class="col s12">
                    <a [routerLink]="['/infrastructure']" routerLinkActive="router-link-active" class="waves-effect #1a237e indigo darken-4 btn leadside leadbutton" >Infrastructure Services</a>
                </div>
            </div>
        </div> 
 




    
   
    
  
