import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-led',
  templateUrl: './led.component.html',
  styleUrls: ['./led.component.css']
})
export class LedComponent implements OnInit {

  websiteLiveDate :Date = new Date('2020-01-16');
  ledDatabase :Date = new Date('2021-05-13 13:55');

  constructor() { }

  ngOnInit() {
  }

}
