import { Component, OnInit } from '@angular/core';
import{PhotoModule} from '../photo/photo.module';



@Component({
  selector: 'app-coastal-cleanup',
  templateUrl: './coastal-cleanup.component.html',
  styleUrls: ['./coastal-cleanup.component.css']
})
export class CoastalCleanupComponent implements OnInit {

  coastalPics:PhotoModule[] = [];

  constructor() { 
    this.coastalPics=[
      new PhotoModule(
        "/assets/gallery/Coastal Cleanup/1.jpg"
      ),
      new PhotoModule(
        "/assets/gallery/Coastal Cleanup/2.jpg"
      ),
      new PhotoModule(
        "/assets/gallery/Coastal Cleanup/3.jpg"
      ),
      new PhotoModule(
        "/assets/gallery/Coastal Cleanup/4.jpg"
      ),
      new PhotoModule(
        "/assets/gallery/Coastal Cleanup/5.jpg"
      ),
      new PhotoModule(
        "/assets/gallery/Coastal Cleanup/6.jpg"
      ),
      new PhotoModule(
        "/assets/gallery/Coastal Cleanup/7.jpg"
      ),
      new PhotoModule(
        "/assets/gallery/Coastal Cleanup/8.jpg"
      ),
      new PhotoModule(
        "/assets/gallery/Coastal Cleanup/9.jpg"
      ),
      new PhotoModule(
        "/assets/gallery/Coastal Cleanup/10.jpg"
      ),
      new PhotoModule(
        "/assets/gallery/Coastal Cleanup/11.jpg"
      ),
      new PhotoModule(
        "/assets/gallery/Coastal Cleanup/12.jpg"
      ),
      new PhotoModule(
        "/assets/gallery/Coastal Cleanup/13.jpg"
      ),
      new PhotoModule(
        "/assets/gallery/Coastal Cleanup/14.jpg"
      ),
      new PhotoModule(
        "/assets/gallery/Coastal Cleanup/15.jpg"
      ),
      new PhotoModule(
        "/assets/gallery/Coastal Cleanup/16.jpg"
      ),
      new PhotoModule(
        "/assets/gallery/Coastal Cleanup/17.jpg"
      ),
      new PhotoModule(
        "/assets/gallery/Coastal Cleanup/18.jpg"
      ),
      new PhotoModule(
        "/assets/gallery/Coastal Cleanup/19.jpg"
      ),
      new PhotoModule(
        "/assets/gallery/Coastal Cleanup/20.jpg"
      ),
      new PhotoModule(
        "/assets/gallery/Coastal Cleanup/21.jpg"
      ),
      new PhotoModule(
        "/assets/gallery/Coastal Cleanup/22.jpg"
      ),

    ]
  }

  ngOnInit() {
    jQuery(function(){
      $('.materialboxed').materialbox();
    });
  }

}
