import { Component, OnInit } from '@angular/core';
import {MzCollapsibleModule } from 'ngx-materialize';

import{TendersModule} from "../../../../Modules/tenders/tenders.module";
import { DatePipe } from '@angular/common';
import { TenderService } from 'src/app/Modules/tenders/tender-service';

@Component({
  selector: 'app-tenders-open',
  templateUrl: './tenders-open.component.html',
  styleUrls: ['./tenders-open.component.css']
})
export class TendersOpenComponent implements OnInit {

  openTenders:TendersModule[];
  tenderUploadDate = Date.now();
  tenderUploadTime= Date.now();
  tenderExtension2 : Date = new Date('2020-05-07 19:14');
  T16Notice:Date = new Date('2020-10-23 14:27');

  // Tenders:TendersModule[];

  constructor(private tenderService:TenderService) { 
      this.openTenders = this.tenderService.getAllTenders();
    // const tenderUploadDate = this.datePipe.transform(this.dateUploaded,'yyyy-MM-dd');
    // const tenderUploadTime =this.datePipe.transform(this. timeUploaded,'shortTime');
  
   /*  this.openTenders=[
      new TendersModule(
        `SERVICE, REPAIR AND REFILL OF FIRE EQUIPMENT FOR MATZIKAMA MUNICIPALITY FOR THE PERIOD ENDING 30 JUNE 2021 `,
        "T22:2020/2021",
        "2020-08-19",
        "16:50",
        "2020-08-26",
        "No later than 12:00",
        ``,
        `SERVICE, REPAIR AND REFILL OF FIRE EQUIPMENT FOR MATZIKAMA MUNICIPALITY FOR THE PERIOD ENDING 30 JUNE 2021`,
        "/assets/Tenders/2020-21/T22-202021.pdf",
        "N/A",
        "N/A",
        "N/A",
        ""  
      ),
      new TendersModule(
        `SUPPLY AND DELIVERY OF PERSONAL PROTECTIVE EQUIPMENT FOR PERIOD UNTIL 30 JUNE 2021
        WITH THE OPTION TO EXTEND FOR EXTRA 2 YEARS`,
        "T19:2020/2021",
        "2020-08-18",
        "13:13",
        "2020-09-01",
        "No later than 12:00",
        ``,
        `SUPPLY AND DELIVERY OF PERSONAL PROTECTIVE EQUIPMENT FOR PERIOD UNTIL 30 JUNE 2021
        WITH THE OPTION TO EXTEND FOR EXTRA 2 YEARS`,
        "/assets/Tenders/2020-21/T19-202021.pdf",
        "N/A",
        "N/A",
        "N/A",
        ""      
      ),
      new TendersModule(
        "COMPILATION OF ANNUAL PERFORMANCE REPORT 2019-2020 AND ANNUAL REPORT 2019-2020",
        "FQ21:2020/2021",
        "2020-07-30",
        "16:21",
        "2020-08-06",
        "No later than 12:00",
        ``,
        `COMPILATION OF ANNUAL PERFORMANCE REPORT 2019-2020 AND ANNUAL REPORT 2019-2020`,
        "/assets/Tenders/FQ21-2020-21.pdf",
        "N/A",
        "N/A",
        "N/A",
        ""      
      ),
      new TendersModule(
        "UPGRADING OF ROADS AND STORMWATER INFRASTRUCTURE IN UITKYK, LUTZVILLE - PHASE 1.2",
        "T09/2020",
        "2020-07-17",
        "18:58",
        "2020-08-07",
        "No later than 12:00",
        ``,
        `UPGRADING OF ROADS AND STORMWATER INFRASTRUCTURE IN UITKYK, LUTZVILLE - PHASE 1.2`,
        "/assets/Tenders/T09-2020.pdf",
        "N/A",
        "N/A",
        "N/A",
        ""     
      ),
      new TendersModule(
        "APPOINTMENT OF A CONSULTANT TO HANDLE AND EVALUATE THE CHIEF FINANCIAL OFFICER POSITION",
        "FQ20:2020-21",
        "2020-07-14",
        "17:01",
        "2020-07-21",
        "No later than 12:00",
        ``,
        `APPOINTMENT OF A CONSULTANT TO HANDLE AND EVALUATE THE CHIEF FINANCIAL OFFICER POSITION`,
        "/assets/Tenders/FQ20-2020-21.pdf",
        "N/A",
        "N/A",
        "N/A",
        ""      
      ),
      new TendersModule(
        "ANALYTICAL ANALYSIS OF WATER AND WASTE WATER SAMPLES FOR THE PERIOD UNTIL 30 JUNE 2021",
        "T15/2020",
        "2020-07-01",
        "15:33",
        "2020-08-03",
        "No later than 12:00",
        ``,
        `SUPPLY AND DELIVERY OF CHLORINE GAS FOR WATER AND SEWER WATER FOR MATZIKAMA MUNICIPALITY  UNTIL 30 JUNE 2021`,
        "/assets/Tenders/T15-2020.pdf",
        "N/A",
        "N/A",
        "N/A",
        ""      
      ),
      new TendersModule(
        "SUPPLY AND DELIVERY OF CHLORINE GAS FOR WATER AND SEWER WATER FOR MATZIKAMA MUNICIPALITY  UNTIL 30 JUNE 2021",
        "T14/2020",
        "2020-07-01",
        "15:31",
        "2020-08-03",
        "No later than 12:00",
        ``,
        `SUPPLY AND DELIVERY OF CHLORINE GAS FOR WATER AND SEWER WATER FOR MATZIKAMA MUNICIPALITY  UNTIL 30 JUNE 2021`,
        "/assets/Tenders/T14-2020.pdf",
        "N/A",
        "N/A",
        "N/A",
        ""      
      ),
      new TendersModule(
        "SUPPLY AND DELIVERY OF FILTERSAND FOR MATZIKAMA MUNICIPALITY  UNTIL 30 JUNE 2021",
        "T13/2020",
        "2020-07-01",
        "15:26",
        "2020-08-03",
        "No later than 12:00",
        ``,
        `SUPPLY AND DELIVERY OF FILTERSAND FOR MATZIKAMA MUNICIPALITY  UNTIL 30 JUNE 2021`,
        "/assets/Tenders/T13-2020.pdf",
        "N/A",
        "N/A",
        "N/A",
        ""      
      ),
      new TendersModule(
        "UPGRADING OF ROADS AND STORMWATER INFRASTRUCTURE IN VANRHYNSDORP, MASKAMSIG - PHASE 4",
        "T08/2020",
        "2020-06-15",
        "14:55",
        "2020-07-06",
        "No later than 12:00",
        `23 June  2020 at 11:00 at the Municipal Offices in Vanrhynsdorp`,
        `UPGRADING OF ROADS AND STORMWATER INFRASTRUCTURE IN VANRHYNSDORP, MASKAMSIG - PHASE 4`,
        "/assets/Tenders/T08-2020.pdf",
        "N/A",
        "N/A",
        "N/A",
        ""      
      ),
      new TendersModule(
        "LIFE INSURANCE SERVICES FOR MATZIKAMA MUNICIPALITY.",
        "T12/2020",
        "2020-05-29",
        "15:36",
        "2020-06-30",
        "No later than 12:00",
        `N/A`,
        `LIFE INSURANCE SERVICES FOR MATZIKAMA MUNICIPALITY.`,
        "/assets/Tenders/T12-2020.pdf",
        "N/A",
        "N/A",
        "N/A",
        ""      
      ),
 

 
   

   
    
  
    ] */

  }

  ngOnInit() {
  }
}
