<!-- Display options is in index.html -->
<div class="slider">
    <ul class="slides">
      <!-- <li>
        <img src="assets/PHOTO-2020-05-10-10-36-20.jpg" height="50px"> 
        <div class="caption center-align">
          <h3 class="dark blue-text hide-on-small-only">OUR MISSION</h3>
          <h5 class="dark black-text hide-on-small-only">
              To achieve effective service delivery and upliftment of the community through sustainable economic development
          </h5>
        </div> 
      </li>-->
      <li>
        <img src="assets/doringbaai.JPG"> 
        <div class="caption center-align">
          <h3 class="dark blue-text">Matzikama Municipality (WC011)</h3>
          <h5 class="light grey-text text-lighten-3 hide-on-small-only">Matzikama means "place of water" and boasts a wonderful destination where southern Namaqualand can be explored, 
              with its variety of tourist attractions, including the world famous wines of the West Coast Winelands and most beautiful flowers.</h5>
        </div>
      </li>
      <li>
        <img src="assets/waterval.jpg"> 
        <div class="caption center-align">
          <h3 class="dark blue-text hide-on-small-only">Towns </h3>
          <h5 class="light grey-text text-lighten-3 hide-on-small-only">
              The towns and neighborhoods / settlements within the municipal boundaries include: Doring Bay, Strandfontein, Ebenhaezer, Papendorp, Lutzville, Koekenaap, 
              Vredendal, Klawer, Vanrhynsdorp, Bitterfontein, Nuwerus , Kliprand, Stofkraal, Molsvlei, Putsekloof, Rietpoort.
          </h5>
        </div>
      </li>
      <li>
        <img src="assets/wingerd1.jpg"> 
        <div class="caption center-align">
          <h3 class="dark blue-text hide-on-small-only">Vision</h3>
          <h5 class="dark black-text hide-on-small-only">
            An efficient and sustainable organisation
          </h5>
        </div>
      </li>
      <li>
        <img src="assets/wingerd2.jpg"> 
        <div class="caption center-align">
          <h3 class="dark blue-text hide-on-small-only">Municipal Area</h3>
          <h5 class="light grey-text text-lighten-3 hide-on-small-only">The municipal area covers about 14,000 square kilometers</h5>
        </div>
      </li>
      <li>
        <img src="assets/wingerd3.jpg"> 
        <div class="caption center-align">
          <h3 class="dark blue-text hide-on-small-only">Main Economic Activity</h3>
          <h5 class="black-text text-lighten-3 hide-on-small-only">Agriculture:Mainly vineyard and hydroponic farming. In smaller extent, vegetables and livestock.
              Mining: Exxaro Namakwa Sands, Cape Lime, Rareco, PPC, Vanrhynsdorp Gypsum TransHex, Thormin Minerals Cawood Salt and Namakwa Bricks.
          </h5>
        </div>
      </li>
      <li>
        <img src="assets/boot.jpg"> 
        <div class="caption center-align">
          <h3 class="dark blue-text hide-on-small-only">OUR MISSION</h3>
          <h5 class="dark black-text hide-on-small-only">
            To build a high-performance organisation that facilitates the development of a flourishing economic environment for all its
            stakeholders
          </h5>
        </div>
      </li>
    </ul>
  </div>
 
