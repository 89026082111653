

        <!-- Sidemenu -->
 <!--        <ul id="slide-out" class="townplanningSidenav">
            <li><a [routerLink]="['/applications']" class="sidenav-close" routerLinkActive="router-link-active">Applications</a></li>
            <li><div class="divider"></div></li>
            <li><a [routerLink]="['/covid19']" class="sidenav-close" routerLinkActive="router-link-active">By-Laws</a></li>
            <li><div class="divider"></div></li>
            <li><div class="divider"></div></li>
            <li><a [routerLink]="['/documents']" class="sidenav-close" routerLinkActive="router-link-active">Notices</a></li>
            <li><div class="divider"></div></li>
            <li><a [routerLink]="['/gallery']" class="sidenav-close" routerLinkActive="router-link-active">Decisions</a></li>
            <li><div class="divider"></div></li>
            <li><a [routerLink]="['/contact']" class="sidenav-close" routerLinkActive="router-link-active">Spatial Plans</a></li>
            <li><div class="divider"></div></li>
            <li><a [routerLink]="['/contact']" class="sidenav-close" routerLinkActive="router-link-active">Tribunal</a></li>
            <li><div class="divider"></div></li>
            <li><a [routerLink]="['/contact']" class="sidenav-close" routerLinkActive="router-link-active">Appeals</a></li>
            <li><div class="divider"></div></li>
            <li><a [routerLink]="['/contact']" class="sidenav-close" routerLinkActive="router-link-active">Town Plans</a></li>
            <li><div class="divider"></div></li>
            <li><a [routerLink]="['/contact']" class="sidenav-close" routerLinkActive="router-link-active">Contact Us</a></li>
            <li><div class="divider"></div></li>
        </ul>
    <a href="#" data-target="slide-out" class="townplanningSidenav-trigger right"><i class="material-icons">menu</i></a>-->

<div class="row">
   
    <nav role="navigation"> 
        <div class="col s12 m12 l12 offset-l1">
            <div class="nav-wrapper container">
                <ul class="">
                        <li><a [routerLink]="['/townplanning']" class="sidenav-close" routerLinkActive="router-link-active">Home</a></li>
                        <li><a [routerLink]="['/applications']" class="sidenav-close" routerLinkActive="router-link-active">Applications</a></li>
                        <li><a [routerLink]="['/bylaws']" class="sidenav-close" routerLinkActive="router-link-active">By-Laws</a></li>
                        <li><a [routerLink]="['/notices']" class="sidenav-close" routerLinkActive="router-link-active">Notices</a></li>
                        <li><a [routerLink]="['/decisions']" class="sidenav-close" routerLinkActive="router-link-active">Decisions</a></li>
                        <li><a [routerLink]="['/spatialPlans']" class="sidenav-close" routerLinkActive="router-link-active">Scheme Regulations</a></li>
                        <li><a [routerLink]="['/tribunal']" class="sidenav-close" routerLinkActive="router-link-active">Tribunal</a></li>
                        <li><a [routerLink]="['/appeals']" class="sidenav-close" routerLinkActive="router-link-active">Appeals</a></li>
                        <li><a [routerLink]="['/townplans']" class="sidenav-close" routerLinkActive="router-link-active">Town Plans</a></li>
                        <li><a [routerLink]="['/townPlanningContact']" class="sidenav-close" routerLinkActive="router-link-active">Contact Us</a></li>
                </ul>
            </div>
        </div>
    </nav>
</div> 
<!-- hide-on-med-and-down -->
<!-- offset-l2 -->


<router-outlet></router-outlet>