import { Component, OnInit } from '@angular/core';
import * as M from '../../../../node_modules/materialize-css';

@Component({
  selector: 'app-floating-button',
  templateUrl: './floating-button.component.html',
  styleUrls: ['./floating-button.component.css']
})
export class FloatingButtonComponent implements OnInit {
  options ={}
  constructor() { }

  ngOnInit() {
    var elems = document.querySelectorAll('.fixed-action-btn');
      var instances = M.FloatingActionButton.init(elems,this.options);
  }

}
