import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-town-planning-contact',
  templateUrl: './town-planning-contact.component.html',
  styleUrls: ['./town-planning-contact.component.css']
})
export class TownPlanningContactComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
