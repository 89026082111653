<div class="container">
    <div class="row">
        <h4 class="center-align">Archived Tenders</h4>
        <div class="col-s6" *ngFor="let tender of ArchivedTenders">
            <mz-collapsible
                [mode]="'expandable'"
               
                [popout]="true">
                    <mz-collapsible-item [active]="true">
                        <mz-collapsible-item-header>
                            <i class="material-icons" >keyboard_arrow_down</i>
                            {{tender.tenderTitle}}
                        </mz-collapsible-item-header>
                            <mz-collapsible-item-body>
                                <table class="responsive-table">
                                    <thead>
                                        <tr>
                                            <th data-field="id">Description</th>
                                            <th data-field="name">Details</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                                 <tr>
                                                    <td>Title:</td>
                                                    <td>{{tender.tenderTitle}}</td>
                                                </tr>
                                                <tr>
                                                    <td>Reference Number</td>
                                                    <td>{{tender.tenderReferenceNumber}}</td>
                                                </tr>
                                                 <tr>
                                                    <td>Upload Date</td>
                                                    <td>{{tender.tenderUploadedDate}}</td>
                                                </tr>
                                                <tr>
                                                    <td>Upload Time</td>
                                                    <td>{{tender.tenderUploadedTime}}</td>
                                                </tr> 
                                                <tr>
                                                    <td>Closing Date</td>
                                                    <td>{{tender.tenderClosingDate}}</td>
                                                </tr>
                                                <tr>
                                                    <td>Closing Time</td>
                                                    <td>{{tender.tenderClosingTime}}</td>
                                                </tr>
                                                <tr>
                                                    <td>Compulsory Meeting Details</td>
                                                    <td>{{tender.tenderCompulsoryMeeting}}</td>
                                                </tr>
                                                <tr>
                                                    <td>Tender Awarded To</td>
                                                    <td>{{tender.tenderAward}}</td>
                                                </tr>
                                                <tr>
                                                    <td>BEE Status</td>
                                                    <td>{{tender.tenderBEEStatus}}</td>
                                                </tr>
                                                <tr>
                                                    <td>Tender Value</td>
                                                    <td>{{tender.tenderValue}}</td>
                                                </tr>
                                                <tr>
                                                    <td>Tender Status</td>
                                                    <td>{{tender.tenderStatus}}</td>
                                                </tr>
                                                <tr>
                                                    <td>Advertisement Download</td>
                                                    <td><a href="{{tender.tenderLink}}" download><u>{{tender.tenderReferenceNumber}}</u></a></td>
                                                </tr>
                                    </tbody>
                                </table>  
                            </mz-collapsible-item-body>
                    </mz-collapsible-item>
            </mz-collapsible>
        </div>
    </div>
</div>
