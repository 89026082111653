import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-grant-loadshedding',
  templateUrl: './grant-loadshedding.component.html',
  styleUrls: ['./grant-loadshedding.component.css']
})
export class GrantLoadsheddingComponent implements OnInit {

  lgM09:Date = new Date('2023-05-09 11:17');
  lgM10:Date = new Date('2023-05-09 11:20');
  lgM11:Date = new Date('2023-07-07 12:46');
  lgM12:Date = new Date('2023-07-07 16:21');
  lgReport:Date = new Date('2023-07-07 16:24');
  lg012324:Date = new Date('2023-09-07 16:26');
  lg022324:Date = new Date('2023-09-07 16:38');
  lg032324:Date = new Date('2023-10-24 16:11');








  constructor() { }

  ngOnInit() {
  }

}
