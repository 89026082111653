export class PublicMeetingsModule {

  public publicMeetingWard:string;
  public publicMeetingDayandTime:string;
  public publicMeetingTown:string;
  public publicMeetingPlace:string;
  public publicMeetingTime:string;


  constructor(ward:string,dayandtime:string,town:string,place:string,time:string){
    this.publicMeetingWard=ward;
    this.publicMeetingDayandTime=dayandtime;
    this.publicMeetingTown=town;
    this.publicMeetingPlace=place;
    this.publicMeetingTime=time;
  }
 }
