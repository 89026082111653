import { Component, OnInit } from '@angular/core';
import{PhotoModule} from '../photo/photo.module';


@Component({
  selector: 'app-view-event',
  templateUrl: './view-event.component.html',
  styleUrls: ['./view-event.component.css']
})
export class ViewEventComponent implements OnInit {

  events:PhotoModule[]=[];

  constructor() { 

    this.events=[
      new PhotoModule(
        "/assets/gallery/Rietpoort Erfenisdag/IMG_7080.JPG"
      ),
      new PhotoModule(
        "/assets/gallery/Rietpoort Erfenisdag/IMG_7092.JPG"
      ),
      new PhotoModule(
        "/assets/gallery/Rietpoort Erfenisdag/IMG_7113.JPG"
      ),
      new PhotoModule(
        "/assets/gallery/Rietpoort Erfenisdag/IMG_7114.JPG"
      ),
      new PhotoModule(
        "/assets/gallery/Rietpoort Erfenisdag/IMG_7115.JPG"
      ),
      new PhotoModule(
        "/assets/gallery/Rietpoort Erfenisdag/IMG_7116.JPG"
      ),
      new PhotoModule(
        "/assets/gallery/Rietpoort Erfenisdag/IMG_7117.JPG"
      ),
      new PhotoModule(
        "/assets/gallery/Rietpoort Erfenisdag/IMG_7135.JPG"
      ),
      new PhotoModule(
        "/assets/gallery/Rietpoort Erfenisdag/IMG_7148.JPG"
      ),
      new PhotoModule(
        "/assets/gallery/Rietpoort Erfenisdag/IMG_7149.JPG"
      ),
      new PhotoModule(
        "/assets/gallery/Rietpoort Erfenisdag/IMG_7150.JPG"
      ),
      new PhotoModule(
        "/assets/gallery/Rietpoort Erfenisdag/IMG_7151.JPG"
      ),
      new PhotoModule(
        "/assets/gallery/Rietpoort Erfenisdag/IMG_7152.JPG"
      ),
      new PhotoModule(
        "/assets/gallery/Rietpoort Erfenisdag/IMG_7154.JPG"
      ),
      new PhotoModule(
        "/assets/gallery/Rietpoort Erfenisdag/IMG_7155.JPG"
      ),
      new PhotoModule(
        "/assets/gallery/Rietpoort Erfenisdag/IMG_7156.JPG"
      ),
      new PhotoModule(
        "/assets/gallery/Rietpoort Erfenisdag/IMG_7163.JPG"
      ),
      new PhotoModule(
        "/assets/gallery/Rietpoort Erfenisdag/IMG_7164.JPG"
      ),
      new PhotoModule(
        "/assets/gallery/Rietpoort Erfenisdag/IMG_7165.JPG"
      ),
      new PhotoModule(
        "/assets/gallery/Rietpoort Erfenisdag/IMG_7167.JPG"
      ),
      new PhotoModule(
        "/assets/gallery/Rietpoort Erfenisdag/IMG_7185.JPG"
      ),
      new PhotoModule(
        "/assets/gallery/Rietpoort Erfenisdag/IMG_7187.JPG"
      ),
      new PhotoModule(
        "/assets/gallery/Rietpoort Erfenisdag/IMG_7196.JPG"
      ),
      new PhotoModule(
        "/assets/gallery/Rietpoort Erfenisdag/IMG_7200.JPG"
      ),
      new PhotoModule(
        "/assets/gallery/Rietpoort Erfenisdag/IMG_7201.JPG"
      ),
      new PhotoModule(
        "/assets/gallery/Rietpoort Erfenisdag/IMG_7207.JPG"
      ),
      new PhotoModule(
        "/assets/gallery/Rietpoort Erfenisdag/IMG_7209.JPG"
      ),
      new PhotoModule(
        "/assets/gallery/Rietpoort Erfenisdag/IMG_7163.JPG"
      ),
      new PhotoModule(
        "/assets/gallery/Rietpoort Erfenisdag/IMG_7221.JPG"
      ),
      new PhotoModule(
        "/assets/gallery/Rietpoort Erfenisdag/IMG_7223.JPG"
      ),
      new PhotoModule(
        "/assets/gallery/Rietpoort Erfenisdag/IMG_7224.JPG"
      ),
      new PhotoModule(
        "/assets/gallery/Rietpoort Erfenisdag/IMG_7225.JPG"
      ),
      new PhotoModule(
        "/assets/gallery/Rietpoort Erfenisdag/IMG_7234.JPG"
      ),
      new PhotoModule(
        "/assets/gallery/Rietpoort Erfenisdag/IMG_7236.JPG"
      ),
      new PhotoModule(
        "/assets/gallery/Rietpoort Erfenisdag/IMG_7239.JPG"
      ),
      new PhotoModule(
        "/assets/gallery/Rietpoort Erfenisdag/IMG_7305.JPG"
      ),
      new PhotoModule(
        "/assets/gallery/Rietpoort Erfenisdag/IMG_7307.JPG"
      ),
      new PhotoModule(
        "/assets/gallery/Rietpoort Erfenisdag/IMG_7242.JPG"
      ),
      new PhotoModule(
        "/assets/gallery/Rietpoort Erfenisdag/IMG_7246.JPG"
      ),
      new PhotoModule(
        "/assets/gallery/Rietpoort Erfenisdag/IMG_7248.JPG"
      ),
      new PhotoModule(
        "/assets/gallery/Rietpoort Erfenisdag/IMG_7249.JPG"
      ),
      new PhotoModule(
        "/assets/gallery/Rietpoort Erfenisdag/IMG_7254.JPG"
      ),
      new PhotoModule(
        "/assets/gallery/Rietpoort Erfenisdag/IMG_7255.JPG"
      ),
      new PhotoModule(
        "/assets/gallery/Rietpoort Erfenisdag/IMG_7273.JPG"
      ),
      new PhotoModule(
        "/assets/gallery/Rietpoort Erfenisdag/IMG_7289.JPG"
      ),
      new PhotoModule(
        "/assets/gallery/Rietpoort Erfenisdag/IMG_7293.JPG"
      ),
      new PhotoModule(
        "/assets/gallery/Rietpoort Erfenisdag/IMG_7298.JPG"
      ),
      new PhotoModule(
        "/assets/gallery/Rietpoort Erfenisdag/IMG_7300.JPG"
      ),
      new PhotoModule(
        "/assets/gallery/Rietpoort Erfenisdag/IMG_7301.JPG"
      ),
      new PhotoModule(
        "/assets/gallery/Rietpoort Erfenisdag/IMG_7304.JPG"
      ),
      new PhotoModule(
        "/assets/gallery/Rietpoort Erfenisdag/IMG_7308.jpg"
      ),
      new PhotoModule(
        "/assets/gallery/Rietpoort Erfenisdag/IMG_7309.jpg"
      ),
      new PhotoModule(
        "/assets/gallery/Rietpoort Erfenisdag/IMG_7310.jpg"
      ),
      new PhotoModule(
        "/assets/gallery/Rietpoort Erfenisdag/IMG_7311.jpg"
      ),
      new PhotoModule(
        "/assets/gallery/Rietpoort Erfenisdag/IMG_7312.jpg"
      ),
      new PhotoModule(
        "/assets/gallery/Rietpoort Erfenisdag/IMG_7313.jpg"
      ),
      new PhotoModule(
        "/assets/gallery/Rietpoort Erfenisdag/IMG_7314.jpg"
      ),
      new PhotoModule(
        "/assets/gallery/Rietpoort Erfenisdag/IMG_7315.jpg"
      ),
      new PhotoModule(
        "/assets/gallery/Rietpoort Erfenisdag/IMG_7316.jpg"
      ),
      new PhotoModule(
        "/assets/gallery/Rietpoort Erfenisdag/IMG_7317.jpg"
      ),
      new PhotoModule(
        "/assets/gallery/Rietpoort Erfenisdag/IMG_7318.jpg"
      ),
    ]
  }

  ngOnInit() {

    jQuery(function(){
      $('.materialboxed').materialbox();
    });
  }

}
