import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tribunal',
  templateUrl: './tribunal.component.html',
  styleUrls: ['./tribunal.component.css']
})
export class TribunalComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
