import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
// import * as M from '../../../../../node_modules/materialize-css';
import * as M from '../../../../node_modules/materialize-css';
declare let $ : any;

@Component({
  selector: 'app-covid19',
  templateUrl: './covid19.component.html',
  styleUrls: ['./covid19.component.css']
})
export class Covid19Component implements OnInit {

  constructor(
    private toastr: ToastrService
  ) { }

  ngOnInit() {

    $(document).ready(function(){
      $('.parallax').parallax();
    });
  }
  covidClick(){
    this.toastr.success('Toastr service Work','Toastr Service')
  }

}
