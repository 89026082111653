import { Component, OnInit } from '@angular/core';
import * as M from '../../../../../../node_modules/materialize-css';
declare let $ : any;

@Component({
  selector: 'app-spatial-plans',
  templateUrl: './spatial-plans.component.html',
  styleUrls: ['./spatial-plans.component.css']
})
export class SpatialPlansComponent implements OnInit {

  Vredendal1993 : Date = new Date('2020-09-28 20:25:22');
  Artikel8 : Date = new Date('2020-09-28 20:28:36');
  zoningBylaw : Date = new Date('2023-03-17 12:56:15');




  constructor() { }

  ngOnInit() {

    $(document).ready(function(){
      $('.materialboxed').materialbox();
    });
  }

}
