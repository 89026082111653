
<div class="container hide-on-med-and-down">
    <div class="row">
        <div class="col s3 mayor">
           <app-lead-side-bar></app-lead-side-bar>
        </div>
        <div class="col s9">
           <h4 class="center">Office of the Speaker</h4>
           <hr>
           <div class="col s12">
                <div class="col s5">
                     <img src="/assets/Council/Matz Mun_046.png" alt="Matzikama Speaker" class="responsive-img">
                     <span class="flow-text">Cllr A. Sindyamba</span>
                </div>
                <div class="col s7">
                    <span class="black-text">
                        <p class="leadtext right" align="justify">

                                The Structures Act defines the speaker as a councillor elected in terms of section 36 of the Act to be the chairperson
                                of a municipal council as envisaged in section 160 (1)(b) of the Constitution. <br>
                                The speaker's role in a municipality will mostly depend on the internal arrangements made by the municipality itself.
                                The formulation of the terms of reference for the speaker in terms of section 53 of the Systems Act will be a critical process.
                                The speaker’s role in a municipality is key to ensuring oversight, accountability, integrity, discipline of office,
                                and the efficient running of council meetings. As such, impartiality in the exercise of his or her function is essential for the speaker. <br>
                                The speaker must distinguish between his or her activities as a politician and his or her functions as a speaker.
                                It also means that the function of the speaker and the non-partisan exercise of that function must be respected by members,
                                parties and interests represented in the council. <br>
                                <b>The key principles underlying the role of the Speaker are:</b><br>
                                (a) chair of council meetings; <br>
                                (b) implementation of the Code of Conduct; and <br>
                                (c) exercise of delegated functions including - <br>
                                i. facilitating public participation in legislative matters; <br>

                                ii. establishment and functioning of ward committees; and support to councilors. <br>
                                <b>Functions of the Speaker</b><br>
                                 <b>The speaker of a municipal council- </b><br>
                                (a) presides at meetings of the council; <br>
                                (b) performs the duties and exercises the powers delegated to the speaker in terms of section 59 of the Local Government:
                                Municipal Systems Act, 2000 (Act 32 of 2000); <br>
                                (c) must ensure that the council meets at least quarterly; <br>
                                (d) must maintain order during meetings; <br>

                                (e) must ensure compliance in the council and council committees with the Code of Conduct set out in Schedule 1 to the Local Government: Municipal Systems Act, 2000 (Act 32 of 2000);
                                and <br>
                                (f) must ensure that council meetings are conducted in accordance with the rules and orders of the council. <br>
                                <sup>Local Government Municipal Structures Act 117 of 1998 as amended</sup>


                        </p>
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container hide-on-large-only">
    <div class="row">
        <h4 class="center">Speaker</h4>
    </div>
    <div class="row">
        <div class="col s12 m12 l4">
            <img src="/assets/Council/Matz Mun_046.png" alt="Matzikama Speaker" class="responsive-img">
            <span class="flow-text center">Cllr A. Sindyamba</span>
        </div>
        <div class="col s12 m12 l4">
            <span class="black-text">
                <p class="leadtext right" align="justify">
                        The Structures Act defines the speaker as a councillor elected in terms of section 36 of the Act to be the chairperson
                        of a municipal council as envisaged in section 160 (1)(b) of the Constitution. <br>
                        The speaker's role in a municipality will mostly depend on the internal arrangements made by the municipality itself.
                        The formulation of the terms of reference for the speaker in terms of section 53 of the Systems Act will be a critical process.
                        The speaker’s role in a municipality is key to ensuring oversight, accountability, integrity, discipline of office,
                        and the efficient running of council meetings. As such, impartiality in the exercise of his or her function is essential for the speaker. <br>
                        The speaker must distinguish between his or her activities as a politician and his or her functions as a speaker.
                        It also means that the function of the speaker and the non-partisan exercise of that function must be respected by members,
                        parties and interests represented in the council. <br>
                        <b>The key principles underlying the role of the Speaker are:</b><br>
                        (a) chair of council meetings; <br>
                        (b) implementation of the Code of Conduct; and <br>
                        (c) exercise of delegated functions including - <br>
                        i. facilitating public participation in legislative matters; <br>

                        ii. establishment and functioning of ward committees; and support to councilors. <br>
                        <b>Functions of the Speaker</b><br>
                         <b>The speaker of a municipal council- </b><br>
                        (a) presides at meetings of the council; <br>
                        (b) performs the duties and exercises the powers delegated to the speaker in terms of section 59 of the Local Government:
                        Municipal Systems Act, 2000 (Act 32 of 2000); <br>
                        (c) must ensure that the council meets at least quarterly; <br>
                        (d) must maintain order during meetings; <br>

                        (e) must ensure compliance in the council and council committees with the Code of Conduct set out in Schedule 1 to the Local Government: Municipal Systems Act, 2000 (Act 32 of 2000);
                        and <br>
                        (f) must ensure that council meetings are conducted in accordance with the rules and orders of the council. <br>
                         <sup>Local Government Municipal Structures Act 117 of 1998 as amended</sup>
                </p>
            </span>
        </div>
    </div>
</div>
<app-footer></app-footer>
