import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-municipal-notices',
  templateUrl: './municipal-notices.component.html',
  styleUrls: ['./municipal-notices.component.css']
})
export class MunicipalNoticesComponent implements OnInit {


  AssetDisposal1920:Date =new Date('2020-11-10 15:41');
  TronoxLED:Date =new Date('2020-11-20 09:38');
  kiosk2020:Date =new Date('2020-11-20 14:24');
  hawkers2020:Date =new Date('2020-11-20 14:32');
  mcop2020:Date =new Date('2020-11-24 11:13');
  adjustbudgetDec2020:Date =new Date('2020-12-15 12:39');
  bursariesMarch2021:Date =new Date('2020-03-15 13:54');
  internDatabaseMarch2021:Date= new Date('2020-04-01 08:21');
  publicParticipationMarch2021:Date= new Date('2020-04-19 20:20');
  // K1_2021-2022:Date= new Date('2020-07-114 11:16')
  K1_2021_2022:Date = new Date('2021-07-14 11:16');
  K13_2021_2022:Date = new Date('2021-09-27 12:25');
  statsSA:Date = new Date('2021-11-02 12:33');
  rfpHousing:Date = new Date("2021-11-08 12:51");
  availFeesENG:Date = new Date("2021-11-11 12:22");
  availFeesAFR:Date = new Date("2021-11-11 12:24");
  eventsCancel:Date = new Date("2021-12-21 15:20");
  bedryfHuiswinkels:Date = new Date("2022-01-26 09:56");
  bursaries2022:Date = new Date("2022-01-31 08:36");
  bursariesFeb2022:Date = new Date("2022-02-10 12:14");
  drieFaseKragMarch2022:Date = new Date("2022-03-24 09:37");
  PublicPartMeetingsMarch2022:Date = new Date("2022-03-30 10:58");
  k35:Date = new Date("2022-05-19 07:56");
  tronoxSLP= new Date("2022-05-23 15:28");
  liquorLicence = new Date("2022-05-27 08:09");
  internship202223 = new Date("2022-06-02 13:34");
  narysec202223 = new Date("2022-06-02 13:42");
  idSummaryAd = new Date("2022-06-10 15:12");
  SoneringSkemaBylaw2022 = new Date("2022-06-21 09:43");
  SoneringSkemaBylaw2022Ad = new Date("2022-06-21 09:46");
  WaterKennisgewingJuly2022 = new Date("2022-07-07 09:59");
  idpProcessPlanJuly2022 = new Date("2022-07-11 10:27");
  idpTimeScheduleJuly2022 = new Date("2022-07-11 10:23");
  idpProcessPlanNoticeJuly2022 = new Date("2022-07-11 10:35");
  K11202122 = new Date("2022-09-01 16:34");
  K1202122 = new Date("2022-09-01 16:41");
  IDPTimeSchedule202223 = new Date("2022-09-01 16:50");
  IDPProcessPlan202223 = new Date("2022-09-01 16:53");
  IDPMeetingsOctober202223 = new Date("2022-09-29 10:07");
  LgsetaBursariesOctober202223 = new Date("2022-10-18 16:22");
  waterNotice091122 = new Date("2022-11-09 17:28");
  waterNotice241122 = new Date("2022-11-24 16:03");
  buildingPlanNotice241122 = new Date("2022-11-24 16:12");
  spazaShopsNotice241129 = new Date("2022-11-29 11:23");
  fq272023 = new Date("2023-02-16 08:31");
  fq282023 = new Date("2023-02-16 08:35");
  zoningBylaw2023 = new Date("2023-03-17 12:40");
  k362023=new Date("2023-04-03 16:02");
  elecSupply2122=new Date("2023-05-11 14:32");
  nersaLetter202324=new Date("2023-07-05 09:36");
  StrandFonteinBookings202324=new Date("2023-07-05 10:22");
  Fq03Extention202324=new Date("2023-08-08 10:15");
  idp2324=new Date("2023-08-30 16:21");
  idp2324deadlines=new Date("2023-08-30 16:24");
  townplanNotice20102023=new Date("2023-10-20 12:14");
  buildingControlNotice20102023=new Date("2023-10-20 12:17");
  ssegApp=new Date("2023-10-21 16:12");
  ssegReq=new Date("2023-10-21 16:09");
  ssegPolicy=new Date("2023-10-21 16:15");
  rasta202402=new Date("2024-02-15 14:13");
  huiswinkelAfr=new Date("2024-02-16 19:08");
  huiswinkelEng=new Date("2024-02-16 19:05");
  nersaLetter0207=new Date("2024-07-02 14:38");
  strandfontein2425=new Date("2024-07-29 07:55");
  IDPMeetingsSept202526= new Date("2024-09-02 10:14");
  TimeScheduleSept202526= new Date("2024-09-02 10:24");
  K22Gholfklub= new Date("2024-10-03 09:09");
  TrainingFQs= new Date("2024-10-15 16:55");
  Agreements202324CDS= new Date("2023-12-13 16:22");
  Agreements202324MM = new Date("2023-12-13 16:20");
  s33Banking = new Date("2024-12-03 07:46");
  s33BankingInfoSheet = new Date("2024-12-03 07:49");
  strandEaster2025 = new Date("2024-12-04 09:36");






  constructor() { }

  ngOnInit() {

    $(document).ready(function(){
      $('.collapsible').collapsible();
    });
  }

}
