import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-media-statements',
  templateUrl: './media-statements.component.html',
  styleUrls: ['./media-statements.component.css']
})
export class MediaStatementsComponent implements OnInit {

  hawksMarch2021 = new Date('2021-03-16 14:29');
  statement30March2021 = new Date('2021-03-30 21:46');
  statement01March2021 = new Date('2021-03-30 21:50');
  pressRelease12May2021 = new Date('2021-05-13 12:09');
  circular212May2021 = new Date('2021-05-13 12:17');
  CETAJune2021 = new Date('2021-06-04 08:41');
  officeOfSpeakerStatement = new Date('2021-07-06 12:52');
  officeOfSpeakerStatement2 = new Date('2021-07-06 12:55');






  constructor() { }

  ngOnInit() {
  }

}
